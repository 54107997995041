// src/components/AssetDetailEditor/BasicDetail.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveBasicDetailsRequest, saveAssetDescriptionRequest, uploadImageRequest,
  uploadImageStart, uploadImageSuccess2, uploadImageFailure2,
  getBasicDetailsRequest
 } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import styles from './BasicDetail.module.css';
import { uploadImageApi, uploadImageApi2 } from '../../../../features/assetEditor/assetEditorApi';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface BasicDetailProps {
  selectedAsset: { assetID: string; name: string; _id: string }; // Assuming Asset is the type of your selected asset
}

const BasicDetail: React.FC<BasicDetailProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  console.log('BasicDetail: Selected Asset:', selectedAsset);
  useSelector((state: RootState) => {
    console.log('BasicDetail::', state.assetEditor);
    return state.assetEditor.basicDetails;
  });

  const { success, error, loading, basicDetails } = useSelector((state: RootState) => state.assetEditor);

  const imagePreviewUrls = basicDetails?.images?.map((image: any) => {
    console.log('image: ', image);
    const url = 'http://localhost:3000/uploads/' + image;
    return url;
  });
  console.log('imagePreviewUrls ', imagePreviewUrls);

  console.log('BasicDetail: Basic Details:', basicDetails);
  const [assetDescription, setAssetDescription] = useState(basicDetails.assetDescription || '');
  const [images, setImages] = useState<File[]>([]);
  const [assetTitle, setAssetTitle] = useState(basicDetails.assetTitle || '');

  //const [imagePreviews, setImagePreviews] = useState<string[]>(Array(5).fill(''));
  const [imagePreviews, setImagePreviews] = useState<string[]>(imagePreviewUrls);

  useEffect(() => {
    dispatch(getBasicDetailsRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);



  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAssetDescription(e.target.value);
  };

  const handleImageChange_old = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).slice(0, 5); // Limit to 5 files
      setImages(filesArray);
    }
  };

  const handleImageChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const updatedImages = [...images];
      updatedImages[index] = file;
      setImages(updatedImages);

      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedPreviews = [...imagePreviews];
        updatedPreviews[index] = reader.result as string;
        setImagePreviews(updatedPreviews);
      };
      reader.readAsDataURL(file);

      // Dispatch the image upload action here
      // dispatch(uploadImageRequest({ file, assetID: selectedAsset.assetID, _id: selectedAsset._id }));
    }
  };
  const handleSave = () => {
    //const basicDetails = { assetDescription, images, propertyID };
    //dispatch(saveBasicDetailsRequest({ basicDetails, assetID: selectedAsset.assetID, _id: selectedAsset._id}));
  };

  // const handleSaveDescription = () => {
  //   const basicDetails = { assetDescription };
  //   dispatch(saveAssetDescriptionRequest({ assetDescription, assetID: selectedAsset.assetID, _id: selectedAsset._id}));
  // };


  const handleSaveBasicDetails = () => {
    dispatch(saveAssetDescriptionRequest({
      assetTitle,
      assetDescription,
      assetID: selectedAsset.assetID,
      _id: selectedAsset._id
    }));
  };

  const handleImageUpload = async (file: File, index: number) => {
    console.log('handleImageUpload called with file:   ', file, '   and index:   ', index );
    dispatch(uploadImageStart());
    try {
      // Replace with your actual image upload logic
      //const response = await fakeUploadFunction(file); // Use an API call or function to upload the file
      // let formData = new FormData();
      // formData.append('file', file);
      // formData.append('assetID', selectedAsset._id);
      // formData.append('_id', selectedAsset._id);
    
      // console.log('uploadImageApi formData:', formData);
      // var options = { content: formData };
      // console.log('uploadImageApi options:', options);

    
      console.log('calling  uploadImageApi with file:   ', file, '   and assetID:   ', selectedAsset._id, '   and _id:   ', selectedAsset._id)
      const response = await uploadImageApi({file, assetID: selectedAsset._id, _id: selectedAsset._id});
      //const response = await uploadImageApi2(formData, selectedAsset._id);
      console.log('response from uploadImageApi:   ', response);
      dispatch(uploadImageSuccess2({ index, url: response.url }));
    } catch (err) {
      dispatch(uploadImageFailure2("err.message"));
    }
  };


  // Fake upload function for demonstration
const fakeUploadFunction = async (file: File) => {
  return new Promise<{ url: string }>((resolve) => {
    setTimeout(() => resolve({ url: URL.createObjectURL(file) }), 1000);
  });
};

  const handleSaveImages = () => {
    // Upload each image separately
    images.forEach((image, index) => {
      if (image) {
        handleImageUpload(image, index);
      }
    });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAssetTitle(e.target.value);
  };


  return (
    <div className={styles.basicDetailContainer}>
      <h2 className={styles.heading}>Basic Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />
      
      <div className={styles.formGroup}>
        <label>Asset Title:</label>
        <input
          type="text"
          value={assetTitle}
          onChange={handleTitleChange}
          className={styles.inputBox}
        />
      </div>

      <div className={styles.formGroup}>
        <label>Asset Description:</label>
        <textarea
          value={assetDescription}
          onChange={handleDescriptionChange}
          maxLength={1000}
          className={styles.textArea}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={handleSaveBasicDetails}>Save Basic Details</button>
      </div>

      <div className={styles.formGroup}>
        <label>Upload Asset Images (Max 5):</label>
        <div className={styles.imageUploadContainer}>
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className={styles.imagePlaceholder}>
              {imagePreviews[index] ? (
                <img src={imagePreviews[index]} alt={`Preview ${index}`} className={styles.imagePreview} />
              ) : (
                <span>+</span>
              )}

              <input
                type="file"
                onChange={(e) => handleImageChange(index, e)}
                className={styles.fileInput}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSaveImages}>Save Images</button>
      </div>
    </div>
  );
};

export default BasicDetail;

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/admin-users';

export const registerApi = async (userData: { email: string; password: string }) => {
  const response = await axios.post(`${API_BASE_URL}/users/register`, userData);
  return response.data;
};

export const loginApi = async (userData: { username: string; password: string }) => {
  console.log('loginApi ', userData);
  let response = null;

  try {
    response = await axios.post(`${API_BASE_URL}/login`, userData);
    console.log('loginApi ', response);  
  }
  catch (e:any) {
    console.log(e);
    response = e.response;
  }
  return response;
};

export const logoutApi = async () => {
  //TODO:// Add withCredentials: true to the request
  //const response = await axios.post('http://localhost:3000/users/logout', {}, { withCredentials: true });
  const response = await axios.post(`${API_BASE_URL}/users/logout`, {});
  return response.data;
};

export const refreshTokenRefreshApi = async () => {
  const response = await axios.post(`${API_BASE_URL}/users/refresh-token`, {}, { withCredentials: true });
  return response.data;
};

export const validateTokenApi = async () => {
  const response = await axios.post(`${API_BASE_URL}/users/validate-token`, {}, { withCredentials: true });
  return response.data;
};

export const changePasswordApi = async (userID: string, currentPassword: string, newPassword: string) => {
  const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
  const response = await axios.post(`${API_BASE_URL}/users/change-password`,
    { userID, currentPassword, newPassword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const verifyEmailApi = async (token: string) => {
  const response = await axios.get(`${API_BASE_URL}/users/verify-email`, {
    params: { token },
  });
  return response.data;
};

export const requestPasswordResetApi = async (email: any) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/request-password-reset`, { email });
    return response.data;
  } catch (error) {
    throw new Error('Failed to request password reset');
  }
};

export const resetPasswordApi = async (token: any, newPassword: any) => {
  try {
    const response = await axios.patch(`${API_BASE_URL}/users/reset-password?token=${token}`, {
      newPassword,
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to reset password');
  }
};

export const resendEmailVerificationApi = async (userID: string) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/users/resend-email-verification`, { userID });
    return response.data;
  } catch (error) {
    throw new Error('Unable to send verification email');
  }
};

export const checkEmailVerifiedAPI = async (userID: string) => {
  console.log('checkEmailVerifiedAPI');
  console.log(userID);
  try {
    const response = await axios.get(`${API_BASE_URL}/users/is-email-verified`, {
      params: { userID },
    });
    return response.data;
  } catch (error) {
    throw new Error('Unable to send verification email');
  }
};
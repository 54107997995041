// src/components/AssetDetailEditor/AssetFeatures.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetFeatures.module.css';

interface AssetFeaturesProps {
  features: string[];
}

const AssetFeatures: React.FC<AssetFeaturesProps> = ({ features }) => {
  return (
    <div className={styles.featuresContainer}>
      <h2 className={styles.featuresHeading}>Features</h2>
      <div className={styles.featuresGrid}>
        {features.map((feature, index) => (
          <div key={index} className={styles.featureItem}>
            <FontAwesomeIcon icon={faCheck} className={styles.featureIcon} />
            <span className={styles.featureText}>{feature}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetFeatures;

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl + '/monthly-financial';

// API to fetch all monthly spending details for a specific asset
export const getMonthlySpendingsApi = async (fetchDetails: any) => {
  try {
    const { assetID, month, year } = fetchDetails;
    const response = await axios.get(`${API_BASE_URL}/spendings/${assetID}/${year}/${month}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching monthly spendings');
  }
};

// API to update spending details for a specific asset and month
export const updateMonthlySpendingApi = async (assetID: string, spendingDetails: any) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/spending/${assetID}`, spendingDetails);
    return response.data;
  } catch (error) {
    throw new Error('Error updating monthly spending');
  }
};

// API to add new spending details for a specific asset
export const addMonthlySpendingApi = async (spendingDetails: any) => {
  try {
    const assetID = spendingDetails.assetID;
    const response = await axios.post(`${API_BASE_URL}/${assetID}/spendings`, spendingDetails);
    return response.data;
  } catch (error) {
    throw new Error('Error adding new monthly spending');
  }
};

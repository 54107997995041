// src/components/UnpublishedEditor/UnpublishedEditor.tsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../../store/rootReducer';
import CreateNewAsset from '../CreateNewAsset/CreateNewAsset';
import ShowUnpublishedAsset from '../ShowUnpublishedAsset/ShowUnpublishedAsset';
import styles from './AssetManagementViewer.module.css';
import CreateAsset from '../CreateAsset/CreateAsset';
import BasicDetails from '../AssetDetailEditor/BasicDetail';
import LocationDetails from '../AssetDetailEditor/LocationDetail';
import InternalStructure from '../AssetDetailEditor/InternalStructure';
import RentDetails from '../AssetDetailEditor/RentDetails';
import TokenOfferingDetails from '../AssetDetailEditor/TokenOfferingDetails';
import ExternalStructure from '../AssetDetailEditor/ExternalStructure';
import PurchaseDetails from '../AssetDetailEditor/PurchaseDetails';
import TokenDetails from '../AssetDetailEditor/TokenDetails';
import ValuationDetails from '../AssetDetailEditor/ValuationDetails';
import PublishDetails from '../AssetDetailEditor/PublishDetails';
import FinancialDetails from '../AssetDetailEditor/FinancialDetails';
import AssetFeatures from '../AssetDetailEditor/AssetFeatures';
import MonthlyRentDetails from '../AssetDetailEditor/MonthlyRentDetails';
import AssetUpdates from '../AssetDetailEditor/AssetUpdates';
import AssetTokenization from '../AssetDetailEditor/AssetTokenization';
import HowToCreateAsset from '../AssetDetailEditor/HowToCreateAsset';
import DistributionDetails from '../AssetDetailEditor/DistrbutionDetails';
import HowToManageAsset from './HowToManageAsset';
import TokenPurchases from './TokenPurchases';
import UpdateTokenPrice from './UpdateTokenPrice';
import AddOffers from './AddOffers';
import MonthlySpendingDetails from '../AssetDetailEditor/MonthlySpendingDetails';
import AssetSettings from '../AssetDetailEditor/AssetSettings';

// Custom Modal Component
const Modal: React.FC<{ isOpen: boolean; onClose: () => void; aid: string }> = ({ isOpen, onClose, aid }) => {
    if (!isOpen) return null;
  
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
          <iframe
            src={`/me/assetv/${aid}`}
            className={styles.marketplaceViewer}
            title="Marketplace Viewer"
          />
        </div>
      </div>
    );
  };
  

const UnpublishedEditor: React.FC = () => {
  const navigate = useNavigate();
  const { pid, aid } = useParams<{ pid: string; aid: string }>(); // Extracting pid and aid from the URL

  const selectedAsset = useSelector((state: RootState) => state.asset.selectedAsset);
  const [selectedMenu, setSelectedMenu] = useState<string>('Location Details');
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  if (!selectedAsset) return null;
  
  console.log('selectedAsset', selectedAsset);
  console.log('Property ID:', pid, 'Asset ID:', aid); // Logging pid and aid for debugging

  const menus = [
    'What are the Asset Management Tasks',
    'Token Purchases',
    'Valuation Details',
    'Update Token Price',
    'Financial Details',
    'Token Offering Details', 
    'Rent Contract Details', 
    'Monthly Rent Details',
    'Monthly Spending Details',
    'Distribution Details',
    'Add Offers',
    'Asset Updates',
    'Asset Settings',
  ];



  const renderContent = () => {
    switch (selectedMenu) {
        case 'What are the Asset Management Tasks':
            return <HowToManageAsset />;
        case 'Token Purchases':
            return <TokenPurchases selectedAsset={selectedAsset} />;
        // case 'Location Details':
        //     return <LocationDetails selectedAsset={selectedAsset} />;
        // case 'Internal Details':
        //     return <InternalStructure selectedAsset={selectedAsset} />;
        // case 'External Details':
        //     return <ExternalStructure selectedAsset={selectedAsset} />;
        // case 'Asset Features':
        //     return <AssetFeatures selectedAsset={selectedAsset} />;
        // case 'Purchase Details':
        //     return <PurchaseDetails selectedAsset={selectedAsset} />;
        case 'Rent Contract Details':
            return <RentDetails selectedAsset={selectedAsset} />;
        case 'Token Offering Details':
            return <TokenOfferingDetails selectedAsset={selectedAsset} />;
        // case 'Blockchain Details':
        //     return <TokenDetails selectedAsset={selectedAsset} />;
        case 'Valuation Details':
            return <ValuationDetails selectedAsset={selectedAsset} initialValuation={false} />;
        // case 'Publish Details':
        //     return <PublishDetails selectedAsset={selectedAsset} />;
        case 'Financial Details':
            return <FinancialDetails selectedAsset={selectedAsset} />;
        case 'Monthly Rent Details':
            return <MonthlyRentDetails selectedAsset={selectedAsset} />;
        case 'Monthly Spending Details':
            return <MonthlySpendingDetails selectedAsset={selectedAsset} />;
        case 'Distribution Details':
            return <DistributionDetails selectedAsset={selectedAsset} />;
        case 'Asset Updates':
            return <AssetUpdates selectedAsset={selectedAsset} />;
        case 'Update Token Price':
            return <UpdateTokenPrice selectedAsset={selectedAsset} />;
        case 'Add Offers':
          return <AddOffers />;
        case 'Asset Settings':
          return <AssetSettings selectedAsset={selectedAsset} />;
              // case 'Tokenization Details':
        //     return <AssetTokenization selectedAsset={selectedAsset} />;
                
                // Add cases for other menu...
        default:
            return <LocationDetails  selectedAsset={selectedAsset}/>;
        
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <ul>

        {menus.map((menu) => (
            <li>
                <button
                    key={menu}
                    className={` ${selectedMenu === menu ? styles.activeMenuItem : ''}`}
                    onClick={() => setSelectedMenu(menu)}
                >
                    {menu}
                </button>
            </li>
        ))}
        </ul>
      </div>
      <div className={styles.content}>
          <div className={styles.header}>
            <button className={styles.backButton} onClick={() => navigate(-1)}>
                &larr; Back
            </button>

            <span><strong>Editing</strong>&nbsp;&nbsp;</span>
            <span><strong>Property ID:</strong> {pid}&nbsp;</span> {/* Displaying pid */}
            <span><strong>Asset ID:</strong> {aid}&nbsp;</span>  {/* Displaying aid */}
            <span>
                <button className={styles.viewButton} onClick={() => setIsModalOpen(true)}>
                View in Marketplace Viewer
                </button>
            </span>

            {/* <h2 className={styles.assetName}>{selectedAsset.name}</h2> */}
          </div>
        {renderContent()}

        {/* Modal for Marketplace Viewer */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} aid={aid || ''} />

      </div>
    </div>
  );
};

export default UnpublishedEditor;

// src/components/AssetDetailEditor/AssetCarousel.tsx
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface AssetCarouselProps {
  images: string[];
}

const AssetCarousel: React.FC<AssetCarouselProps> = ({ images }) => {
  return (
    <Carousel showArrows={true} showThumbs={true} infiniteLoop={true} autoPlay={true}>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default AssetCarousel;

// src/components/AssetDetailEditor/AssetBlockchain.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faTag, faWallet, faDollarSign, faFileContract, faUser, faBarcode } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetBlockchain.module.css';

interface BlockchainProperties {
  tokenName: string;
  tokenSymbol: string;
  tokenSupply: string;
  tokenPrice: string;
  tokenType: string;
  tokenContract: string;
  ownerWalletAddress: string;
}

interface AssetBlockchainProps {
  blockChainProperties: BlockchainProperties;
}

const AssetBlockchain: React.FC<AssetBlockchainProps> = ({ blockChainProperties }) => {
  return (
    <div className={styles.blockchainContainer}>
      <h2 className={styles.blockchainHeading}>Blockchain Details</h2>
      <div className={styles.blockchainGrid}>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faCoins} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Name: <span className={styles.blockchainValue}>{blockChainProperties.tokenName}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faTag} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Symbol: <span className={styles.blockchainValue}>{blockChainProperties.tokenSymbol}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faBarcode} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Supply: <span className={styles.blockchainValue}>{blockChainProperties.tokenSupply}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faDollarSign} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Price: <span className={styles.blockchainValue}>{blockChainProperties.tokenPrice}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faFileContract} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Type: <span className={styles.blockchainValue}>{blockChainProperties.tokenType}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faWallet} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Token Contract: <span className={styles.blockchainValue}>{blockChainProperties.tokenContract}</span>
          </div>
        </div>
        <div className={styles.blockchainItem}>
          <FontAwesomeIcon icon={faUser} className={styles.blockchainIcon} />
          <div className={styles.blockchainText}>
            Owner Wallet Address: <span className={styles.blockchainValue}>{blockChainProperties.ownerWalletAddress}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetBlockchain;

// src/components/AssetDetailEditor/TokenOfferingDetails.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTokenOfferingDetailsRequest, getTokenOfferingDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';

import styles from './TokenOfferingDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface TokenOfferingDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string }; // Assuming Asset is the type of your selected asset
}

const TokenOfferingDetails: React.FC<TokenOfferingDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  console.log('TokenOfferingDetails: Selected Asset:', selectedAsset);
  useSelector((state: RootState) => {
    console.log('TokenOfferingDetails: Token Details:', state.assetEditor);
    return state.assetEditor.tokenOfferingDetails;
  });

  const {
    offeringDate,
    issuerName,
    minInvestmentAmount,
    maxInvestmentAmount,
    publicOfferingPercent,
    companyHoldingPercent,
    offeringCloseDate,
    expectedAmountToRaise,
    actualAmountRaised,
  } = useSelector((state: RootState) => state.assetEditor.tokenOfferingDetails);
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const tokenOfferingDetailsList = useSelector((state: RootState) => state.assetEditor.tokenOfferingDetailsList);
  console.log('Rent Details List:', tokenOfferingDetailsList);
  const _tokenOfferingDetailsList: any = [];
  tokenOfferingDetailsList.map((detail: any) => {  
    _tokenOfferingDetailsList.push(detail);
  });
  console.log('tokenOfferingDetailsList Details List:', _tokenOfferingDetailsList);

  const [offeringDateState, setOfferingDate] = useState('');
  const [issuerNameState, setIssuerName] = useState('');
  const [minInvestmentAmountState, setMinInvestmentAmount] = useState<number>(0);
  const [maxInvestmentAmountState, setMaxInvestmentAmount] = useState<number>(0);
  const [publicOfferingPercentState, setPublicOfferingPercent] = useState<number>(0);
  const [companyHoldingPercentState, setCompanyHoldingPercent] = useState<number>(0);
  const [offeringCloseDateState, setOfferingCloseDate] = useState('');
  const [expectedAmountToRaiseState, setExpectedAmountToRaise] = useState<number>(0);
  const [actualAmountRaisedState, setActualAmountRaised] = useState<number>(0);

  useEffect(() => {
    dispatch(getTokenOfferingDetailsRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    setOfferingDate(offeringDate);
    setIssuerName(issuerName);
    setMinInvestmentAmount(minInvestmentAmount);
    setMaxInvestmentAmount(maxInvestmentAmount);
    setPublicOfferingPercent(publicOfferingPercent);
    setCompanyHoldingPercent(companyHoldingPercent);
    setOfferingCloseDate(offeringCloseDate);
    setExpectedAmountToRaise(expectedAmountToRaise);
    setActualAmountRaised(actualAmountRaised);
  }, [offeringDate, issuerName, minInvestmentAmount, maxInvestmentAmount, publicOfferingPercent, companyHoldingPercent, offeringCloseDate, expectedAmountToRaise, actualAmountRaised]);

  const handleSave = () => {
    const _id = selectedAsset._id;
    const tokenOfferingDetails = { assetID: _id, offeringDate: offeringDateState, issuerName: issuerNameState, minInvestmentAmount: minInvestmentAmountState, maxInvestmentAmount: maxInvestmentAmountState, publicOfferingPercent: publicOfferingPercentState, companyHoldingPercent: companyHoldingPercentState, offeringCloseDate: offeringCloseDateState, expectedAmountToRaise: expectedAmountToRaiseState, actualAmountRaised: actualAmountRaisedState };
    const assetID = selectedAsset.assetID;
    console.log('Saving token offering details:', tokenOfferingDetails);
    dispatch(saveTokenOfferingDetailsRequest({ tokenOfferingDetails, assetID, _id }));
  };

  return (
    <div className={styles.tokenOfferingDetailsContainer}>
      <h2 className={styles.heading}>Token Offering Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.tableContainer}>
        <h3>Offering Details Summary</h3>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div className={styles.tableHeader}>Issuer</div>
            <div className={styles.tableHeader}>Offering Date</div>
            <div className={styles.tableHeader}>Minimum Investment</div>
            <div className={styles.tableHeader}>Maximum Investment</div>
            <div className={styles.tableHeader}>Public Offering Percent</div>
            <div className={styles.tableHeader}>Company Holding Percent</div>
            <div className={styles.tableHeader}>Offering Close Date</div>
            <div className={styles.tableHeader}>Expected amount to raise</div>
            <div className={styles.tableHeader}>Actual amount raised</div>
          </div>
          {_tokenOfferingDetailsList.map((detail: any) => (
            <div key={detail._id} className={styles.tableRow}>
              <div className={styles.tableCell}>{detail.issuerName}</div>
              <div className={styles.tableCell}>{new Date(detail.offeringDate).toLocaleDateString()}</div>
              <div className={styles.tableCell}>{detail.minInvestmentAmount}</div>
              <div className={styles.tableCell}>{detail.maxInvestmentAmount}</div>
              <div className={styles.tableCell}>{detail.publicOfferingPercent}</div>
              <div className={styles.tableCell}>{detail.companyHoldingPercent}</div>
              <div className={styles.tableCell}>{new Date(detail.offeringCloseDate).toLocaleDateString()}</div>
              <div className={styles.tableCell}>{detail.expectedAmountToRaise}</div>
              <div className={styles.tableCell}>{detail.actualAmountRaised}</div>
            </div>
          ))}
        </div>
      </div>


      <h2 className={styles.heading}>Add New Offering</h2>

      <div className={styles.formGroup}>
        <label>Offering Date:</label>
        <input type="date" value={offeringDateState} onChange={(e) => setOfferingDate(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Issuer Name:</label>
        <input type="text" value={issuerNameState} onChange={(e) => setIssuerName(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Minimum Investment Amount:</label>
        <input type="number" value={minInvestmentAmountState.toString()} onChange={(e) => setMinInvestmentAmount(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Maximum Investment Amount:</label>
        <input type="number" value={maxInvestmentAmountState.toString()} onChange={(e) => setMaxInvestmentAmount(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Public Offering Percent:</label>
        <input type="number" value={publicOfferingPercentState.toString()} onChange={(e) => setPublicOfferingPercent(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Company Holding Percent:</label>
        <input type="number" value={companyHoldingPercentState.toString()} onChange={(e) => setCompanyHoldingPercent(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Offering Close Date:</label>
        <input type="date" value={offeringCloseDateState} onChange={(e) => setOfferingCloseDate(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Expected Amount to Raise:</label>
        <input type="number" value={expectedAmountToRaiseState.toString()} onChange={(e) => setExpectedAmountToRaise(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Actual Amount Raised:</label>
        <input type="number" value={actualAmountRaisedState.toString()} onChange={(e) => setActualAmountRaised(parseInt(e.target.value))} />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default TokenOfferingDetails;

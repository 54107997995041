// src/components/AdminDashboard/AdminDashboard.tsx
import React, { useState } from 'react';
import CreateNewAsset from '../CreateNewAsset/CreateNewAsset';
import ShowUnpublishedAsset from '../ShowUnpublishedAsset/ShowUnpublishedAsset';
import styles from './AdminDashboard.module.css';
import CreateAsset from '../CreateAsset/CreateAsset';
import AssetViewer from '../AssetViewer/AssetViewer';
import UserDetailViewer from '../UserDetailViewer/UserDetailViewer';
import AdminDashboard_ from '../../AdminDashboard/AdminDashboard';
import AssetManagement from '../AssetManagement/AssetManagement';
import AdminUsers from '../AdminUsers/AdminUsers';
import AssetValuationCompanies from '../AssetValuationCompanies/AssetValuationCompanies';
import Offers from '../Offers/Offers';
import BackendPartners from '../BackendPartners/BackendPartners';
import RegistrationWaitingList from '../RegistrationWaitinglist/RegistrationWaitinglist';

const AdminDashboard: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>('admin-dashboard');

  const renderContent = () => {
    switch (selectedMenu) {
      case 'admin-dashboard':
        return <AdminDashboard_ />;
      case 'create-new-asset':
        return <CreateAsset />;
      case 'asset-viewer':
        return <AssetViewer />;
      case 'show-unpublished-asset':
        return <ShowUnpublishedAsset />;
      case 'user-details-viewer':
        return <UserDetailViewer />;
      case 'asset-management':
        return <AssetManagement />;  
      case 'admin-users':
        return <AdminUsers />;
      case 'backend-partners':
        return <BackendPartners />;
      case 'offers':
        return <Offers />;
      case 'registration-waitinglist':
        return <RegistrationWaitingList />;
      default:
        return <CreateAsset />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <ul>
        <li>
            <button
              onClick={() => setSelectedMenu('admin-dashboard')}
              className={selectedMenu === 'admin-dashboard' ? styles.activeMenuItem : ''}
            >
              Dashboard
            </button>
          </li>
          <li>
            <button
              onClick={() => setSelectedMenu('create-new-asset')}
              className={selectedMenu === 'create-new-asset' ? styles.activeMenuItem : ''}
            >
              Asset Creation
            </button>
          </li>
          <li>
            <button
              onClick={() => setSelectedMenu('asset-management')}
              className={selectedMenu === 'asset-management' ? styles.activeMenuItem : ''}
            >
              Asset Management
            </button>
          </li>
          {/* <li>
            <button
              onClick={() => setSelectedMenu('asset-viewer')}
              className={selectedMenu === 'asset-viewer' ? styles.activeMenuItem : ''}
            >
              Asset Viewer
            </button>
          </li> */}
          <li>
            <button
              onClick={() => setSelectedMenu('user-details-viewer')}
              className={selectedMenu === 'user-details-viewer' ? styles.activeMenuItem : ''}
            >
              User Details
            </button>
          </li>
          <li>
            <button
              onClick={() => setSelectedMenu('admin-users')}
              className={selectedMenu === 'admin-users' ? styles.activeMenuItem : ''}
            >
              Admin Users
            </button>
          </li>
          <li>
            <button
              onClick={() => setSelectedMenu('offers')}
              className={selectedMenu === 'offers' ? styles.activeMenuItem : ''}
            >
              Offers
            </button>
          </li>
          <li>
            <button
              onClick={() => setSelectedMenu('backend-partners')}
              className={selectedMenu === 'backend-partners' ? styles.activeMenuItem : ''}
            >
              Backend Partners
            </button>
          </li>
          <li>
            <button
              onClick={() => setSelectedMenu('registration-waitinglist')}
              className={selectedMenu === 'registration-waitinglist' ? styles.activeMenuItem : ''}
            >
              Registration Waiting List
            </button>
          </li>
        </ul>
      </div>
      <div className={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

export default AdminDashboard;

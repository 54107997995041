import devConfig from "./config.development";
import prodConfig from "./config.production";
import localConfig from "./config.local";
import { get } from "http";


const config = {
    development: devConfig,
    production: prodConfig,
    local: localConfig
  };

const getConfig = () => {
    console.log("admin::Getting config");
    console.log(process.env.REACT_APP_ADMIN_APP_ENV);
    const env = process.env.REACT_APP_ADMIN_APP_ENV || "local";
    console.log(`Environment: ${env}`);
    return config[env as keyof typeof config];
  };


export default getConfig();
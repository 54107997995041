// src/components/UnpublishedEditor/UnpublishedEditor.tsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store/rootReducer';
import styles from './PublishedViewer.module.css';
import AssetPage from '../AssetDetailViewer/AssetPage/AssetPage';


const PublishedViewer: React.FC = () => {
  const navigate = useNavigate();

  const selectedAsset = useSelector((state: RootState) => state.asset.selectedAsset);
  const [selectedMenu, setSelectedMenu] = useState<string>('Asset Details');

  if (!selectedAsset) return null;

  console.log('selectedAsset', selectedAsset);

  const menus = [
    'Asset Details',
    'Asset Tokens',
  ];



  const renderContent = () => {
    switch (selectedMenu) {
        case 'Asset Details':
            return <AssetPage />;
        default:
            return <AssetPage />;
        
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <ul>

        {menus.map((menu) => (
            <li>
                <button
                    key={menu}
                    className={` ${selectedMenu === menu ? styles.activeMenuItem : ''}`}
                    onClick={() => setSelectedMenu(menu)}
                >
                    {menu}
                </button>
            </li>
        ))}
        </ul>
      </div>
      <div className={styles.content}>
          <div className={styles.header}>
            <button className={styles.backButton} onClick={() => navigate(-1)}>
                &larr; Back
            </button>
            <h2 className={styles.assetName}>{selectedAsset.name}</h2>
          </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default PublishedViewer;

// src/components/ShowUnpublishedAsset/ShowUnpublishedAsset.tsx
import React from 'react';

const ShowUnpublishedAsset: React.FC = () => {
  return (
    <div>
      <h2>Show Unpublished Asset</h2>
      <p>Content for showing unpublished assets goes here.</p>
    </div>
  );
};

export default ShowUnpublishedAsset;

// UserDetailViewer.tsx

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { createAssetRequest, showUnpublishedAssetsRequest, selectAsset } from '../../../../features/asset/assetSlice';
import { useNavigate } from 'react-router-dom';
import styles from './UserDetailViewer.module.css';
import { Link } from 'react-router-dom';
import AssetDetailEditor from '../AssetDetailEditor/AssetDetailEditor';

const UserDetailViewer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success, unpublishedAssets, selectedAsset } = useSelector((state: RootState) => state.asset);

  console.log('unpublishedAssets', unpublishedAssets);

  const handleCreateNewAsset = () => {
    dispatch(createAssetRequest());
  };

  const handleShowUnpublishedAssets = () => {
    dispatch(showUnpublishedAssetsRequest());
  };

  const handleSelectAsset = (asset: { assetID: string; name: string, _id: string }) => {
    dispatch(selectAsset(asset));
    navigate(`/admin/published-viewer`);
    //navigate(`/admin/unpublished-editor/${asset._id}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={handleShowUnpublishedAssets}>
          Show Users List
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>Asset created successfully!</p>}
      <div>
        {unpublishedAssets?.length > 0 && !selectedAsset && (
          <div className={styles.unpublishedAssets}>
            <h3>Unpublished Assets ({unpublishedAssets?.length})</h3>
            <ul>
              {unpublishedAssets.map((asset, index) => (                
                <li key={index} onClick={() => handleSelectAsset(asset)}>
                  {asset.assetID}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/*{selectedAsset && <AssetDetailEditor />}*/}
    </div>
  );
};

export default UserDetailViewer;

// src/components/AssetDetailEditor/AssetBasicDetails.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRulerCombined, faBed, faBath, faDoorClosed, faBuilding, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetBasicDetails.module.css';

interface AssetBasicDetailsProps {
  area: string;
  beds: string;
  baths: string;
  rooms: string;
  floors: string;
  garage: string;
  description: string;
}

const AssetBasicDetails: React.FC<AssetBasicDetailsProps> = ({ area, beds, baths, rooms, floors, garage, description }) => {
  return (
    <div className={styles.basicDetailsContainer}>
      <h2 className={styles.basicDetailsHeading}>Description</h2>
      <div className={styles.detailsGrid}>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faRulerCombined} />
          <div className={styles.detailText}>
            Area: <span className={styles.detailValue}>{area}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBed} />
          <div className={styles.detailText}>
            Beds: <span className={styles.detailValue}>{beds}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBath} />
          <div className={styles.detailText}>
            Baths: <span className={styles.detailValue}>{baths}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faDoorClosed} />
          <div className={styles.detailText}>
            Rooms: <span className={styles.detailValue}>{rooms}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faBuilding} />
          <div className={styles.detailText}>
            Floors: <span className={styles.detailValue}>{floors}</span>
          </div>
        </div>
        <div className={styles.detailItem}>
          <FontAwesomeIcon icon={faWarehouse} />
          <div className={styles.detailText}>
            Garage: <span className={styles.detailValue}>{garage}</span>
          </div>
        </div>
      </div>
      <p className={styles.descriptionText}>{description}</p>
    </div>
  );
};

export default AssetBasicDetails;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PropertyManagementCompanies.module.css';
import {
  getCompaniesByTypeRequest,
  addCompanyRequest,
} from '../../../../features/backendPartners/backendPartnerSlice';
import { RootState } from '../../../../store/rootReducer';

interface Company {
  id?: number;
  companyName: string;
  companyCountryLocation: string;
  companyServicingCountry: string;
  companyContactName: string;
  companyContactPhoneNumber: string;
  companyContactEmail: string;
  companyWebsite: string;
}

const PropertyManagementCompanies: React.FC = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state: RootState) => state.BackendPartners.companies);
  const status = useSelector((state: RootState) => state.BackendPartners.status);
  const error = useSelector((state: RootState) => state.BackendPartners.error);

  const companyType = 'Property Management Company';

  // console.log('AssetValuationCompanies ', companies);

  const [newCompany, setNewCompany] = useState<Company>({
    companyName: '',
    companyCountryLocation: '',
    companyServicingCountry: '',
    companyContactName: '',
    companyContactPhoneNumber: '',
    companyContactEmail: '',
    companyWebsite: '',
  });

  const [formError, setFormError] = useState('');

  // Fetch companies of type "Asset Valuation Companies" on load
  useEffect(() => {
    dispatch(getCompaniesByTypeRequest(companyType));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCompaniesByTypeRequest(companyType));
  }, [status, dispatch]);
  

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    
    console.log('handleInputChange')
    console.log('newCompany', newCompany);

    console.log('e.target', e.target);

    const { name, value } = e.target;
    console.log('name', name);
    console.log('value', value);
    setNewCompany({
      ...newCompany,
      [name]: value,
    });
  };

  // Validate form and dispatch action to add new company
  const addCompany = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('addCompany ', newCompany)
    const { companyName, companyContactEmail } = newCompany;

    if (!companyName || !companyContactEmail) {
      setFormError('Company Name and Contact Email are required');
      return;
    }

    dispatch(addCompanyRequest({ ...newCompany, companyType: companyType }));
    setNewCompany({
      companyName: '',
      companyCountryLocation: '',
      companyServicingCountry: '',
      companyContactName: '',
      companyContactPhoneNumber: '',
      companyContactEmail: '',
      companyWebsite: '',
      });
    setFormError('');
  };

  return (
    <div className={styles.container}>
      <h2>Property Management Companies</h2>

      {/* Notification Banner */}
      {status === 'loading' && <div className={styles.notification}>Loading...</div>}
      {status === 'succeeded' && <div className={styles.notificationSuccess}>Company added successfully!</div>}
      {status === 'failed' && <div className={styles.notificationError}>Error: {error}</div>}

      {/* Table displaying companies */}
      <table className={styles.companyTable}>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Company Country Location</th>
            <th>Country Servicing</th>
            <th>Contact Name</th>
            <th>Contact Number</th>
            <th>Contact Email</th>
            <th>Website</th>
          </tr>
        </thead>
        <tbody>
          {companies?.length === 0 ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                No companies added yet
              </td>
            </tr>
          ) : (
            companies.map((company: Company, index: number) => (
              <tr key={index}>
                <td>{company?.companyName}</td>
                <td>{company?.companyCountryLocation}</td>
                <td>{company?.companyServicingCountry}</td>
                <td>{company?.companyContactName}</td>
                <td>{company?.companyContactPhoneNumber}</td>
                <td>{company?.companyContactEmail}</td>
                <td>
                  <a href={company?.companyWebsite} target="_blank" rel="noopener noreferrer">
                    {company?.companyWebsite}
                  </a>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Form to add a new company */}
      <div className={styles.formContainer}>
        <h3>Add New Company</h3>
        <form onSubmit={addCompany}>
          {formError && <div className={styles.error}>{formError}</div>}

          <div className={styles.formGroup}>
            <label>Company Name</label>
            <input
              type="text"
              name="companyName"
              value={newCompany.companyName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label>Country Servicing</label>
            <input
              type="text"
              name="companyCountryLocation"
              value={newCompany.companyCountryLocation}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Country Servicing</label>
            <input
              type="text"
              name="companyServicingCountry"
              value={newCompany.companyServicingCountry}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Contact Name</label>
            <input
              type="text"
              name="companyContactName"
              value={newCompany.companyContactName}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Contact Number</label>
            <input
              type="text"
              name="companyContactPhoneNumber"
              value={newCompany.companyContactPhoneNumber}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Contact Email</label>
            <input
              type="email"
              name="companyContactEmail"
              value={newCompany.companyContactEmail}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.formGroup}>
            <label>Website</label>
            <input
              type="url"
              name="companyWebsite"
              value={newCompany.companyWebsite}
              onChange={handleInputChange}
            />
          </div>

          <button type="submit" className={styles.submitButton}>
            Add Company
          </button>
        </form>
      </div>
    </div>
  );
};

export default PropertyManagementCompanies;

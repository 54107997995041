import React, { useEffect } from 'react';
import styles from './RegistrationWaitinglist.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { approveWaitingListRequest, fetchWaitingListRequest } from '../../../../features/registrationWaitingList/waitingListSlice';

interface WaitingListEntry {
  email: string;
  approvalStatus: string;
  registrationStatus: string;
}

interface RegistrationWaitingListProps {
}

const RegistrationWaitingList: React.FC<RegistrationWaitingListProps> = () => {
    const dispatch = useDispatch();
    const { entries, loading, error } = useSelector((state: RootState) => state.registrationWaitingList);

    useEffect(() => {
        dispatch(fetchWaitingListRequest());
      }, [dispatch]);
    
    const handleApprove = (email: string) => {
        dispatch(approveWaitingListRequest({ emailId: email }));
    };
      
      if (loading) return <div>Loading...</div>;
      if (error) return <div>Error: {error}</div>;
    
    
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Registration Waiting List</h2>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Email ID</th>
            <th>Approval Status</th>
            <th>Registration Status</th>
            <th>Submitted Date</th>
          </tr>
        </thead>
        <tbody>
          {entries.length > 0 ? (
            entries.map((entry, index) => (
              <tr key={index}>
                <td>{entry.emailId}</td>
                <td>{entry.status === 'pending' ? <button onClick={() => handleApprove(entry.emailId)}>Approve</button> : entry.status}</td>
                <td>{entry.registrationStatus}</td>
                <td>{entry.joinedAt}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className={styles.noData}>
                No entries available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RegistrationWaitingList;

// src/features/asset/assetSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationDetails, BasicDetails, InternalStructureDetails, RentDetails, TokenOfferingDetails,
  ExternalStructureDetails, PurchaseDetails, TokenDetails, ValuationDetails, FinancialDetails, ResponseGenerator,
  AssetFeatures, MonthlyRentDetails, AssetUpdate, AssetTokenization, ITokenPriceDetails,
  ITokenAvailabilityDetails, ITokenPurchaseData,
  PurchaseHistory
 } from './types';

interface AssetEditorState {
    addressDetails: LocationDetails;
    basicDetails: BasicDetails;
    internalStructureDetails: InternalStructureDetails;
    externalStructureDetails: ExternalStructureDetails;
    rentDetails: RentDetails;
    rentDetailsList: RentDetails[];
    tokenOfferingDetails: TokenOfferingDetails;
    tokenOfferingDetailsList: TokenOfferingDetails[];
    purchaseDetails: PurchaseDetails;
    tokenDetails: TokenDetails;
    valuationDetails: ValuationDetails;
    valuationDetailsList: ValuationDetails[];
    financialDetails: FinancialDetails;
    assetFeatures: AssetFeatures;
    publishStatus: boolean;
    monthlyRentDetails: MonthlyRentDetails | null;
    monthlyRentDetailsList: MonthlyRentDetails[];
    selectedMonthlyRentDetails: any;
    updates: AssetUpdate[];
    purchasePrice: number;
    assetTokenization: AssetTokenization;
    activeTokenPrice: ITokenPriceDetails | null;
    initialTokenPrice: ITokenPriceDetails | null;
    tokenPriceHistory: ITokenPriceDetails[];
    tokenAvailability: ITokenAvailabilityDetails | null;
    tokenPurchaseData: ITokenPurchaseData | null;
    purchaseHistory: PurchaseHistory[];
    totalTokenPurchased: number;
    imageUploadStatus : 'idle' | 'uploading' | 'success' | 'failure';
    imageUrls: string[];
    loading: boolean;
    success: boolean | null;
    successMessage: string | null;
    error: string | null;
}

const initialState: AssetEditorState = {
    addressDetails: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        province: '',
        country: '',
        zipcode: '',
    },
    basicDetails: {
        assetTitle: '',
        assetDescription: '',
        images: [],
        propertyID: 0,
        province: '',
        country: ''    
    },
    internalStructureDetails: {
        numberOfBedRoom: 0,
        numberOfBathroom: 0,
        interiorSizeInSqft: 0,
        heating: '',
        cooling: '',
    },
    externalStructureDetails: {
        propertyType: '',
        lotSizeInSqft: 0,
        foundation: '',
        exteriorWalls: '',
        roofType: '',
        parking: '',
        buildingClass: '',
        constructionYear: 0,
        stories: 0,
    },
    rentDetails: {
      rentalPrice: 0,
      rentalStart: '',
      rentalEnd: '',
      rentalStatus: '',
      monthlyUtilities: 0,
      adminCharge: 0,
      comment: '',
      tenantName: '',
      tenantContact: '',
    },
    rentDetailsList: [],
    tokenOfferingDetails: {
      offeringDate: '',
      issuerName: '',
      minInvestmentAmount: 0,
      maxInvestmentAmount: 0,
      publicOfferingPercent: 0,
      companyHoldingPercent: 0,
      offeringCloseDate: '',
      expectedAmountToRaise: 0,
      actualAmountRaised: 0,
    },
    tokenOfferingDetailsList: [],
    purchaseDetails: {
      purchasePrice: 0,
      purchaseDate: '',
      purchasePriceExtras: 0,
      purchaseComments: '',
      adminFee: 0,
      dldFee: 0,
      customFields: {}
    },    
    tokenDetails: {
      tokenName: '',
      tokenId: '',
      totalNumberOfTokens: 0,
      tokenType: '',
      contractAddress: '',
      ownerWalletAddress: '',
    },    
    valuationDetails: {
      valuationCost: 0,
      valuationDate: '',
      valuationStatus: '',
      valuationCompany: '',
    },    
    valuationDetailsList: [],
    financialDetails: {
      monthlyGrossIncome: 0,
      propertyManagementCostPerMonth: 0,
      platformFeePerMonth: 0,
      maintenanceExpensePerMonth: 0,
      propertyTaxesPerMonth: 0,
      insurancePerMonth: 0,
      utilitiesPerMonth: 0,
    },
    assetFeatures: {
      cooling: false,
      heating: false,
      fireAlarm: false,
      gym: false,
      balcony: false,
      modernKitchen: false,
      pool: false,
      elevator: false,
      petFriendly: false,
      storage: false,
      laundry: false,
      dishWasher: false,    
    },
    monthlyRentDetails: {
      year: 0,
      month: '',
      rentalStatus: '',
      rentAmount: 0,
      lateFees: 0,
      propertyManagementFees: 0,
      assetSlicesFees: 0,
      maintenanceExpense: 0,
      propertyTax: 0,
      ownerInsurance: 0,
      renterInsurance: 0,
      utilitiesElectric: 0,
      utilitiesWater: 0,
      utilitiesWifi: 0,
      utilitiesCable: 0,
      utilitiesPhone: 0,
      utilitiesLandscaping: 0,
      legalFees: 0,
      professionalFees: 0
    },
    monthlyRentDetailsList: [],
    selectedMonthlyRentDetails: null,
    purchasePrice: 0,
    assetTokenization: {
      tokenLaunchingPrice: 0,
      totalNumberOfTokens: 0,
      tokenType: '',
    },
    activeTokenPrice: null,
    initialTokenPrice: null,
    tokenPriceHistory: [],
    tokenAvailability: null,
    tokenPurchaseData: null,
    purchaseHistory: [],
    totalTokenPurchased: 0,
    updates: [],
    imageUploadStatus: 'idle',
    imageUrls: [],
    publishStatus: false,
    loading: false,
    success: null,
    successMessage: null,
    error: null,
  };

const assetSlice = createSlice({
  name: 'assetEditor',
  initialState,
  reducers: {
    getLocationDetailsRequest: (state, action: PayloadAction<string>) => {
        state.loading = true;
        state.success = null;
        state.error = null;
    },
    getLocationDetailsSuccess: (state, action: PayloadAction<LocationDetails>) => {
      return { 
        ...state, 
        addressDetails: {...action.payload}, 
        loading: false, 
        success: true, 
        successMessage: 'Location details fetched successfully',
        error: null 
      };
    },
    getLocationDetailsFailure: (state, action: PayloadAction<string>) => {
      state.success = false;
      state.error = action.payload;
      state.loading = false;
    },
    saveLocationDetailsRequest(state, action: PayloadAction<{ locationDetails: LocationDetails; assetID: string; _id: string }>) {
      state.success = null;
      state.error = null;
    },
    saveLocationDetailsSuccess(state) {
      state.success = true;
      state.error = null;
    },
    saveLocationDetailsFailure(state, action: PayloadAction<string>) {
      state.success = false;
      state.error = action.payload;
    },

    saveBasicDetailsRequest(state, action: PayloadAction<{ basicDetails: BasicDetails; assetID: string; _id: string }>) {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    saveBasicDetailsSuccess(state) {
      state.loading = false;
      state.success = true;
    },
    saveBasicDetailsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    getBasicDetailsRequest: (state, action: PayloadAction<string>) => {
        state.loading = true;
        state.success = null;
        state.error = null;
    },
    getBasicDetailsSuccess: (state, action: PayloadAction<ResponseGenerator>) => {
      if (action.payload?.data) {
        state.basicDetails = action.payload?.data;
      }
      state.loading = false;
      state.successMessage = action.payload?.statusText || '';
    },
    getBasicDetailsFailure: (state, action: PayloadAction<string>) => {
      state.success = false;
      state.error = action.payload;
      state.loading = false;
    },

    saveAssetDescriptionRequest(state, action: PayloadAction<{ assetTitle: string, assetDescription: string; assetID: string; _id: string }>) {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    saveAssetDescriptionSuccess(state) {
      state.loading = false;
      state.success = true;
    },
    saveAssetDescriptionFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    // Internal structure details actions
    saveInternalStructureRequest(state, action: PayloadAction<{ internalStructureDetails: InternalStructureDetails; assetID: string; _id: string }>) {
        state.loading = true;
        state.success = null;
        state.error = null;
      },
      saveInternalStructureSuccess(state) {
        state.loading = false;
        state.success = true;
      },
      saveInternalStructureFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      getInternalStructureRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.success = null;
        state.error = null;
      },
      getInternalStructureSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.internalStructureDetails = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getInternalStructureFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

    // External structure details actions
    saveExternalStructureRequest(state, action: PayloadAction<{ externalStructureDetails: ExternalStructureDetails; assetID: string; _id: string }>) {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    saveExternalStructureSuccess(state) {
      state.loading = false;
      state.success = true;
    },
    saveExternalStructureFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    getExternalStructureRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.success = null;
      state.error = null;
    },
    getExternalStructureSuccess(state, action: PayloadAction<ResponseGenerator>) {
      console.log('getExternalStructureSuccess: action.payload:', action.payload);
      if (action.payload?.data) {
        state.externalStructureDetails = action.payload?.data;
      }
      state.loading = false;
      state.successMessage = action.payload?.statusText || '';
    },
    getExternalStructureFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },



      // Rent details actions

      saveRentDetailsRequest(state, action: PayloadAction<{ rentDetails: RentDetails; _id: string }>) {
        state.loading = true;
        state.success = null;
        state.error = null;
      },
      saveRentDetailsSuccess(state) {
        state.loading = false;
        state.success = true;
      },
      saveRentDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      getRentDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.success = null;
        state.error = null;
      },
      getRentDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.rentDetailsList = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getRentDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },      
      getTokenOfferingDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getTokenOfferingDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.tokenOfferingDetailsList = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getTokenOfferingDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      saveTokenOfferingDetailsRequest(state, action: PayloadAction<{ tokenOfferingDetails: TokenOfferingDetails; assetID: string; _id: string }>) {
        state.loading = true;
        state.error = null;
      },
      saveTokenOfferingDetailsSuccess(state) {
        state.loading = false;
        state.success = true;
      },
      saveTokenOfferingDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
  
      // Purchase details actions

      getPurchaseDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getPurchaseDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.purchaseDetails = action.payload?.data;
        }
        else {
          state.purchaseDetails = {
            purchasePrice: 0,
            purchaseDate: '',
            purchasePriceExtras: 0,
            purchaseComments: '',
            adminFee: 0,
            dldFee: 0,
            customFields: {}
          };

        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getPurchaseDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      savePurchaseDetailsRequest(state, action: PayloadAction<{ purchaseDetails: PurchaseDetails; assetID: string; _id: string }>) {
        state.loading = true;
        state.error = null;
      },
      savePurchaseDetailsSuccess(state) {
        state.loading = false;
        state.success = true;
      },
      savePurchaseDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      // Token details actions

      getTokenDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getTokenDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.tokenDetails = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getTokenDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      saveTokenDetailsRequest(state, action: PayloadAction<{ tokenDetails: TokenDetails; assetID: string; _id: string }>) {
        state.loading = true;
        state.error = null;
      },
      saveTokenDetailsSuccess(state) {
        state.loading = false;
        state.success = true;
        state.successMessage = 'Token details saved successfully';
      },
      saveTokenDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      // Valuation details actions

      getValuationDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getValuationDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.valuationDetailsList = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getValuationDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      saveValuationDetailsRequest(state, action: PayloadAction<{ valuationDetails: ValuationDetails; assetID: string; _id: string }>) {
        state.loading = true;
        state.error = null;
      },
      saveValuationDetailsSuccess(state) {
        state.loading = false;
        state.success = true;
      },
      saveValuationDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      // Publish status actions

      publishAssetRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      publishAssetSuccess(state) {
        state.loading = false;
        state.publishStatus = true;
      },
      publishAssetFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      unpublishAssetRequest(state, action: PayloadAction<{ assetID: string; }>) {
        state.loading = true;
        state.error = null;
      },
      unpublishAssetSuccess(state) {
        state.loading = false;
        state.publishStatus = false;
      },
      unpublishAssetFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      // Image upload actions

      uploadImageRequest(state, action: PayloadAction<any>) {
        state.loading = true;
        state.error = null;
      },
      uploadImageSuccess(state) {
        state.loading = false;
      },
      uploadImageFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      uploadImageStart(state) {
        state.imageUploadStatus = 'uploading';
      },
      uploadImageSuccess2(state, action: PayloadAction<{ index: number; url: string }>) {
        state.imageUploadStatus = 'success';
        state.imageUrls[action.payload.index] = action.payload.url;
      },
      uploadImageFailure2(state, action: PayloadAction<string>) {
        state.imageUploadStatus = 'failure';
        state.error = action.payload;
      },

      // upload valuation document actions
      uploadValuationDocumentRequest(state) {
        state.loading = true;
        state.error = null;
      },
      uploadValuationDocumentSuccess(state) {
        state.loading = false;
      },
      uploadValuationDocumentFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      // Financial details actions

      saveFinancialDetailsRequest(state, action: PayloadAction<any>) {
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      saveFinancialDetailsSuccess(state) {
        state.loading = false;
        state.success = true;
        state.successMessage= 'Financial details saved successfully';
      },
      saveFinancialDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      getFinancialDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getFinancialDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.financialDetails = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getFinancialDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      // Asset features actions

      saveFeaturesRequest(state, action: PayloadAction<any>) {
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      saveFeaturesSuccess(state) {
        state.loading = false;
        state.success = true;
        state.successMessage= 'Asset Features saved successfully';
      },
      saveFeaturesFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      getFeaturesRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getFeaturesSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.assetFeatures = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getFeaturesFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      updateMonthlyRentDetailsRequest(state, action: PayloadAction<any>) {
        console.log('updateMonthlyRentDetailsRequest:', action.payload);
        state.loading = true;
        state.error = null;
        state.success = false;
      },

      updateMonthlyRentDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        if (action.payload?.data) {
          state.monthlyRentDetails = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      updateMonthlyRentDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getAllMonthlyRentDetailsRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },

      getAllMonthlyRentDetailsSuccess(state, action: PayloadAction<ResponseGenerator>) {
        console.log('getAllMonthlyRentDetailsSuccess:', action.payload);
        if (action.payload) {
          state.monthlyRentDetailsList = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getAllMonthlyRentDetailsFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getMonthlyRentDetailsByYearMonthRequest(state, action: PayloadAction<{ assetID: string; year: number; month: string}>) {
        console.log('getMonthlyRentDetailsByYearMonthRequest:', action.payload);
        state.loading = true;
        state.error = null;
      },

      getMonthlyRentDetailsByYearMonthSuccess(state, action: PayloadAction<ResponseGenerator>) {
        console.log('getAllMonthlyRentDetailsSuccess:', action.payload);
        if (action.payload) {
          state.selectedMonthlyRentDetails = action.payload?.data;
        }
        state.loading = false;
        state.successMessage = action.payload?.statusText || '';
      },
      getMonthlyRentDetailsByYearMonthFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getAssetUpdatesRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getAssetUpdatesSuccess(state, action: PayloadAction<AssetUpdate[]>) {
        state.loading = false;
        state.updates = action.payload;
      },
      getAssetUpdatesFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      saveAssetUpdateRequest(state, action: PayloadAction<any>) {
        console.log('saveAssetUpdateRequest:', action.payload); 
        state.loading = true;
        state.error = null;
      },
      saveAssetUpdateSuccess(state, action: PayloadAction<AssetUpdate>) {
        state.loading = false;
        state.successMessage = '';
      },
      saveAssetUpdateFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },      
      getPurchasePriceRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getPurchasePriceSuccess(state, action: PayloadAction<number>) {
        state.loading = false;
        state.purchasePrice = action.payload;
      },
      getPurchasePriceFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getActiveTokenPriceRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getActiveTokenPriceSuccess(state, action: PayloadAction<ITokenPriceDetails>) {
        state.loading = false;
        state.activeTokenPrice = action.payload;
      },
      getActiveTokenPriceFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getInitialTokenPriceRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getInitialTokenPriceSuccess(state, action: PayloadAction<ITokenPriceDetails>) {
        console.log('getInitialTokenPriceSuccess:', action.payload);
        state.loading = false;
        state.initialTokenPrice = action.payload;
      },
      getInitialTokenPriceFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getTokenPriceHistoryRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getTokenPriceHistorySuccess(state, action: PayloadAction<any[]>) { // Replace `any[]` with the actual type
        state.loading = false;
        state.tokenPriceHistory = action.payload;
      },
      getTokenPriceHistoryFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },
      
      setTokenPriceRequest(state, action: PayloadAction<{ assetID: string; 
        assetTokenDetails: AssetTokenization, purchasePrice: number, initialValuationId: string }>) {
        state.loading = true;
        state.error = null;
      },
      setTokenPriceSuccess(state) {
        state.loading = false;
      },
      setTokenPriceFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      updateTokenPriceRequest(state, action: PayloadAction<{ assetID: string; assetValuationId: string }>) {
        state.loading = true;
        state.error = null;
      },
      updateTokenPriceSuccess(state) {
        state.loading = false;
      },
      updateTokenPriceFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      getTokenAvailabilityRequest(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
      },
      getTokenAvailabilitySuccess(state, action: PayloadAction<ITokenAvailabilityDetails>) {
        state.loading = false;
        state.tokenAvailability = action.payload;
      },
      getTokenAvailabilityFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
      },

      submitPurchaseRequest(state, action: PayloadAction<{
        assetID: string;
        purchaserID: string;
        numberOfTokens: number;
        purchasePricePerToken: number;
      }>) {
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      submitPurchaseRequestSuccess(state, action: PayloadAction<any>) {
        state.loading = false;
        state.success = true;
        state.tokenPurchaseData = action.payload;
        state.successMessage = 'Purchase request submitted successfully';
      },
      submitPurchaseRequestFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      },      

      fetchPurchaseHistoryByAssetID(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      fetchPurchaseHistoryByAssetIDSuccess(state, action: PayloadAction<PurchaseHistory[]>) {
        state.loading = false;
        state.success = true;
        state.purchaseHistory = action.payload ?? [];
      },
      fetchPurchaseHistoryByAssetIDFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      },

      fetchPurchaseHistoryByAssetIDAndUserID(state, action: PayloadAction<{ assetID: string; userID: string }>) {
        console.log('slice fetchPurchaseHistoryByAssetIDAndUserID:', action.payload);
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      fetchPurchaseHistoryByAssetIDAndUserIDSuccess(state, action: PayloadAction<PurchaseHistory[]>) {
        console.log('slice fetchPurchaseHistoryByAssetIDAndUserIDSuccess:', action.payload);
        state.loading = false;
        state.success = true;
        state.purchaseHistory = action.payload ?? [];
      },
      fetchPurchaseHistoryByAssetIDAndUserIDFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      },

      fetchTotalTokenPurchaseByAssetID(state, action: PayloadAction<string>) {
        state.loading = true;
        state.error = null;
        state.success = false;
      },
      fetchTotalTokenPurchaseByAssetIDSuccess(state, action: PayloadAction<number>) {
        state.loading = false;
        state.success = true;
        state.totalTokenPurchased = action.payload;
      },
      fetchTotalTokenPurchaseByAssetIDFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      },



  },

});

export const {
  saveLocationDetailsRequest, saveLocationDetailsSuccess, saveLocationDetailsFailure,
  getLocationDetailsRequest, getLocationDetailsSuccess, getLocationDetailsFailure,
  saveBasicDetailsRequest, saveBasicDetailsSuccess, saveBasicDetailsFailure,
  getBasicDetailsRequest, getBasicDetailsSuccess, getBasicDetailsFailure,
  saveAssetDescriptionRequest, saveAssetDescriptionSuccess, saveAssetDescriptionFailure,
  saveInternalStructureRequest, saveInternalStructureSuccess, saveInternalStructureFailure,
  getInternalStructureRequest, getInternalStructureSuccess, getInternalStructureFailure,
  saveExternalStructureRequest, saveExternalStructureSuccess, saveExternalStructureFailure,
  getExternalStructureRequest, getExternalStructureSuccess, getExternalStructureFailure,
  saveRentDetailsRequest, saveRentDetailsSuccess, saveRentDetailsFailure,
  getRentDetailsRequest, getRentDetailsSuccess, getRentDetailsFailure,  
  getTokenOfferingDetailsRequest, getTokenOfferingDetailsSuccess, getTokenOfferingDetailsFailure,
  saveTokenOfferingDetailsRequest, saveTokenOfferingDetailsSuccess, saveTokenOfferingDetailsFailure,
  getPurchaseDetailsRequest, getPurchaseDetailsSuccess, getPurchaseDetailsFailure,
  savePurchaseDetailsRequest, savePurchaseDetailsSuccess, savePurchaseDetailsFailure,
  getTokenDetailsRequest, getTokenDetailsSuccess, getTokenDetailsFailure,
  saveTokenDetailsRequest, saveTokenDetailsSuccess, saveTokenDetailsFailure,
  getValuationDetailsRequest, getValuationDetailsSuccess, getValuationDetailsFailure,
  saveValuationDetailsRequest, saveValuationDetailsSuccess, saveValuationDetailsFailure,
  publishAssetRequest, publishAssetSuccess, publishAssetFailure,
  unpublishAssetRequest, unpublishAssetSuccess, unpublishAssetFailure,
  uploadImageRequest, uploadImageSuccess, uploadImageFailure,
  uploadImageStart, uploadImageSuccess2, uploadImageFailure2,
  uploadValuationDocumentRequest, uploadValuationDocumentSuccess, uploadValuationDocumentFailure,
  saveFinancialDetailsRequest, saveFinancialDetailsSuccess, saveFinancialDetailsFailure,
  getFinancialDetailsRequest, getFinancialDetailsSuccess, getFinancialDetailsFailure,
  saveFeaturesRequest, saveFeaturesSuccess, saveFeaturesFailure,
  getFeaturesRequest, getFeaturesSuccess, getFeaturesFailure,
  updateMonthlyRentDetailsRequest, updateMonthlyRentDetailsSuccess, updateMonthlyRentDetailsFailure,
  getAllMonthlyRentDetailsRequest, getAllMonthlyRentDetailsSuccess, getAllMonthlyRentDetailsFailure,
  getMonthlyRentDetailsByYearMonthRequest, getMonthlyRentDetailsByYearMonthSuccess, getMonthlyRentDetailsByYearMonthFailure,
  getAssetUpdatesRequest, getAssetUpdatesSuccess, getAssetUpdatesFailure,
  saveAssetUpdateRequest, saveAssetUpdateSuccess, saveAssetUpdateFailure,
  getPurchasePriceRequest, getPurchasePriceSuccess, getPurchasePriceFailure,
  getActiveTokenPriceRequest, getActiveTokenPriceSuccess, getActiveTokenPriceFailure,
  getInitialTokenPriceRequest, getInitialTokenPriceSuccess, getInitialTokenPriceFailure,
  setTokenPriceRequest, setTokenPriceSuccess, setTokenPriceFailure,
  updateTokenPriceRequest, updateTokenPriceSuccess, updateTokenPriceFailure,
  getTokenPriceHistoryRequest, getTokenPriceHistorySuccess, getTokenPriceHistoryFailure,
  getTokenAvailabilityRequest, getTokenAvailabilitySuccess, getTokenAvailabilityFailure,
  submitPurchaseRequest, submitPurchaseRequestSuccess, submitPurchaseRequestFailure,
  fetchPurchaseHistoryByAssetID, fetchPurchaseHistoryByAssetIDSuccess, fetchPurchaseHistoryByAssetIDFailure, 
  fetchPurchaseHistoryByAssetIDAndUserID, fetchPurchaseHistoryByAssetIDAndUserIDSuccess, fetchPurchaseHistoryByAssetIDAndUserIDFailure,
  fetchTotalTokenPurchaseByAssetID, fetchTotalTokenPurchaseByAssetIDSuccess, fetchTotalTokenPurchaseByAssetIDFailure,
} = assetSlice.actions;

export default assetSlice.reducer;

import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchAllWaitingListApi, approveWaitingListApi } from './waitingListApi';
import {
  fetchWaitingListRequest,
  fetchWaitingListSuccess,
  fetchWaitingListFailure,
  approveWaitingListRequest,
  approveWaitingListSuccess,
  approveWaitingListFailure,
} from './waitingListSlice';

// Worker saga: Fetch all waiting list entries
function* fetchWaitingListSaga(): any {
  try {
    const response = yield call(fetchAllWaitingListApi);
    yield put(fetchWaitingListSuccess(response));
  } catch (error: any) {
    yield put(fetchWaitingListFailure(error.message));
  }
}

// Worker saga: Approve waiting list entry
function* approveWaitingListSaga(action: any): any {
    try {
      const { emailId } = action.payload;
      const response = yield call(approveWaitingListApi, emailId);
      yield put(approveWaitingListSuccess(response));
    } catch (error: any) {
      yield put(approveWaitingListFailure(error.message));
    }
  }

// Watcher saga: Watches for fetchWaitingListRequest action
export function* watchRegistrationWaitingListSagas() {
  yield takeLatest(fetchWaitingListRequest.type, fetchWaitingListSaga);
  yield takeLatest(approveWaitingListRequest.type, approveWaitingListSaga);

}

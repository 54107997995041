// src/components/AssetDetailEditor/TokenOffering.tsx
import React, { useState } from 'react';
import styles from './TokenOffering.module.css';

const offeringData = [
  {
    issuer: 'Company A',
    offeringDate: '2024 Feb 15',
    maxInvestment: '$1,000,000.00',
    minInvestment: '$10,000.00',
    publicOfferingPercentage: '60%',
    companyHoldingPercentage: '40%',
    expectedAmountRaised: '$600,000.00',
    actualAmountRaised: '$550,000.00',
  },
  {
    issuer: 'Company B',
    offeringDate: '2023 Jun 10',
    maxInvestment: '$2,000,000.00',
    minInvestment: '$20,000.00',
    publicOfferingPercentage: '70%',
    companyHoldingPercentage: '30%',
    expectedAmountRaised: '$1,400,000.00',
    actualAmountRaised: '$1,300,000.00',
  },
];

const TokenOffering: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.offeringContainer}>
      <h2 className={styles.offeringHeading}>Token Offering</h2>
      <div className={styles.accordion}>
        {offeringData.map((offering, index) => (
          <div key={index} className={styles.accordionItem}>
            <div
              className={styles.accordionTitle}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{offering.offeringDate}</h3>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className={styles.accordionContent}>
                <div className={styles.offeringDetails}>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>ISSUER</div>
                    <div>{offering.issuer}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>OFFERING DATE</div>
                    <div>{offering.offeringDate}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>MAXIMUM INVESTMENT</div>
                    <div>{offering.maxInvestment}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>MINIMUM INVESTMENT</div>
                    <div>{offering.minInvestment}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>PUBLIC OFFERING %</div>
                    <div>{offering.publicOfferingPercentage}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>COMPANY HOLDING %</div>
                    <div>{offering.companyHoldingPercentage}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>EXPECTED AMOUNT RAISED</div>
                    <div>{offering.expectedAmountRaised}</div>
                  </div>
                  <div className={styles.offeringItem}>
                    <div className={styles.offeringTitle}>ACTUAL AMOUNT RAISED</div>
                    <div>{offering.actualAmountRaised}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenOffering;

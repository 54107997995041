


const HowToManageAsset = () => {
    return (
        <div>
            <h4>How to Manage Asset</h4>
            <p>
                This page is used to manage the asset. You can manage the asset by selecting the menu on the left side.
            </p>
        </div>
    );
};

export default HowToManageAsset;

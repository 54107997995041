import React, { useEffect, useState } from 'react';
import styles from './AssetUpdates.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { getAssetUpdatesRequest, saveAssetUpdateRequest } from '../../../../features/assetEditor/assetEditorSlice';

interface Update {
  _id: string;
  assetID: string;
  content: string;
  createdBy: string;
  createdOn: string;
}

interface AssetUpdatesProps {
  selectedAsset: { assetID: string; name: string; _id: string }; // Assuming Asset is the type of your selected asset
}

const AssetUpdates: React.FC<AssetUpdatesProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const [newUpdateContent, setNewUpdateContent] = useState('');

  const { success, error, loading, updates } = useSelector((state: RootState) => state.assetEditor);

  useEffect(() => {
    dispatch(getAssetUpdatesRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUpdateContent(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updateObj: Update = {
      _id: '', // this will be generated by the backend
      assetID: selectedAsset._id,
      content: newUpdateContent,
      createdBy: 'Admin',
      createdOn: new Date().toISOString(), // assuming the current time
    };
    dispatch(saveAssetUpdateRequest({ assetUpdate: updateObj, assetID: selectedAsset._id }));
    setNewUpdateContent(''); // Clear the input after submission
  };

  // Helper function to calculate days ago
  const daysAgo = (dateString: string) => {
    const updateDate = new Date(dateString);
    const today = new Date();
    const difference = Math.floor((today.getTime() - updateDate.getTime()) / (1000 * 3600 * 24));
    return difference === 0 ? 'Today' : `${difference} days ago`;
  };

  return (
    <div className={styles.assetUpdates}>
      <h2>Asset Updates</h2>
      <form onSubmit={handleSubmit} className={styles.updateForm}>
        <input
          type="text"
          value={newUpdateContent}
          onChange={handleInputChange}
          placeholder="Enter update details"
          className={styles.inputBox}
        />
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
      <div className={styles.updateList}>
        {updates.length > 0 ? (
          <div className={styles.updateContainer}>
            {updates.map(update => (
              <div key={update.assetID} className={styles.updateItem}>

                <div className={styles.updateDetails}>
                  <span className={styles.updateTitle}>{update.createdBy}</span>
                  <span className={styles.updateDate}>{daysAgo(update.createdOn)}</span>
                </div>
                <div className={styles.updateContent}>{update.content}</div>
              </div>
            ))}
          </div>
        ) : (
          <p>No updates available</p>
        )}
      </div>
    </div>
  );
};

export default AssetUpdates;

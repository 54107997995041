// src/components/AssetDetailEditor/ExternalStructure.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveExternalStructureRequest, getExternalStructureRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';

import styles from './ExternalStructure.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface ExternalStructureProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const ExternalStructure: React.FC<ExternalStructureProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  console.log('ExternalStructure: Selected Asset:', selectedAsset);
  useSelector((state: RootState) => {
    console.log('ExternalStructure: Token Details:', state.assetEditor);
    return state.assetEditor.externalStructureDetails;
  });

  const {
    propertyType,
    lotSizeInSqft,
    foundation,
    exteriorWalls,
    roofType,
    parking,
    buildingClass,
    constructionYear,
    stories,
  } = useSelector((state: RootState) => state.assetEditor.externalStructureDetails);
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const [type, setType] = useState('');
  const [lotSize, setLotSize] = useState<number>(0);
  const [foundationType, setFoundationType] = useState('');
  const [walls, setWalls] = useState('');
  const [roof, setRoof] = useState('');
  const [parkingType, setParkingType] = useState('');
  const [buildingClassType, setBuildingClassType] = useState('');
  const [year, setYear] = useState<number>(0);
  const [numberOfStories, setNumberOfStories] = useState<number>(0);

  useEffect(() => {
    dispatch(getExternalStructureRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    setType(propertyType);
    setLotSize(lotSizeInSqft);
    setFoundationType(foundation);
    setWalls(exteriorWalls);
    setRoof(roofType);
    setParkingType(parking);
    setBuildingClassType(buildingClass);
    setYear(constructionYear);
    setNumberOfStories(stories);
  }, [propertyType, lotSizeInSqft, foundation, exteriorWalls, roofType, parking, buildingClass, constructionYear, stories]);

  const handleSave = () => {

    const externalStructureDetails = { 
      propertyType: type,
      lotSizeInSqft: lotSize,
      foundation: foundationType,
      exteriorWalls: walls,
      roofType: roof,
      parking: parkingType,
      buildingClass: buildingClassType,
      constructionYear: year,
      stories: numberOfStories,  
    };

    const _id = selectedAsset._id;
    const assetID = selectedAsset.assetID;
    console.log('Saving external structure details:', externalStructureDetails);
    dispatch(saveExternalStructureRequest({ externalStructureDetails, assetID, _id }));
  };

  return (
    <div className={styles.externalStructureContainer}>
      <h2 className={styles.heading}>External Structure</h2>
      <NotificationBanner success={success} error={error} loading={loading} />
      
      <div className={styles.formGroup}>
        <label>Property Type:</label>
        <input type="text" value={type} onChange={(e) => setType(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Lot Size (sq ft):</label>
        <input type="number" value={lotSize} onChange={(e) => setLotSize(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Foundation:</label>
        <input type="text" value={foundationType} onChange={(e) => setFoundationType(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Exterior Walls:</label>
        <input type="text" value={walls} onChange={(e) => setWalls(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Roof Type:</label>
        <input type="text" value={roof} onChange={(e) => setRoof(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Parking:</label>
        <input type="text" value={parkingType} onChange={(e) => setParkingType(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Building Class:</label>
        <input type="text" value={buildingClassType} onChange={(e) => setBuildingClassType(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Construction Year:</label>
        <input type="number" value={year} onChange={(e) => setYear(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Stories:</label>
        <input type="number" value={numberOfStories} onChange={(e) => setNumberOfStories(Number(e.target.value))} />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default ExternalStructure;

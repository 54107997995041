// src/components/NotificationBanner/NotificationBanner.tsx
import React, { useEffect, useState } from 'react';
import { RootState } from '../../../../store/rootReducer';
import { useSelector } from 'react-redux';

import styles from './NotificationBanner.module.css';

interface NotificationBannerProps {
  success: boolean | null;
  error: string | null;
  loading: boolean;
}

const NotificationBanner: React.FC<NotificationBannerProps> = () => {
  const { success, error, loading, successMessage } = useSelector((state: RootState) => state.assetEditor);
  const [showSuccess, setShowSuccess] = useState(success);

  const showSuccessMessage = successMessage ? successMessage : 'Details saved successfully!';

  useEffect(() => {
    if (success) {
      setShowSuccess(true);
      const timer = setTimeout(() => setShowSuccess(false), 5000); // Hide success banner after 5 seconds
      return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }
  }, [success]);

  return (
    <div>
      {showSuccess && <div className={styles.successBanner}>{ showSuccessMessage }</div>}
      {error && <div className={styles.errorBanner}>Error saving details: {error}</div>}
      {loading && <div className={styles.loadingBanner}>Loading details...</div>}
    </div>
  );
};

export default NotificationBanner;

// src/components/AssetDetailEditor/PublishStatus.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { publishAssetRequest, unpublishAssetRequest } from '../../../../features/assetEditor/assetEditorSlice';

import styles from './PublishDetails.module.css';

interface PublishStatusProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const PublishDetails: React.FC<PublishStatusProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const { publishStatus, error, loading } = useSelector((state: RootState) => state.assetEditor);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    // Fetch the current publish status of the asset
    // Assume you have an API endpoint to get the publish status
    // For simplicity, we'll assume the status is fetched and set here
    setIsPublished(false); // Change this based on actual status fetched
  }, [selectedAsset._id]);

  const handlePublish = () => {
    dispatch(publishAssetRequest(selectedAsset._id as string));
  };

  const handleUnpublish = () => {
    dispatch(unpublishAssetRequest({assetID: selectedAsset._id}));
  };

  return (
    <div className={styles.publishStatusContainer}>
      <h2 className={styles.heading}>Publish Status</h2>
      
      {publishStatus && <div className={styles.successBanner}>Asset published successfully!</div>}
      {error && <div className={styles.errorBanner}>Error: {error}</div>}
      {loading && <div className={styles.loadingBanner}>Loading...</div>}

      {isPublished ? (
        <>
          <div className={styles.confirmationMessage}>Do you really want to unpublish the asset?</div>
          <button onClick={handleUnpublish}>UnPublish</button>
        </>
      ) : (
        <>
          <div className={styles.confirmationMessage}>Do you really want to publish the asset?</div>
          <button onClick={handlePublish}>Publish</button>
        </>
      )}
    </div>
  );
};

export default PublishDetails;

// src/components/AdminDashboard/AdminDashboard.tsx
import React from 'react';

const AdminDashboard: React.FC = () => {
  return (
    <div>
      <h2>AdminDashboard Details</h2>
      <p>Content for AdminDashboard Details goes here.</p>
    </div>
  );
};

export default AdminDashboard;

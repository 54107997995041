// src/components/MonthlyRentDetails/MonthlyRentDetails.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMonthlyRentDetailsRequest, updateMonthlyRentDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import styles from './MonthlyRentDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { RootState } from '../../../../store/rootReducer';

interface MonthlyRentDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string }; // Assuming Asset is the type of your selected asset
}

const MonthlyRentDetails: React.FC<MonthlyRentDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const [selectedDetails, setSelectedDetails] = useState<any>(null);
  const [isFormVisible, setFormVisible] = useState(false);

  const { success, error, loading, monthlyRentDetails, monthlyRentDetailsList } = useSelector((state: RootState) => state.assetEditor);

  console.log('monthlyRentDetails', monthlyRentDetails);
  console.log('monthlyRentDetailsList', monthlyRentDetailsList);

  const [year, setYear] = useState<number>(2024);
  const [month, setMonth] = useState<string>('January');
  const [rentalStatus, setRentalStatus] = useState<string>('Occupied');
  const [rentAmount, setRentAmount] = useState<number>(0);
  const [lateFees, setLateFees] = useState<number>(0);
  const [propertyManagementFees, setPropertyManagementFees] = useState<number>(0);
  const [assetSlicesFees, setAssetSlicesFees] = useState<number>(0);
  const [maintenanceExpense, setMaintenanceExpense] = useState<number>(0);
  const [propertyTax, setPropertyTax] = useState<number>(0);
  const [ownerInsurance, setOwnerInsurance] = useState<number>(0);
  const [renterInsurance, setRenterInsurance] = useState<number>(0);
  const [utilitiesElectric, setUtilitiesElectric] = useState<number>(0);
  const [utilitiesWater, setUtilitiesWater] = useState<number>(0);
  const [utilitiesWifi, setUtilitiesWifi] = useState<number>(0);
  const [utilitiesCable, setUtilitiesCable] = useState<number>(0);
  const [utilitiesPhone, setUtilitiesPhone] = useState<number>(0);
  const [utilitiesLandscaping, setUtilitiesLandscaping] = useState<number>(0);
  const [legalFees, setLegalFees] = useState<number>(0);
  const [professionalFees, setProfessionalFees] = useState<number>(0);

  const handleUpdate = () => {
    const monthlyRentDetails = {
      year,
      month,
      rentalStatus,
      rentAmount,
      lateFees,
      propertyManagementFees,
      assetSlicesFees,
      maintenanceExpense,
      propertyTax,
      ownerInsurance,
      renterInsurance,
      utilitiesElectric,
      utilitiesWater,
      utilitiesWifi,
      utilitiesCable,
      utilitiesPhone,
      utilitiesLandscaping,
      legalFees,
      professionalFees,
    };
    const assetID = selectedAsset.assetID;
    const _id = selectedAsset._id;
    const updateObj = {monthlyRentDetails: monthlyRentDetails, assetID: assetID, _id: _id};
    dispatch(updateMonthlyRentDetailsRequest(updateObj));
  };

  useEffect(() => {
    console.log(
      'selectedAsset',
      selectedAsset,
    );
    dispatch(getAllMonthlyRentDetailsRequest(selectedAsset._id));
  }, [dispatch]);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  return (
    <div className={styles.monthlyRentDetailsContainer}>
      <h2 className={styles.heading}>Monthly Rent Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Year</th>
              <th>Month</th>
              <th>Rental Status</th>
              <th>Rent Amount</th>
              <th>Late Fees</th>
              {/* <th>Property Management Fees</th>
              <th>AssetSlices Fees</th>
              <th>Maintenance Expense</th>
              <th>Property Tax</th>
              <th>Owner Insurance</th>
              <th>Renter Insurance</th>
              <th>Utilities (Electric)</th>
              <th>Utilities (Water)</th>
              <th>Utilities (Wifi)</th>
              <th>Utilities (Cable)</th>
              <th>Utilities (Phone)</th>
              <th>Utilities (Landscaping)</th>
              <th>Legal Fees</th>
              <th>Professional Fees</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {monthlyRentDetailsList.map((detail: any) => (
              <tr key={detail._id}>
                <td>{detail.year}</td>
                <td>{detail.month}</td>
                <td>{detail.rentalStatus}</td>
                <td>{detail.rentAmount}</td>
                <td>{detail.lateFees}</td>
                {/* <td>{detail.propertyManagementFees}</td>
                <td>{detail.assetSlicesFees}</td>
                <td>{detail.maintenanceExpense}</td>
                <td>{detail.propertyTax}</td>
                <td>{detail.ownerInsurance}</td>
                <td>{detail.renterInsurance}</td>
                <td>{detail.utilitiesElectric}</td>
                <td>{detail.utilitiesWater}</td>
                <td>{detail.utilitiesWifi}</td>
                <td>{detail.utilitiesCable}</td>
                <td>{detail.utilitiesPhone}</td>
                <td>{detail.utilitiesLandscaping}</td>
                <td>{detail.legalFees}</td>
                <td>{detail.professionalFees}</td> */}
                <td>
                  <button onClick={() => setSelectedDetails(detail)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.toggleButtonContainer}>
        <button onClick={toggleFormVisibility} className={styles.toggleButton}>
          {isFormVisible ? 'Hide' : 'Add/Edit Monthly Rent Details'}
        </button>
      </div>

      {isFormVisible && (
      <div className={styles.formContainer}>
        <h3>Add/Edit Monthly Rent Details</h3>
      <div className={styles.formGroup}>
        <label>Year:</label>
        <input type="number" value={year} onChange={(e) => setYear(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Month:</label>
        <input type="text" value={month} onChange={(e) => setMonth(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Rental Status:</label>
        <input type="text" value={rentalStatus} onChange={(e) => setRentalStatus(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Rent Amount:</label>
        <input type="number" value={rentAmount} onChange={(e) => setRentAmount(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Late Fees:</label>
        <input type="number" value={lateFees} onChange={(e) => setLateFees(Number(e.target.value))} />
      </div>

      {/* <div className={styles.formGroup}>
        <label>Property Management Fees:</label>
        <input type="number" value={propertyManagementFees} onChange={(e) => setPropertyManagementFees(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>AssetSlices Fees:</label>
        <input type="number" value={assetSlicesFees} onChange={(e) => setAssetSlicesFees(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Maintenance Expense:</label>
        <input type="number" value={maintenanceExpense} onChange={(e) => setMaintenanceExpense(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Property Tax:</label>
        <input type="number" value={propertyTax} onChange={(e) => setPropertyTax(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Owner Insurance:</label>
        <input type="number" value={ownerInsurance} onChange={(e) => setOwnerInsurance(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Renter Insurance:</label>
        <input type="number" value={renterInsurance} onChange={(e) => setRenterInsurance(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities (Electric):</label>
        <input type="number" value={utilitiesElectric} onChange={(e) => setUtilitiesElectric(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities (Water):</label>
        <input type="number" value={utilitiesWater} onChange={(e) => setUtilitiesWater(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities (Wifi):</label>
        <input type="number" value={utilitiesWifi} onChange={(e) => setUtilitiesWifi(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities (Cable):</label>
        <input type="number" value={utilitiesCable} onChange={(e) => setUtilitiesCable(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities (Phone):</label>
        <input type="number" value={utilitiesPhone} onChange={(e) => setUtilitiesPhone(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities (Landscaping):</label>
        <input type="number" value={utilitiesLandscaping} onChange={(e) => setUtilitiesLandscaping(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Legal Fees:</label>
        <input type="number" value={legalFees} onChange={(e) => setLegalFees(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Professional Fees:</label>
        <input type="number" value={professionalFees} onChange={(e) => setProfessionalFees(Number(e.target.value))} />
      </div> */}

      <div className={styles.buttonContainer}>
        <button onClick={handleUpdate}>Update Monthly Rental Details</button>
      </div>
      </div>
      )}
    </div>
  );
};

export default MonthlyRentDetails;

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl;

export const createAssetApi = async () => {
  const response = await axios.post(`${API_BASE_URL}/asset/create`);
  return response.data;
};

export const getUnPublishedAssetsApi = async () => {
  const response = await axios.get(`${API_BASE_URL}/asset/un-published`);
  return response;
};
 
export const getTotalPropertiesCount = async (assetId:any) => {
  const response = await axios.get(`${API_BASE_URL}/asset/total-property-count`);
  return response?.data;
}

export const getPublishedAssetsApi = async () => {
  const response = await axios.get(`${API_BASE_URL}/asset/all-published`);
  return response;
};
  
export const getPorfolioDetails = async(userId:any)=>{
  const response = await axios.get(`${API_BASE_URL}/asset/portfolioDetails/${userId}`);
  return response
}
  
export const getAllPropertyDetails = async()=>{
  return await axios.get(`${API_BASE_URL}/asset/all-property-values`)
}

// store/rootReducer.ts

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import assetReducer from '../features/asset/assetSlice';
import assetEditorReducer from '../features/assetEditor/assetEditorSlice';
import distributionSlice from '../features/distribution/distributionSlice';
import valuationSlice from '../features/assetValuation/assetValuationSlice';
import UserDistributionReducer from '../features/userDistribution/userDistributionSlice';
import BackendPartnersReducer from '../features/backendPartners/backendPartnerSlice';
import assetMonthlyFinancialsReducer from '../features/assetMonthlyFinancials/assetMonthlyFinancialsSlice';
import assetTokenizationReducer from '../features/assetTokenization/assetTokenizationSlice';
import assetSettingsReducer from '../features/assetSettings/assetSettingsSlice';
import adminUsersReducer from '../features/adminUsers/adminUsersSlice';
import assetStagesReducer from '../features/assetStages/assetStagesSlice';
import registrationWaitingListReducer from '../features/registrationWaitingList/waitingListSlice';

// Import other reducers as needed

const rootReducer = combineReducers({
  auth: authReducer,
  asset: assetReducer,
  assetEditor: assetEditorReducer,
  distribution: distributionSlice,
  valuation: valuationSlice,
  userDistribution: UserDistributionReducer,
  BackendPartners: BackendPartnersReducer,
  assetMonthlyFinancials: assetMonthlyFinancialsReducer,
  assetTokenization: assetTokenizationReducer,
  assetSettings: assetSettingsReducer,
  adminUsers: adminUsersReducer,
  assetStages: assetStagesReducer,
  registrationWaitingList: registrationWaitingListReducer,

  // Add other reducers here
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

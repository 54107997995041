// features/auth/authSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { log } from 'console';

interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
  error: string | null;
  sessionValid: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  user: any | null;
  successMessage: string | null;
  registrationSuccess: boolean;
  registrationError: string | null;
  registrationLoading: boolean;
  emailVerificationLoading: boolean;
  emailVerificationSuccess: boolean;
  emailVerificationError: string | null;
  passwordResetSuccess: boolean;
  passwordResetError: string | null;
  resetPasswordSuccess: boolean;
  resetPasswordError: string | null;
  emailVerificationSent: boolean;
  emailVerificationMessage: string | null;
  isEmailVerified: boolean;
}

const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  error: null,
  sessionValid: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  successMessage: null,
  registrationSuccess: false,
  registrationError: null,
  registrationLoading: false,
  emailVerificationLoading: false,
  emailVerificationSuccess: false,
  emailVerificationError: null, 
  passwordResetSuccess: false,
  passwordResetError: null, 
  resetPasswordSuccess: false,
  resetPasswordError: null,  
  emailVerificationSent: false,
  emailVerificationMessage: null,
  isEmailVerified: true
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest(state, action: PayloadAction<{ username: string, password: string }>) {
      console.log('loginRequest');
        state.isLoggedIn = false;
        state.error = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      console.log('loginSuccess ', action.payload);
      // SessionStorage
      sessionStorage.setItem('accessToken', action.payload.accessToken);
      sessionStorage.setItem('userID', action.payload.id);
      sessionStorage.setItem('userRole', action.payload.userRole);
      // LocalStorage 
      // TODO: change to HTTP-only cookie
      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
      localStorage.setItem('userID', action.payload.id);
      localStorage.setItem('userRole', action.payload.userRole);
      state.isLoggedIn = true;
      state.user = action.payload;
      state.error = null;
      state.sessionValid = true;
    },
    loginFailure(state, action: PayloadAction<string>) {
      console.log('loginFailure ', action.payload);
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    registerRequest(state, action: PayloadAction<{ email: string; password: string }>) {
      state.registrationLoading = true;
      state.registrationSuccess = false;
      state.registrationError = null;
      state.loading = true;
      state.error = null;
    },
    registerSuccess(state) {
      state.registrationLoading = false;
      state.registrationSuccess = true;
      state.registrationError = null;
      state.isLoggedIn = false;
      state.loading = false;
    },
    registerFailure(state, action: PayloadAction<string>) {
      state.registrationLoading = false;
      state.registrationSuccess = false;
      state.registrationError = action.payload;
      state.loading = false;
      state.error = action.payload;
    },
    logoutRequest(state) {
      state.isLoggedIn = false;
      state.error = null;
    },
    logoutSuccess(state) {
      state.isLoggedIn = false;
      // SessionStorage
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('userID');
      sessionStorage.removeItem('userRole');
      // LocalStorage
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userID');
      localStorage.removeItem('userRole');
      window.location.href = '/login';
      state.error = null;
      state.sessionValid = false;
    },
    logoutFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    refreshTokenRefreshRequest(state) {
      state.error = null;
    },
    refreshTokenRefreshSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
    },  
    refreshTokenRefreshFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    validateTokenRequest(state) {
      state.error = null;
    },
    validateTokenSuccess(state) {
      state.error = null;
      state.sessionValid = true;
    },
    validateTokenFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.sessionValid = false;
    },
    setTokens: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    clearTokens: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },    
    changePasswordRequest(state, action: PayloadAction<{ userID: string, currentPassword: string; newPassword: string }>) {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    changePasswordSuccess(state, action: PayloadAction<string>) {
      state.loading = false;
      state.successMessage = action.payload;
    },
    changePasswordFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    // Email Verification Reducers
    verifyEmailRequest(state, action: PayloadAction<string>) {
      state.emailVerificationLoading = true;
      state.emailVerificationSuccess = false;
      state.emailVerificationError = null;
    },
    verifyEmailSuccess(state) {
      state.emailVerificationLoading = false;
      state.emailVerificationSuccess = true;
      state.emailVerificationError = null;
    },
    verifyEmailFailure(state, action: PayloadAction<string>) {
      state.emailVerificationLoading = false;
      state.emailVerificationSuccess = false;
      state.emailVerificationError = action.payload;
    },
    requestPasswordResetRequest(state, action: PayloadAction<string>) {
      state.loading = true;
      state.passwordResetSuccess = false;
      state.passwordResetError = null;
    },
    requestPasswordResetSuccess(state) {
      state.loading = false;
      state.passwordResetSuccess = true;
      state.passwordResetError = null;
    },
    requestPasswordResetFailure(state, action) {
      state.loading = false;
      state.passwordResetSuccess = false;
      state.passwordResetError = action.payload;
    },
    resetPasswordRequest(state, action: PayloadAction<{ token: string; newPassword: string }>) {
      state.loading = true;
      state.resetPasswordSuccess = false;
      state.resetPasswordError = null;
    },
    resetPasswordSuccess(state) {
      state.loading = false;
      state.resetPasswordSuccess = true;
      state.resetPasswordError = null;
    },
    resetPasswordFailure(state, action) {
      state.loading = false;
      state.resetPasswordSuccess = false;
      state.resetPasswordError = action.payload;
    },
    resendEmailVerificationRequest: (state, action: PayloadAction<{ userID: string }>) => {
      state.loading = true;
      state.error = null;
    },
    resendEmailVerificationSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.emailVerificationSent = true;
    },
    resendEmailVerificationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetEmailVerificationSent: (state) => {
      state.emailVerificationSent = false;
    },        
    checkEmailVerifiedRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    checkEmailVerifiedSuccess: (state, action: PayloadAction<any>) => {
      state.isEmailVerified = action.payload.isEmailVerified;
      state.loading = false;
    },
    checkEmailVerifiedFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

  },
});

export const { loginRequest, loginSuccess, loginFailure, registerRequest, registerSuccess, registerFailure,
  logoutRequest, logoutSuccess, logoutFailure, refreshTokenRefreshRequest, refreshTokenRefreshSuccess, 
  refreshTokenRefreshFailure, validateTokenRequest, validateTokenSuccess, validateTokenFailure,
  setTokens, clearTokens, setUser, clearUser, changePasswordRequest, changePasswordSuccess, changePasswordFailure,
  verifyEmailRequest, verifyEmailSuccess, verifyEmailFailure, requestPasswordResetRequest, requestPasswordResetSuccess,
  requestPasswordResetFailure, resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure, resendEmailVerificationRequest,
  resendEmailVerificationSuccess, resendEmailVerificationFailure, resetEmailVerificationSent,
  checkEmailVerifiedRequest, checkEmailVerifiedSuccess, checkEmailVerifiedFailure,
 } = authSlice.actions;

export default authSlice.reducer;

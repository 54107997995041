// src/components/AssetSettings/AssetSettings.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AssetSettings.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner'; // Assuming a similar notification banner is used
import { fetchAssetSettingsRequest, addOrUpdateAssetSettingsRequest } from '../../../../features/assetSettings/assetSettingsSlice';
import { RootState } from '../../../../store/rootReducer';

interface AssetSettingsProps {
    selectedAsset: { assetID: string; name: string; _id: string };
}

const AssetSettings: React.FC<AssetSettingsProps> = ({ selectedAsset }) => {
    const dispatch = useDispatch();

  const [platformFee, setPlatformFee] = useState<number>(2); // Default 2%
  const [dividendStartDate, setDividendStartDate] = useState<string>('');
  const [sellingCoolOffPeriod, setSellingCoolOffPeriod] = useState<number>(0);
  const [estimatedROI, setEstimatedROI] = useState<number>(0);
  const [saleStartDate, setSaleStartDate] = useState<string>('');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

    useEffect(() => {
      dispatch(fetchAssetSettingsRequest(selectedAsset._id));
    }, [dispatch, selectedAsset]);

    const { loading, settings } = useSelector((state: RootState) => state.assetSettings);

    console.log('AssetSettings: Selected Asset:', settings);

    useEffect(() => {
      if (settings) {
        setPlatformFee(settings.platformFee);
        setDividendStartDate(settings.dividendStartDate);
        setSellingCoolOffPeriod(settings.sellingCoolOffPeriod);
        setSaleStartDate(settings.saleStartDate);
        setEstimatedROI(settings.estimatedROI);
      }
    }, [settings]);
  const handleSave = () => {
    console.log('Saving Asset Settings:', {
      platformFee,
      dividendStartDate,
      sellingCoolOffPeriod,
      saleStartDate,
      estimatedROI
    });
    const assetSettings = {
      assetID: selectedAsset._id,
      settings: {
        platformFee,
        dividendStartDate,
        sellingCoolOffPeriod,
        saleStartDate,
        estimatedROI
      }
    };
    dispatch(addOrUpdateAssetSettingsRequest(assetSettings));
    setSuccess(true);
    setError('');
  };

  return (
    <div className={styles.assetSettingsContainer}>
      <h2 className={styles.heading}>Asset Settings</h2>
      <NotificationBanner success={success} error={error} loading={false} /> {/* Assuming NotificationBanner is available */}

      <div className={styles.formGroup}>
        <label>Platform Fee (%):</label>
        <input
          type="number"
          value={platformFee}
          onChange={(e) => setPlatformFee(Number(e.target.value))}
          min="0"
          max="100"
          step="0.01"
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>Dividend Start Date:</label>
        <input
          type="date"
          value={dividendStartDate}
          onChange={(e) => setDividendStartDate(e.target.value)}
          required
        />
      </div>
      <div className={styles.formGroup}>
        <label>Sale Start Date:</label>
        <input
          type="date"
          value={saleStartDate}
          onChange={(e) => setSaleStartDate(e.target.value)}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>Selling Cool Off Period (Days):</label>
        <input
          type="number"
          value={sellingCoolOffPeriod}
          onChange={(e) => setSellingCoolOffPeriod(Number(e.target.value))}
          min="0"
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>Estimated Return of Interest (By market rate):</label>
        <input
          type="number"
          value={estimatedROI}
          onChange={(e) => setEstimatedROI(Number(e.target.value))}
          min="0"
          required
        />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Settings</button>
      </div>
    </div>
  );
};

export default AssetSettings;

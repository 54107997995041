// src/components/AssetDetailEditor/InternalStructure.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveInternalStructureRequest, getInternalStructureRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';

import styles from './InternalStructure.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface InternalStructureProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const InternalStructure: React.FC<InternalStructureProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  console.log('InternalStructure: Selected Asset:', selectedAsset);
  useSelector((state: RootState) => {
    console.log('InternalStructure: Details:', state.assetEditor);
    return state.assetEditor.internalStructureDetails;
  });


  const {
    numberOfBedRoom,
    numberOfBathroom,
    interiorSizeInSqft,
    heating,
    cooling,
  } = useSelector((state: RootState) => state.assetEditor.internalStructureDetails);
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const [bedrooms, setBedrooms] = useState<number>(0);
  const [bathrooms, setBathrooms] = useState<number>(0);
  const [size, setSize] = useState<number>(0);
  const [heatingType, setHeatingType] = useState('');
  const [coolingType, setCoolingType] = useState('');

  useEffect(() => {
    dispatch(getInternalStructureRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    setBedrooms(numberOfBedRoom);
    setBathrooms(numberOfBathroom);
    setSize(interiorSizeInSqft);
    setHeatingType(heating);
    setCoolingType(cooling);
  }, [numberOfBedRoom, numberOfBathroom, interiorSizeInSqft, heating, cooling]);

  const handleSave = () => {
    const internalStructureDetails = {
      numberOfBedRoom: bedrooms,
      numberOfBathroom: bathrooms,
      interiorSizeInSqft: size,
      heating: heatingType,
      cooling: coolingType
    }
    const _id = selectedAsset._id;
    const assetID = selectedAsset.assetID;
    console.log('Saving internal structure details:', internalStructureDetails);
    dispatch(saveInternalStructureRequest({ internalStructureDetails, assetID, _id }));
  };

  return (
    <div className={styles.internalStructureContainer}>
      <h2 className={styles.heading}>Internal Structure</h2>
      <NotificationBanner success={success} error={error} loading={loading} />
      
      <div className={styles.formGroup}>
        <label>Number of Bedrooms:</label>
        <input type="number" value={bedrooms} onChange={(e) => setBedrooms(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Number of Bathrooms:</label>
        <input type="number" value={bathrooms} onChange={(e) => setBathrooms(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Interior Size (sq ft):</label>
        <input type="number" value={size} onChange={(e) => setSize(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Heating:</label>
        <input type="text" value={heatingType} onChange={(e) => setHeatingType(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Cooling:</label>
        <input type="text" value={coolingType} onChange={(e) => setCoolingType(e.target.value)} />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default InternalStructure;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { createDistributionRequest, fetchDistributionsByAssetIDRequest } from '../../../../features/distribution/distributionSlice';
import styles from './DistrbutionDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBannerNew';
import { getTokenDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import DistributionDetailsModal from './DistributionDetailsModal';

interface DistributionDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

interface DistributionDetail {
  _id?: string;
  distributionMonth: string;
  distributionInitiatedDate: string;
  distributedTotalAmount: number;
  distributedAmountPerToken: number;
  claimedUserCount: number;
  claimedForTokenCount: number;
  initiatedUserID: string;
  assetID: string;
  distributionStatus: string;
}

const DistributionDetails: React.FC<DistributionDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const { distributionDetails, distributionDetailsList, status, error } = useSelector((state: RootState) => state.distribution);
  const { tokenDetails } = useSelector((state: RootState) => state.assetEditor);

  const assetID = selectedAsset._id;
  const [distributionMonth, setDistributionMonth] = useState<string>('');
  const [distributionInitiatedDate, setDistributionInitiatedDate] = useState<string>('');
  const [distributedTotalAmount, setDistributedTotalAmount] = useState<number>(0);
  const [distributedAmountPerToken, setDistributedAmountPerToken] = useState<number>(0);
  const [totalTokens, setTotalTokens] = useState<number>(1000); // Example value

  const [summaryNotification, setSummaryNotification] = useState<{ type: any; message: string } | null>(null);
  const [formNotification, setFormNotification] = useState<{ type: any; message: string } | null>(null);

  const [sortColumn, setSortColumn] = useState<string>('distributedTotalAmount');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [localDistributionDetailsList, setLocalDistributionDetailsList] = useState<DistributionDetail[]>([]);

  const [selectedDistributionID, setSelectedDistributionID] = useState<string | null>(null);

  console.log('distributionMonth', distributionMonth);


  useEffect(() => {
    dispatch(getTokenDetailsRequest(assetID));
    dispatch(fetchDistributionsByAssetIDRequest(assetID));
  }, [dispatch, assetID]);

  useEffect(() => {
    if (tokenDetails) {
      setTotalTokens(tokenDetails.totalNumberOfTokens);
    }
  }, [tokenDetails]);

  useEffect(() => {
    if (distributionDetails) {
      setDistributionMonth(distributionDetails.distributionMonth);
      setDistributionInitiatedDate(distributionDetails.distributionInitiatedDate);
      setDistributedTotalAmount(distributionDetails.distributedTotalAmount);
      setDistributedAmountPerToken(distributionDetails.distributedAmountPerToken);
    }
  }, [distributionDetails]);

  useEffect(() => {
    if (totalTokens > 0) {
      setDistributedAmountPerToken(distributedTotalAmount / totalTokens);
    }
  }, [distributedTotalAmount, totalTokens]);

  useEffect(() => {
    const sortedList = [...distributionDetailsList].sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setLocalDistributionDetailsList(sortedList);
  }, [sortColumn, sortDirection, distributionDetailsList]);

  // Handle click to show modal with DistributionID
  const handleDetailsClick = (distributionID: string) => {
    setSelectedDistributionID(distributionID);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setSelectedDistributionID(null);
  };

  const handleSave = () => {
    if (!distributionMonth || !distributionInitiatedDate) {
      setFormNotification({
        type: 'error',
        message: 'Please fill in all required fields and ensure the amounts are valid.'
      });
      return;
    }

    const distributionDetail: DistributionDetail = {
      assetID: assetID,
      distributionMonth,
      distributionInitiatedDate,
      distributedTotalAmount,
      distributedAmountPerToken,
      claimedUserCount: 0,
      claimedForTokenCount: 0,
      initiatedUserID: '60f1b0b3b3f1f00015f2b3b3', // Hardcoded for now
      distributionStatus: 'Initiated' // Hardcoded for now
    };

    dispatch(createDistributionRequest({ distributionDetail, assetID: assetID }))
    //   .then(() => {
    //     setFormNotification({
    //       type: 'success',
    //       message: 'Distribution details saved successfully.'
    //     });
    //   })
    //   .catch(() => {
    //     setFormNotification({
    //       type: 'error',
    //       message: 'Failed to save distribution details.'
    //     });
    //   });
  };

  const getMonthOptions = () => {
    const options = [];
    const now = new Date();
    for (let i = 0; i < 7; i++) {
      const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
      options.push({
        value: month.toISOString().slice(0, 7),
        label: month.toLocaleString('default', { month: 'long', year: 'numeric' })
      });
    }
    return options;
  };

  const monthOptions = getMonthOptions();

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <div className={styles.distributionDetailContainer}>
      <h2 className={styles.heading}>Distribution Details</h2>
      
      {summaryNotification && (
        <NotificationBanner type={summaryNotification.type} message={summaryNotification.message} />
      )}

      <div className={styles.summaryContainer}>
        <h3>Distribution Details Summary</h3>
        {localDistributionDetailsList?.length === 0 ? (
          <p>No distribution details available.</p>
        ) : (
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableHeader} onClick={() => handleSort('distributionMonth')}>Distribution Month</div>
              <div className={styles.tableHeader} onClick={() => handleSort('distributionInitiatedDate')}>Distribution Initiated Date</div>
              <div className={styles.tableHeader} onClick={() => handleSort('distributedTotalAmount')}>Distributed Total Amount {sortColumn === 'distributedTotalAmount' && (sortDirection === 'asc' ? '↑' : '↓')}</div>
              <div className={styles.tableHeader} onClick={() => handleSort('distributedAmountPerToken')}>Distributed Amount Per Token {sortColumn === 'distributedAmountPerToken' && (sortDirection === 'asc' ? '↑' : '↓')}</div>
              <div className={styles.tableHeader} onClick={() => handleSort('distributionStatus')}>Distribution Status {sortColumn === 'distributionStatus' && (sortDirection === 'asc' ? '↑' : '↓')}</div>
              <div className={styles.tableHeader} onClick={() => handleSort('claimedUserCount')}>Claimed User Count {sortColumn === 'claimedUserCount' && (sortDirection === 'asc' ? '↑' : '↓')}</div>
              <div className={styles.tableHeader} onClick={() => handleSort('claimedForTokenCount')}>Claimed For Token Count {sortColumn === 'claimedForTokenCount' && (sortDirection === 'asc' ? '↑' : '↓')}</div>
              <div className={styles.tableHeader}>Initiated User ID</div>
              <div className={styles.tableHeader}>Detaails</div>
            </div>
            {localDistributionDetailsList?.map((detail: DistributionDetail) => (
              <div key={detail._id} className={styles.tableRow}>
                <div className={styles.tableCell}>{detail.distributionMonth}</div>
                <div className={styles.tableCell}>{new Date(detail.distributionInitiatedDate).toLocaleDateString()}</div>
                <div className={styles.tableCell}>${detail.distributedTotalAmount}</div>
                <div className={styles.tableCell}>${detail.distributedAmountPerToken}</div>
                <div className={styles.tableCell}>{detail.distributionStatus}</div>
                <div className={styles.tableCell}>{detail.claimedUserCount}</div>
                <div className={styles.tableCell}>{detail.claimedForTokenCount}</div>
                <div className={styles.tableCell}>{detail.initiatedUserID}</div>
                <div className={styles.tableCell}>
                  <button onClick={() => handleDetailsClick(detail._id ?? '')}>Details</button>
                </div>                
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.formContainer}>
        <h3>Add New Distribution Details</h3>
        {formNotification && (
          <NotificationBanner type={formNotification.type} message={formNotification.message} />
        )}
        <div className={styles.formGroup}>
          <label>Distribution Month:</label>
          <select value={distributionMonth} onChange={(e) => setDistributionMonth(e.target.value)}>
            <option value="">Select Month</option>
            {monthOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.formGroup}>
          <label>Distribution Initiated Date:</label>
          <input type="date" value={distributionInitiatedDate} onChange={(e) => setDistributionInitiatedDate(e.target.value)} />
        </div>

        {/* <div className={styles.formGroup}>
          <label>Distributed Total Amount:</label>
          <input type="number" value={distributedTotalAmount} onChange={(e) => setDistributedTotalAmount(Number(e.target.value))} />
        </div>

        <div className={styles.formGroup}>
          <label>Distributed Amount Per Token:</label>
          <input type="number" value={distributedAmountPerToken} readOnly />
        </div>

        <div className={styles.formGroup}>
          <label>Total Tokens:</label>
          <input type="text" value={totalTokens} readOnly />
        </div> */}

        <div className={styles.buttonContainer}>
          <button className={styles.saveButton} onClick={handleSave}>Initiate Distribution</button>
        </div>
      </div>

      {/* Render the modal if a distributionID is selected */}
      {selectedDistributionID && (
        <DistributionDetailsModal
          distributionID={selectedDistributionID}
          assetID={selectedAsset._id}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default DistributionDetails;

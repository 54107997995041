import React from 'react';
import styles from './HowToCreateAsset.module.css';

const HowToCreateAsset: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2>How to Create an Asset</h2>
      <div className={styles.flowchartContainer}>
        <svg className={styles.flowchart} viewBox="0 0 1200 2800" xmlns="http://www.w3.org/2000/svg">
          {/* Start Symbol */}
          <circle cx="500" cy="50" r="30" className={styles.startSymbol} />
          <text x="500" y="55" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Start
          </text>
          <line x1="500" y1="80" x2="500" y2="120" className={styles.line} />

          {/* Infra Details Background */}
          <rect x="250" y="120" width="500" height="540" className={styles.infraBackground} />
          <text x="350" y="140" textAnchor="middle" alignmentBaseline="middle" className={styles.infraTitle}>
            Infra Details
          </text>

          {/* Location Details */}
          <line x1="500" y1="120" x2="500" y2="180" className={styles.line} />
          <rect x="350" y="180" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="210" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Location Details
          </text>
          <line x1="500" y1="240" x2="500" y2="280" className={styles.line} />

          {/* Basic Details */}
          <rect x="350" y="280" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="310" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Basic Details
          </text>
          <line x1="500" y1="340" x2="500" y2="380" className={styles.line} />

          {/* External Details */}
          <rect x="350" y="380" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="410" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            External Details
          </text>
          <line x1="500" y1="440" x2="500" y2="480" className={styles.line} />

          {/* Internal Details */}
          <rect x="350" y="480" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="510" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Internal Details
          </text>
          <line x1="500" y1="540" x2="500" y2="580" className={styles.line} />

          {/* Asset Features */}
          <rect x="350" y="580" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="610" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Asset Features
          </text>
          <line x1="500" y1="640" x2="500" y2="680" className={styles.line} />

          {/* Decision Symbol for Purchase Valuation Document */}
          <polygon points="400,680 600,680 600,740 400,740" className={styles.decisionSymbol} />
          <text x="500" y="710" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Is Purchase Valuation document ready?
          </text>
          <line x1="500" y1="740" x2="500" y2="780" className={styles.line} />

          {/* Financial Details Background */}
          <rect x="250" y="780" width="500" height="480" className={styles.financialBackground} />
          <text x="350" y="800" textAnchor="middle" alignmentBaseline="middle" className={styles.financialTitle}>
            Financial Detail
          </text>

          {/* Purchase Details */}
          <line x1="500" y1="780" x2="500" y2="880" className={styles.line} />
          <rect x="350" y="880" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="910" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Purchase Details
          </text>
          <line x1="500" y1="940" x2="500" y2="980" className={styles.line} />

          {/* Valuation Details */}
          <rect x="350" y="980" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1010" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Valuation Details
          </text>
          <line x1="500" y1="1040" x2="500" y2="1080" className={styles.line} />

          {/* Financial Details */}
          <rect x="350" y="1080" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1110" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Financial Details
          </text>
          <line x1="500" y1="1140" x2="500" y2="1180" className={styles.line} />

          {/* Tokenization Details */}
          <rect x="350" y="1180" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1210" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Tokenization Details
          </text>
          <line x1="500" y1="1240" x2="500" y2="1280" className={styles.line} />

          {/* Decision Symbol for Blockchain Token */}
          <polygon points="400,1280 600,1280 600,1340 400,1340" className={styles.decisionSymbol} />
          <text x="500" y="1310" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Is Blockchain token ready?
          </text>
          <line x1="500" y1="1340" x2="500" y2="1380" className={styles.line} />

          {/* Blockchain Background */}
          <rect x="250" y="1380" width="500" height="290" className={styles.blockchainBackground} />
          <text x="350" y="1400" textAnchor="middle" alignmentBaseline="middle" className={styles.blockchainTitle}>
            Blockchain
          </text>

          {/* Blockchain Details */}
          <line x1="500" y1="1380" x2="500" y2="1480" className={styles.line} />
          <rect x="350" y="1480" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1510" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Blockchain Details
          </text>
          <line x1="500" y1="1540" x2="500" y2="1580" className={styles.line} />

          {/* Token Offering Details */}
          <rect x="350" y="1580" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1610" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Token Offering Details
          </text>
          <line x1="500" y1="1640" x2="500" y2="1680" className={styles.line} />

          {/* Decision Symbol for Renter */}
          <polygon points="400,1680 600,1680 600,1740 400,1740" className={styles.decisionSymbol} />
          <text x="500" y="1710" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Is Renter ready?
          </text>
          <line x1="500" y1="1740" x2="500" y2="1780" className={styles.line} />

          {/* Rent Details Background */}
          <rect x="250" y="1780" width="500" height="280" className={styles.rentBackground} />
          <text x="350" y="1800" textAnchor="middle" alignmentBaseline="middle" className={styles.rentTitle}>
            Rent Details
          </text>

          {/* Rent Contract Details */}
          <line x1="500" y1="1780" x2="500" y2="1880" className={styles.line} />
          <rect x="350" y="1820" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1850" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Rent Contract Details
          </text>
          <line x1="500" y1="1880" x2="500" y2="1980" className={styles.line} />

          {/* Distribution Details */}
          <rect x="350" y="1900" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="1930" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Distribution Details
          </text>
          <line x1="500" y1="1960" x2="500" y2="1980" className={styles.line} />

          {/* Publish Details */}
          <rect x="350" y="1980" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="500" y="2010" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Publish Details
          </text>
          {/* Vertical line */}
          <line x1="500" y1="2040" x2="500" y2="2100" className={styles.line} />
          {/* Horizontal line */}
          <line x1="250" y1="2100" x2="1000" y2="2100" className={styles.line} />
          {/* Parallel line */}
          <line x1="250" y1="2100" x2="250" y2="2260" className={styles.line} />
          <line x1="620" y1="2100" x2="620" y2="2260" className={styles.line} />
          <line x1="1000" y1="2100" x2="1000" y2="2260" className={styles.line} />

          {/* Parallel Flow: Monthly Rent Details and Valuation Details */}
          {/* Monthly Rent Details */}
          <rect x="100" y="2260" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="250" y="2190" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Update Monthly
          </text>
          <text x="250" y="2290" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Monthly Rent Details
          </text>
          <line x1="250" y1="2320" x2="250" y2="2380" className={styles.line} />

          {/* Distribution Details */}
          <rect x="470" y="2260" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="620" y="2190" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Update Monthly
          </text>
          <text x="630" y="2290" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Distribution Details
          </text>
          <line x1="620" y1="2320" x2="620" y2="2380" className={styles.line} />

          {/* Valuation Details */}
          <rect x="850" y="2260" width="300" height="60" rx="15" ry="15" className={styles.processSymbol} />
          <text x="1000" y="2190" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Update Quaterly
          </text>
          <text x="1000" y="2290" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Valuation Details
          </text>
          <line x1="1000" y1="2320" x2="1000" y2="2380" className={styles.line} />

          {/* Connect Parallel Flows to End */}
          <line x1="250" y1="2380" x2="1000" y2="2380" className={styles.line} />
          <line x1="500" y1="2380" x2="500" y2="2440" className={styles.line} />

          <circle cx="500" cy="2440" r="30" className={styles.startSymbol} />
          <text x="500" y="2440" textAnchor="middle" alignmentBaseline="middle" className={styles.text}>
            Stop
          </text>

        </svg>
      </div>
    </div>
  );
};

export default HowToCreateAsset;

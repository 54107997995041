import React, { useState } from 'react';
import AssetValuationCompanies from '../AssetValuationCompanies/AssetValuationCompanies';
import PropertyManagementCompany from '../PropertyManagementCompanies/PropertyManagementCompanies';
import AssetOwningCompany from '../AssetOwningCompanies/AssetOwningCompanies';
import styles from './BackendPartners.module.css';

const BackendPartners: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Asset Valuation Companies');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Asset Valuation Companies':
        return <AssetValuationCompanies />;
      case 'Property Management Company':
        return <PropertyManagementCompany />;
      case 'Asset Owning Company':
        return <AssetOwningCompany />;
      default:
        return <AssetValuationCompanies />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'Asset Valuation Companies' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('Asset Valuation Companies')}
        >
          Asset Valuation Companies
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'Property Management Company' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('Property Management Company')}
        >
          Property Management Company
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'Asset Owning Company' ? styles.activeTab : ''}`}
          onClick={() => setActiveTab('Asset Owning Company')}
        >
          Asset Owning Company
        </button>
      </div>
      <div className={styles.tabContent}>{renderTabContent()}</div>
    </div>
  );
};

export default BackendPartners;

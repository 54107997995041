// features/auth/authSaga.ts

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAssetRequest, createAssetSuccess, createAssetFailure, ShowReqestPropertycount, requestPortfolioDetails, requestAllProperty, responseAllPropertyDetails } from './assetSlice';
import { showUnpublishedAssetsRequest, showUnpublishedAssetsSuccess, showUnpublishedAssetsFailure,
  showPublishedAssetsRequest, showPublishedAssetsSuccess, showPublishedAssetsFailure,ShowPropertycount,responsePortfolioDetails } from './assetSlice';
import { getPorfolioDetails, getPublishedAssetsApi, getTotalPropertiesCount,createAssetApi, getUnPublishedAssetsApi, getAllPropertyDetails } from './assetApi';

export interface ResponseGenerator{
  config?:any,
  data?:any,
  headers?:any,
  request?:any,
  status?:number,
  statusText?:string
}

function* createAsset() {
  try {
    const response: ResponseGenerator = yield call(createAssetApi);
    yield put(createAssetSuccess(response.data));
} catch (error: any) {
    console.log('Error:', error?.response?.data?.message);
    const errorMessage = error?.response?.data?.message || 'An error occurred while registering';
    yield put(createAssetFailure(errorMessage));
  }
}

function* showUnpublishedAssets() {
    try {
      const response: ResponseGenerator = yield call(getUnPublishedAssetsApi);
      console.log('showUnpublishedAssets response', response);
      yield put(showUnpublishedAssetsSuccess(response.data));
    } catch (error) {
        console.log('Error:', error);
        const errorMessage = error || 'An error occurred while registering';
          yield put(showUnpublishedAssetsFailure(''));
    }
  }

  function* showPublishedAssets() {
    try {
      const response: ResponseGenerator = yield call(getPublishedAssetsApi);
      console.log('showPublishedAssets response', response);
      yield put(showPublishedAssetsSuccess(response?.data));
    } catch (error) {
        console.log('Error:', error);
        const errorMessage = error || 'An error occurred while registering';
        yield put(showPublishedAssetsFailure(errorMessage));
      }
  }

  function* getTotalPropertycount(assetId:any){
    try{
      const response: ResponseGenerator = yield call(getTotalPropertiesCount,assetId)
      yield put(ShowPropertycount(response.data));
    }catch(error){
      console.log('Error:', error);
      yield put(showPublishedAssetsFailure(error));
    }
  }

  function* getPortfolioDetails(userId :any){
    try{
      const response : ResponseGenerator = yield call(getPorfolioDetails,userId?.payload);
      yield put(responsePortfolioDetails(response.data));
    }catch(error){
      yield put(showPublishedAssetsFailure(error));
    }
  }

function* getTotalProperty(){
  try{
    const response : ResponseGenerator = yield call(getAllPropertyDetails);
    yield put(responseAllPropertyDetails(response.data));
  }catch(error){
    yield put(showPublishedAssetsFailure(error));
  }
}  

function* assetSaga() {
  yield takeLatest(createAssetRequest.type, createAsset);
  yield takeLatest(showUnpublishedAssetsRequest.type, showUnpublishedAssets);
  yield takeLatest(showPublishedAssetsRequest.type, showPublishedAssets);
  yield takeLatest(ShowReqestPropertycount.type, getTotalPropertycount);
  yield takeLatest(requestPortfolioDetails.type,getPortfolioDetails);
  yield takeLatest(requestAllProperty.type,getTotalProperty);
}

export default assetSaga;

// app/pages/AdminPage.tsx

import React from 'react';
import TopBanner from '../../AdminTopBanner/TopBanner';
import { Outlet } from 'react-router-dom';
import styles from './AdminPage.module.css';

const AdminPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <TopBanner />
      <div className={styles.content}>
        <div className={styles.mainContent}>
            <Outlet /> {/* Render child routes */}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;

// src/components/AssetDetailEditor/TokenDetails.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTokenDetailsRequest, getTokenDetailsRequest, getBasicDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';

import styles from './TokenDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { fetchAssetPriceRequest, fetchTokenPriceRequest, fetchTotalTokensRequest } from '../../../../features/assetValuation/assetValuationSlice';

interface TokenDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const TokenDetails: React.FC<TokenDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  const { propertyID } = useSelector((state: RootState) => {
    console.log('BasicDetail::', state.assetEditor);
    return state.assetEditor.basicDetails;
  });


  const { totalTokens: totalTokens1 } = useSelector((state: RootState) => {
    return state.valuation;
  });

  const {
    tokenName,
    tokenId,
    totalNumberOfTokens,
    tokenType,
    contractAddress,
    ownerWalletAddress,
  } = useSelector((state: RootState) => state.assetEditor.tokenDetails);

  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [totalTokens, setTotalTokens] = useState(0);
  const [type, setType] = useState('');
  const [contract, setContract] = useState('');
  const [ownerWallet, setOwnerWallet] = useState('');

  useEffect(() => {
    dispatch(getBasicDetailsRequest(selectedAsset._id));
    dispatch(getTokenDetailsRequest(selectedAsset._id));
    dispatch(fetchTotalTokensRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    const suggestedTokenName = `AssetSlices-${propertyID}`;
    const suggestedTokenID = `ASC-${propertyID}`;
    setName(suggestedTokenName);
    setId(suggestedTokenID);
  }, [propertyID]);

  useEffect(() => {
    setTotalTokens(Number(totalTokens1));
  }, [totalTokens1]);

  useEffect(() => {
    setName(tokenName);
    setId(tokenId);
    setTotalTokens(totalNumberOfTokens);
    setType(tokenType);
    setContract(contractAddress);
    setOwnerWallet(ownerWalletAddress);
  }, [tokenName, tokenId, totalNumberOfTokens, tokenType, contractAddress, ownerWalletAddress]);

  const handleSave = () => {
    const tokenDetails = {
      tokenName: name,
      tokenId: id,
      totalNumberOfTokens: totalTokens,
      tokenType: type,
      contractAddress: contract,
      ownerWalletAddress: ownerWallet,
      assetID: selectedAsset._id,
    };
    dispatch(saveTokenDetailsRequest({ tokenDetails, assetID: selectedAsset.assetID, _id: selectedAsset._id }));
  };

  return (
    <div className={styles.tokenDetailsContainer}>
      <h2 className={styles.heading}>Token Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.formGroup}>
        <label>Token Name:</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Token ID:</label>
        <input type="text" value={id} onChange={(e) => setId(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Total Number of Tokens:</label>
        <input type="number" value={totalTokens} onChange={(e) => setTotalTokens(Number(e.target.value))} readOnly />
      </div>

      <div className={styles.formGroup}>
        <label>Token Type:</label>
        <select value={type} onChange={(e) => setType(e.target.value)} className={styles.dropdown}>
          <option value="">Select Type</option>
          <option value="Ethereum">Ethereum</option>
          <option value="Solana">Solana</option>
        </select>
      </div>

      <div className={styles.formGroup}>
        <label>Contract Address:</label>
        <input type="text" value={contract} onChange={(e) => setContract(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Owner Wallet Address:</label>
        <input type="text" value={ownerWallet} onChange={(e) => setOwnerWallet(e.target.value)} />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default TokenDetails;

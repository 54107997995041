import React, { useState } from 'react';
import styles from './Offers.module.css';

interface Offer {
  offerName: string;
  offerDescription: string;
  offerId: string;
  offerType: string;
  offerValue: number;
}

const Offers: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const [newOffer, setNewOffer] = useState<Offer>({
    offerName: '',
    offerDescription: '',
    offerId: '',
    offerType: '',
    offerValue: 0,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewOffer((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddOffer = () => {
    if (newOffer.offerName && newOffer.offerId && newOffer.offerValue) {
      setOffers((prevOffers) => [...prevOffers, newOffer]);
      setNewOffer({ offerName: '', offerDescription: '', offerId: '', offerType: '', offerValue: 0 });
    } else {
      alert('Please fill out all required fields.');
    }
  };

  return (
    <div className={styles.offersContainer}>
      <h2>Offers</h2>

      {/* Offer List Table */}
      <table className={styles.offersTable}>
        <thead>
          <tr>
            <th>Offer Name</th>
            <th>Offer Description</th>
            <th>Offer ID</th>
            <th>Offer Type</th>
            <th>Offer Value</th>
          </tr>
        </thead>
        <tbody>
          {offers.map((offer, index) => (
            <tr key={index}>
              <td>{offer.offerName}</td>
              <td>{offer.offerDescription}</td>
              <td>{offer.offerId}</td>
              <td>{offer.offerType}</td>
              <td>{offer.offerValue}</td>
            </tr>
          ))}
          {offers.length === 0 && (
            <tr>
              <td colSpan={5} className={styles.noOffers}>
                No offers available.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Add New Offer Form */}
      <h3>Add New Offer</h3>
      <div className={styles.formContainer}>
        <div className={styles.formGroup}>
          <label htmlFor="offerName">Offer Name:</label>
          <input
            type="text"
            id="offerName"
            name="offerName"
            value={newOffer.offerName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="offerDescription">Offer Description:</label>
          <textarea
            id="offerDescription"
            name="offerDescription"
            value={newOffer.offerDescription}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="offerId">Offer ID:</label>
          <input
            type="text"
            id="offerId"
            name="offerId"
            value={newOffer.offerId}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="offerType">Offer Type:</label>
          <select
            id="offerType"
            name="offerType"
            value={newOffer.offerType}
            onChange={handleInputChange}
          >
            <option value="">Select Type</option>
            <option value="Discount">Discount</option>
            <option value="Cashback">Cashback</option>
            <option value="Buy One Get One">Buy One Get One</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="offerValue">Offer Value:</label>
          <input
            type="number"
            id="offerValue"
            name="offerValue"
            value={newOffer.offerValue}
            onChange={handleInputChange}
            required
          />
        </div>
        <button className={styles.addButton} onClick={handleAddOffer}>
          Add Offer
        </button>
      </div>
    </div>
  );
};

export default Offers;

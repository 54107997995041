import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenDetailsRequest, fetchTotalTokenPurchaseByAssetID, fetchPurchaseHistoryByAssetID } from '../../../../features/assetEditor/assetEditorSlice';
import { getTotalTokensRequest } from '../../../../features/assetTokenization/assetTokenizationSlice';
import { RootState } from '../../../../store/rootReducer';
import styles from './TokenPurchases.module.css';
import { isConstructorDeclaration } from 'typescript';

interface TokenPurchasesProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const TokenPurchases: React.FC<TokenPurchasesProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  // Fetch the total number of tokens and token purchases list from Redux
  const { totalTokenPurchased, purchaseHistory, loading, error } = useSelector((state: RootState) => state.assetEditor);
  const { totalAssetTokens } = useSelector((state: RootState) => state.assetTokenization);
  
  // Use state to manage token purchases
  const [totalTokens, setTotalTokens] = useState<number>(0);
  const [tokenPurchaseList, setTokenPurchaseList] = useState<any[]>([]);

  useEffect(() => {
    if (selectedAsset) {
      // Fetch the total number of tokens for the selected asset
      dispatch(getTotalTokensRequest(selectedAsset._id));

      dispatch(fetchTotalTokenPurchaseByAssetID(selectedAsset._id));

      // Fetch the token purchases for the selected asset
      dispatch(fetchPurchaseHistoryByAssetID(selectedAsset._id));
    }
  }, [dispatch, selectedAsset]);

  useEffect(() => {
    if (totalAssetTokens) {
      setTotalTokens(totalAssetTokens);
    }
  }, [totalAssetTokens]);

  useEffect(() => {
    if (purchaseHistory) {
      setTokenPurchaseList(purchaseHistory);
    }
  }, [purchaseHistory]);

  return (
    <div className={styles.tokenPurchasesContainer}>
      <h2 className={styles.heading}>Token Purchases {selectedAsset.name}</h2>

      {/* Show loading and error states */}
      {loading && <p>Loading...</p>}
      {error && <p className={styles.error}>Error loading token purchases: {error}</p>}

      {/* Show total number of tokens */}
      <div className={styles.totalTokens}>
        <p>Total Number of Tokens: <strong>{totalTokens}</strong></p>
      </div>
      <div className={styles.totalTokens}>
        <p>Total Number of Tokens Purchased: <strong>{totalTokenPurchased}</strong></p>
      </div>

      {/* Token Purchases Table */}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Purchase Date</th>
              <th>Buyer</th>
              <th>Number of Tokens</th>
              <th>Purchase Amount per token</th>
              <th>Purchase Amount</th>
            </tr>
          </thead>
          <tbody>
            {tokenPurchaseList.length > 0 ? (
              tokenPurchaseList.map((purchase: any) => (
                <tr key={purchase._id}>
                  <td>{new Date(purchase.purchaseDate).toLocaleDateString()}</td>
                  <td>{purchase.purchaserID}</td>
                  <td>{purchase.numberOfTokens}</td>
                  <td>${purchase.purchasePricePerToken}</td>
                  <td>${purchase.numberOfTokens * purchase.purchasePricePerToken}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className={styles.noData}>
                  No token purchases available for this asset.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TokenPurchases;

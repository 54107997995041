// src/components/AssetDetailEditor/PurchaseToken.tsx
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from './PurchaseToken.module.css';

const PurchaseToken: React.FC = () => {
  const [numTokens, setNumTokens] = useState(0);
  const perTokenCost = 50; // Assuming the cost is $50 per token

  const handleIncrement = () => {
    setNumTokens(numTokens + 1);
  };

  const handleDecrement = () => {
    if (numTokens > 0) {
      setNumTokens(numTokens - 1);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle the form submission
    console.log(`Submitting purchase request for ${numTokens} tokens`);
  };

  const totalPurchaseCost = numTokens * perTokenCost;

  return (
    <div className={styles.purchaseContainer}>
      <div className={styles.formWrapper}>
        <h2 className={styles.purchaseHeading}>Purchase Token2</h2>
        <form className={styles.purchaseForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label>Number of tokens available:</label>
            <input type="text" value="5000" readOnly className={styles.readOnlyInput} />
          </div>
          <div className={styles.formGroup}>
            <label>Total token offered:</label>
            <input type="text" value="10000" readOnly className={styles.readOnlyInput} />
          </div>
          <div className={styles.formGroup}>
            <label>Remaining token available:</label>
            <input type="text" value="5000" readOnly className={styles.readOnlyInput} />
          </div>
          <div className={styles.formGroup}>
            <label>Per token cost:</label>
            <input type="text" value="$50.00" readOnly className={styles.readOnlyInput} />
          </div>
          <div className={styles.formGroup}>
            <label>Number of tokens to purchase:</label>
            <div className={styles.tokenInputContainer}>
              <button type="button" className={styles.iconButton} onClick={handleDecrement}>
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <input
                type="text"
                value={numTokens}
                readOnly
                className={styles.tokenInput}
              />
              <button type="button" className={styles.iconButton} onClick={handleIncrement}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
          <div className={styles.formGroup}>
            <label>Total purchase cost:</label>
            <input type="text" value={`$${totalPurchaseCost.toFixed(2)}`} readOnly className={styles.readOnlyInput} />
          </div>
          <div className={styles.submitGroup}>
            <button type="submit" className={styles.submitButton}>Submit Purchase Request</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PurchaseToken;

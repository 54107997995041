// src/components/DistributionDetailsModal/DistributionDetailsModal.tsx

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDistributionDetailsRequest } from '../../../../features/userDistribution/userDistributionSlice'; // Import the action
import { RootState } from '../../../../store/rootReducer'; // Import RootState for types
import styles from './DistributionDetailsModal.module.css';

interface DistributionDetailsModalProps {
  distributionID: string;
  assetID: string;
  onClose: () => void;
}

const DistributionDetailsModal: React.FC<DistributionDetailsModalProps> = ({ distributionID, assetID, onClose }) => {
  const dispatch = useDispatch();

  // Get the distribution details from Redux store
  const { userDistributionDetails, loading, error } = useSelector((state: RootState) => state.userDistribution);

  console.log('userDistributionDetails ', userDistributionDetails);

  // Fetch the distribution details when the component mounts
  useEffect(() => {
    dispatch(getUserDistributionDetailsRequest({ assetID, userDistributionID: distributionID }));
  }, [dispatch, assetID, distributionID]);

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <h2>Distribution Details</h2>

        {/* Show loading state */}
        {loading && <p>Loading...</p>}

        {/* Show error if any */}
        {error && <p className={styles.errorMessage}>{error}</p>}

        {/* Show the distribution details if available */}
        {userDistributionDetails && (
          <table className={styles.detailsTable}>
            <thead>
              <tr>
                <th>Asset ID</th>
                <th>Distribution ID</th>
                <th>User ID</th>
                <th>Claimed Status</th>
                <th>Token Holding</th>
                <th>Dividend Received</th>
              </tr>
            </thead>
            <tbody>
              {userDistributionDetails.map((detail: any) => (
                <tr key={detail._id}>
                  <td>{detail.assetID}</td>
                  <td>{detail.distributionID}</td>
                  <td>{detail.userID}</td>
                  <td>{detail.claimedStatus}</td>
                  <td>{detail.tokenHolding}</td>
                  <td>{detail.dividendReceived}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Close button */}
        <button onClick={onClose} className={styles.closeButton}>Close</button>
      </div>
    </div>
  );
};

export default DistributionDetailsModal;

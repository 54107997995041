import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from '../../../features/auth/authSlice';
import { RootState } from '../../../store/rootReducer';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';


const Login: React.FC = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, user, error } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    console.log("checking...")
    console.log(user)
    if (isLoggedIn && user.redirectUrl === '/admin/dashboard') {
      navigate(user.redirectUrl);
    } if(user !== undefined && user !== null && user.redirectUrl === '/me/dashboard') {
      console.log("Invalid User")
      setUserNameError('Invalid User');
    }
  }, [isLoggedIn, navigate]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    // if (!validateEmail(email)) {
    //   setEmailError('Invalid email format');
    //   return;
    // }
    // setEmailError('');
    dispatch(loginRequest({ username: userName, password }));
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  // const handleEmailBlur = () => {
  //   if (!validateEmail(email)) {
  //     setUserNameError('Invalid email format');
  //   } else {
  //     setUserNameError('');
  //   }
  // };


  return (
    <div>
        {/* Display error notification if there's an error */}
        {error && <div className={styles.errorNotification}>{error}</div>}

        {/* <h2>Login</h2> */}
        <div className={styles.inputField}>
          <label htmlFor="email">Admin Username</label>
          <input
            type="email"
            id="email"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Admin Username"
            className={userNameError ? styles.invalid : ''}
            //onBlur={handleEmailBlur} // Validate on blur
          />
          {userNameError && <span className={styles.errorMessage}>{userNameError}</span>}
        </div>
        <div className={styles.inputField}>
          <label htmlFor="password">Admin Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button className={styles.button} onClick={handleLogin}>
          Login
        </button>
        <div className={styles.linksContainer}>
          {/* <a className={styles.link} href="/register">Register</a> */}
          {/* <a className={styles.link} href="/forgot-password">Forgot Password?</a> */}
        </div>
    </div>
  );
};

export default Login;

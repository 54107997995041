// src/components/AssetDetailEditor/AssetFeatures.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AssetFeatures.module.css';
import { getFeaturesRequest, saveFeaturesRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface AssetFeaturesProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const AssetFeatures: React.FC<AssetFeaturesProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  const featureDisplay: any = {
    cooling: 'Cooling',
    heating: 'Heating',
    fireAlarm: 'Fire Alarm',
    gym: 'Gym',
    balcony: 'Balcony',
    modernKitchen: 'Modern Kitchen',
    pool: 'Pool',
    elevator: 'Elevator',
    petFriendly: 'Pet Friendly',
    storage: 'Storage',
    laundry: 'Laundry',
    dishWasher: 'Dish Washer',
  }
  const [features, setFeatures] = useState({
    cooling: false,
    heating: false,
    fireAlarm: false,
    gym: false,
    balcony: false,
    modernKitchen: false,
    pool: false,
    elevator: false,
    petFriendly: false,
    storage: false,
    laundry: false,
    dishWasher: false,
  });

  const { success, error, loading, assetFeatures } = useSelector((state: RootState) => state.assetEditor);

  console.log('AssetFeatures: Selected Asset:', assetFeatures);


  useEffect(() => {
    const valueFromState = {
        cooling: assetFeatures.cooling,
        heating: false,
        fireAlarm: assetFeatures.fireAlarm,
        gym: assetFeatures.gym,
        balcony: assetFeatures.balcony,
        modernKitchen: assetFeatures.modernKitchen,
        pool: assetFeatures.pool,
        elevator: assetFeatures.elevator,
        petFriendly: assetFeatures.petFriendly,
        storage: assetFeatures.storage,
        laundry: assetFeatures.laundry,
        dishWasher: assetFeatures.dishWasher,
      }
      setFeatures(valueFromState);
  }, [assetFeatures]);

  useEffect(() => {
    dispatch(getFeaturesRequest(selectedAsset._id));
  }, [dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [name]: checked,
    }));
  };

  const handleSubmit = () => {
    const _id = selectedAsset._id;
    const assetID = selectedAsset.assetID;
    console.log('Saving features:', features);
    // Dispatch an action to save the features (implement the saveFeaturesRequest action in your Redux slice)
    dispatch(saveFeaturesRequest({ assetFeatures: {...features, assetID: _id}, assetID, _id }));
  };

  return (
    <div className={styles.assetFeaturesContainer}>
      <h2 className={styles.heading}>Asset Features</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <form>
        {Object.keys(features).map((feature) => (
          <div key={feature} className={styles.formGroup}>
            <label>
              <input
                type="checkbox"
                name={feature}
                checked={features[feature as keyof typeof features]}
                onChange={handleChange}
              />
              {featureDisplay[feature].replace(/([A-Z])/g, ' $1')}
            </label>
          </div>
        ))}
        <div className={styles.buttonContainer}>
          <button type="button" onClick={handleSubmit}>
            Save Features
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssetFeatures;

// src/components/UpdateTokenPrice/UpdateTokenPrice.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValuationByAssetIDRequest } from '../../../../features/assetValuation/assetValuationSlice';
import { getTokenPriceHistoryRequest, updateTokenPriceRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import styles from './UpdateTokenPrice.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface UpdateTokenPriceProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const UpdateTokenPrice: React.FC<UpdateTokenPriceProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  
  const { assetValuationList, error: valuationsError } = useSelector((state: RootState) => state.valuation);
  const { tokenPriceHistory, loading: tokenizationsLoading, error: tokenizationsError } = useSelector((state: RootState) => state.assetEditor);

  console.log('assetValuationList:', assetValuationList);
  console.log('tokenPriceHistory:', tokenPriceHistory);
  
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if (selectedAsset) {
      // Fetch asset valuations
      dispatch(getValuationByAssetIDRequest(selectedAsset._id));

      // Fetch asset tokenizations
      dispatch(getTokenPriceHistoryRequest(selectedAsset._id));
    }
  }, [dispatch, selectedAsset]);

  useEffect(() => {
    if (assetValuationList && tokenPriceHistory) {
      const comparisonData = assetValuationList.map((valuation: any) => {
        console.log('valuation:', valuation);
        const tokenization = tokenPriceHistory.find((token: any) => token.assetValuationID === valuation._id);
        console.log('tokenization:', tokenization);
        return {
          ...valuation,
          tokenPrice: tokenization ? tokenization.tokenLaunchingPrice : 'N/A',
          tokenCount: tokenization ? tokenization.totalNumberOfTokens : 'N/A',
          tokenizationDate: tokenization ? tokenization.priceSetDate : 'N/A',
        };
      });

      setFilteredData(comparisonData);
    }
  }, [assetValuationList, tokenPriceHistory]);

  const handleUpdateTokenPrice = (assetValuationID: string, valuationAmount: number, totalNumberOfTokens: number) => {
    console.log('assetValuationID:', assetValuationID);
    console.log('selectedAsset._id:', selectedAsset._id);
    
  
    dispatch(updateTokenPriceRequest({ assetID: selectedAsset._id, assetValuationId: assetValuationID }));
  };

  return (
    <div className={styles.updateTokenPriceContainer}>
      <h2 className={styles.heading}>Update Token Price</h2>

      <NotificationBanner success={false} error={valuationsError || tokenizationsError} loading={tokenizationsLoading} />

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Valuation Date</th>
              <th>Valuation Amount</th>
              <th>Token Price</th>
              <th>Token Count</th>
              <th>Tokenization Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item._id}>
                <td>{new Date(item.valuationDate).toLocaleDateString()}</td>
                <td>${item.valuationAmount}</td>
                <td>{item.tokenPrice !== 'N/A' ? `$${item.tokenPrice}` : 'N/A'}</td>
                <td>{item.tokenCount !== 'N/A' ? item.tokenCount : 'N/A'}</td>
                <td>{item.tokenizationDate !== 'N/A' ? new Date(item.tokenizationDate).toLocaleDateString() : 'N/A'}</td>
                <td>
                  {item.tokenPrice === 'N/A' && (
                    <button
                      className={styles.updateButton}
                      onClick={() => handleUpdateTokenPrice(
                        item._id, item.valuationAmount, item.totalNumberOfTokens,)}
                    >
                      Update Token Price
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpdateTokenPrice;

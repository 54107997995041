// src/components/AssetDetailEditor/FinancialDetails.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveFinancialDetailsRequest, getFinancialDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

import styles from './FinancialDetails.module.css';

interface FinancialDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const FinancialDetails: React.FC<FinancialDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  console.log('FinancialDetails: Selected Asset:', selectedAsset);
  useSelector((state: RootState) => {
    console.log('FinancialDetails: Token Details:', state.assetEditor);
    return state.assetEditor.financialDetails;
  });

  const {
    monthlyGrossIncome,
    propertyManagementCostPerMonth,
    platformFeePerMonth,
    maintenanceExpensePerMonth,
    propertyTaxesPerMonth,
    insurancePerMonth,
    utilitiesPerMonth,
  } = useSelector((state: RootState) => state.assetEditor.financialDetails);
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const [grossIncome, setGrossIncome] = useState<number>(0);
  const [managementCost, setManagementCost] = useState<number>(0);
  const [platformFee, setPlatformFee] = useState<number>(0);
  const [maintenanceExpense, setMaintenanceExpense] = useState<number>(0);
  const [taxes, setTaxes] = useState<number>(0);
  const [insurance, setInsurance] = useState<number>(0);
  const [utilities, setUtilities] = useState<number>(0);

  useEffect(() => {
    dispatch(getFinancialDetailsRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    setGrossIncome(monthlyGrossIncome);
    setManagementCost(propertyManagementCostPerMonth);
    setPlatformFee(platformFeePerMonth);
    setMaintenanceExpense(maintenanceExpensePerMonth);
    setTaxes(propertyTaxesPerMonth);
    setInsurance(insurancePerMonth);
    setUtilities(utilitiesPerMonth);
  }, [
    monthlyGrossIncome,
    propertyManagementCostPerMonth,
    platformFeePerMonth,
    maintenanceExpensePerMonth,
    propertyTaxesPerMonth,
    insurancePerMonth,
    utilitiesPerMonth,
  ]);

  const handleSave = () => {
    const financialDetails = {
      monthlyGrossIncome: grossIncome,
      propertyManagementCostPerMonth: managementCost,
      platformFeePerMonth: platformFee,
      maintenanceExpensePerMonth: maintenanceExpense,
      propertyTaxesPerMonth: taxes,
      insurancePerMonth: insurance,
      utilitiesPerMonth: utilities,
      assetID: selectedAsset._id,
    };
    const _id = selectedAsset._id;
    const assetID = selectedAsset._id;
    console.log('Saving financial details:', financialDetails);
    dispatch(saveFinancialDetailsRequest({ financialDetails, assetID, _id }));
  };

  const yearlyGrossIncome = grossIncome * 12;

  return (
    <div className={styles.financialDetailsContainer}>
      <h2 className={styles.heading}>Financial Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.formGroup}>
        <label>Monthly Gross Income:</label>
        <input type="number" value={grossIncome} onChange={(e) => setGrossIncome(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Yearly Gross Income:</label>
        <input type="number" className={styles.automatedInput}  value={yearlyGrossIncome} readOnly />
      </div>

      <div className={styles.formGroup}>
        <label>Property Management Cost Per Month:</label>
        <input type="number" value={managementCost} onChange={(e) => setManagementCost(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Platform Fee Per Month:</label>
        <input type="number" value={platformFee} onChange={(e) => setPlatformFee(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Maintenance Expense Per Month:</label>
        <input type="number" value={maintenanceExpense} onChange={(e) => setMaintenanceExpense(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Property Taxes Per Month:</label>
        <input type="number" value={taxes} onChange={(e) => setTaxes(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Insurance Per Month:</label>
        <input type="number" value={insurance} onChange={(e) => setInsurance(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Utilities Per Month:</label>
        <input type="number" value={utilities} onChange={(e) => setUtilities(Number(e.target.value))} />
      </div>

      
        <div className={styles.formGroup}>
            <label>Net Monthly Income:</label>
            <input type="number" className={styles.automatedInput} value={grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities} readOnly />
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Income:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) * 12} readOnly /> 
        </div>

        <div className={styles.formGroup}>  
            <label>Net Monthly ROI:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee + maintenanceExpense + taxes + insurance + utilities)} readOnly />    
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly ROI:</label>
            <input type="number" className={styles.automatedInput}  value={((grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee + maintenanceExpense + taxes + insurance + utilities)) * 12} readOnly /> 
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Cash Flow:</label>
            <input type="number" className={styles.automatedInput}  value={grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities} readOnly />        
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Cash Flow:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) * 12} readOnly /> 
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Cash on Cash ROI:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)} readOnly /> 
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Cash on Cash ROI:</label>
            <input type="number" className={styles.automatedInput}  value={((grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)) * 12} readOnly />      
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Operating Expense Ratio:</label>
            <input type="number" className={styles.automatedInput}  value={(managementCost + platformFee + maintenanceExpense + taxes + insurance + utilities) / grossIncome} readOnly />      
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Operating Expense Ratio:</label>
            <input type="number" className={styles.automatedInput}  value={((managementCost + platformFee + maintenanceExpense + taxes + insurance + utilities) / grossIncome) * 12} readOnly />
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Debt Service Coverage Ratio:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)} readOnly /> 
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Debt Service Coverage Ratio:</label>
            <input type="number" className={styles.automatedInput}  value={((grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)) * 12} readOnly />  
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Break Even Ratio:</label>
            <input type="number" className={styles.automatedInput}  value={(managementCost + platformFee + maintenanceExpense + taxes + insurance + utilities) / grossIncome} readOnly />  
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Break Even Ratio:</label>
            <input type="number" className={styles.automatedInput}  value={((managementCost + platformFee + maintenanceExpense + taxes + insurance + utilities) / grossIncome) * 12} readOnly />       
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Return on Equity:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)} readOnly />
        </div>

        <div className={styles.formGroup}>
            <label>Net Yearly Return on Equity:</label>
            <input type="number" className={styles.automatedInput}  value={((grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)) * 12} readOnly />  
        </div>

        <div className={styles.formGroup}>
            <label>Net Monthly Return on Investment:</label>
            <input type="number" className={styles.automatedInput}  value={(grossIncome - managementCost - platformFee - maintenanceExpense - taxes - insurance - utilities) / (managementCost + platformFee)} readOnly /> 
        </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default FinancialDetails;

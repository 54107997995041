// src/features/assetStages/assetStagesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssetStagesState {
  stage: string;
  stageDetails: any;
  loadingStages: boolean;
  stagesError: string | null;
}

const initialState: AssetStagesState = {
  stage: 'Introductory',
  stageDetails: {},
  loadingStages: false,
  stagesError: null,
};

const assetStagesSlice = createSlice({
  name: 'assetStages',
  initialState,
  reducers: {
    getAssetStagesRequest: (state, action: PayloadAction<string>) => {
      console.log("getAssetStagesRequest action:", action);
      state.loadingStages = true;
      state.stagesError = null;
    },
    getAssetStagesSuccess: (state, action: PayloadAction<any>) => {
      console.log("getAssetStagesSuccess action:", action);
      state.loadingStages = false;
      state.stageDetails = action.payload;
      // state.stage = action.payload;
    },
    getAssetStagesFailure: (state, action: PayloadAction<string>) => {
      state.loadingStages = false;
      state.stagesError = action.payload;
    },
    createOrUpdateAssetStagesRequest: (state, action: PayloadAction<{ assetID: string; stage: string }>) => {
        state.loadingStages = true;
        state.stagesError = null;
      },
      createOrUpdateAssetStagesSuccess: (state, action: PayloadAction<string>) => {
        state.loadingStages = false;
        state.stage = action.payload;
      },
      createOrUpdateAssetStagesFailure: (state, action: PayloadAction<string>) => {
        state.loadingStages = false;
        state.stagesError = action.payload;
      },
  },
});

export const {
  getAssetStagesRequest,
  getAssetStagesSuccess,
  getAssetStagesFailure,
  createOrUpdateAssetStagesRequest,
  createOrUpdateAssetStagesSuccess,
  createOrUpdateAssetStagesFailure,
} = assetStagesSlice.actions;

export default assetStagesSlice.reducer;

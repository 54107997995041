// src/sagas/assetSettingsSaga.ts

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchAssetSettingsRequest,
  fetchAssetSettingsSuccess,
  fetchAssetSettingsFailure,
  addOrUpdateAssetSettingsRequest,
  addOrUpdateAssetSettingsSuccess,
  addOrUpdateAssetSettingsFailure,
} from './assetSettingsSlice';
import {
  getAssetSettingsByAssetID,
  addOrUpdateAssetSettings,
} from './assetSettingsApi';

function* handleFetchAssetSettings(action: ReturnType<typeof fetchAssetSettingsRequest>): Generator<any, void, any> {
  try {
    const settings = yield call(getAssetSettingsByAssetID, action.payload);
    yield put(fetchAssetSettingsSuccess(settings));
  } catch (error: any) {
    yield put(fetchAssetSettingsFailure(error.message || 'Failed to fetch asset settings.'));
  }
}

function* handleAddOrUpdateAssetSettings(action: ReturnType<typeof addOrUpdateAssetSettingsRequest>): Generator<any, void, any> {
  try {
    const { assetID, settings } = action.payload;
    const updatedSettings = yield call(addOrUpdateAssetSettings, assetID, settings);
    yield put(addOrUpdateAssetSettingsSuccess(updatedSettings));
  } catch (error: any) {
    yield put(addOrUpdateAssetSettingsFailure(error.message || 'Failed to update asset settings.'));
  }
}

export default function* watchAssetSettingsSaga() {
  yield takeLatest(fetchAssetSettingsRequest.type, handleFetchAssetSettings);
  yield takeLatest(addOrUpdateAssetSettingsRequest.type, handleAddOrUpdateAssetSettings);
}

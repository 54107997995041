import React, { useEffect, useState } from 'react';
import styles from './AssetTokenization.module.css';
import { RootState } from '../../../../store/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTokenPriceRequest, getInitialTokenPriceRequest, getPurchasePriceRequest, 
  getTokenPriceHistoryRequest, setTokenPriceRequest } from '../../../../features/assetEditor/assetEditorSlice';
import {fetchInitialValuationRequest } from '../../../../features/assetValuation/assetValuationSlice';
  import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface AssetTokenizationProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const AssetTokenization: React.FC<AssetTokenizationProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const [tokenLaunchingPrice, setTokenLaunchingPrice] = useState<number>(0);
  const [totalNumberOfTokens, setTotalNumberOfTokens] = useState<number>(0);
  const [valuationAmount, setValuationAmount] = useState<number>(0);
  const [initialValuationId, setInitialValuationId] = useState<string>('');
  const [initialTokenPriceAlreadySet, setInitialTokenPriceAlreadySet] = useState<boolean>(false);

  const { success, 
    error, 
    loading, 
    purchasePrice, 
    assetTokenization, 
    initialTokenPrice, 
    // activeTokenPrice 
  } = useSelector((state: RootState) => state.assetEditor);

  const { initialValuation } = useSelector((state: RootState) => state.valuation);

  console.log('initialTokenPrice:', initialTokenPrice);
  // console.log('activeTokenPrice:', activeTokenPrice);  
  console.log('initialValuation:', initialValuation);


  useEffect(() => {
    if (selectedAsset._id) {
      // dispatch(getPurchasePriceRequest(selectedAsset._id)); // Dispatch action to get purchase price
      // dispatch(getActiveTokenPriceRequest(selectedAsset._id));
      dispatch(getInitialTokenPriceRequest(selectedAsset._id));
      // dispatch(getTokenPriceHistoryRequest(selectedAsset._id));
      dispatch(fetchInitialValuationRequest(selectedAsset._id));
    }
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    if (initialValuation) {
      console.log('initialValuation in useEffect :', initialValuation);
      console.log(initialValuation._id)
      setValuationAmount(initialValuation.valuationAmount);
      setInitialValuationId(initialValuation._id);
      setTokenLaunchingPrice(50);
    }
  }, [dispatch, initialValuation]);

  useEffect(() => {
    if (tokenLaunchingPrice) {
      const _totalNumberOfTokens = (Number(valuationAmount) / Number(tokenLaunchingPrice));
      setTotalNumberOfTokens(_totalNumberOfTokens);
    }
  }, [dispatch, tokenLaunchingPrice]);

  useEffect(() => {
    console.log('useEffect initialTokenPrice:', initialTokenPrice);
    if (initialTokenPrice) {
      setTokenLaunchingPrice(initialTokenPrice.tokenLaunchingPrice);
      setTotalNumberOfTokens(initialTokenPrice.totalNumberOfTokens);
      if (initialTokenPrice.totalNumberOfTokens > 0) {
        setInitialTokenPriceAlreadySet(true);
      }
    }
  }, [dispatch, initialTokenPrice]);

  const handleTokenLaunchingPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _tokenLaunchingPrice = (Number(valuationAmount) / Number(e.target.value));
    setTokenLaunchingPrice(e.target.value ? Number(e.target.value) : 0);
    setTotalNumberOfTokens(_tokenLaunchingPrice);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle the tokenization process
    console.log('Tokenizing asset with:', {
      valuationAmount,
      tokenLaunchingPrice,
      totalNumberOfTokens,
      initialValuationId,
    });
    const tokenDetails = {
      tokenLaunchingPrice,
      totalNumberOfTokens,
      tokenType: 'Launch',
    };
    dispatch(setTokenPriceRequest({ assetID: selectedAsset._id, assetTokenDetails: tokenDetails, 
      purchasePrice: valuationAmount, initialValuationId }));
  };

  return (
    <div className={styles.assetTokenization}>
      <h2>Asset Tokenization</h2>
      <NotificationBanner success={success} error={error} loading={loading} />
      <form onSubmit={handleSubmit} className={styles.tokenizationForm}>
        <div className={styles.formGroup}>
          <div className={styles.label}>Asset Purchase Price:</div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              value={valuationAmount?.toString()}
              readOnly
              className={styles.readOnlyInput}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.label}>Token Launching Price:</div>
          <div className={styles.inputContainer}>
            <input
              type="number"
              value={tokenLaunchingPrice}
              onChange={handleTokenLaunchingPriceChange}
              readOnly
              placeholder="Enter token launching price"
              className={styles.readOnlyInput}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.label}>Total Number of Tokens:</div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              value={totalNumberOfTokens}
              readOnly
              className={styles.readOnlyInput}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button type="submit" className={styles.submitButton} disabled={initialTokenPriceAlreadySet}>
            Tokenize the Asset
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssetTokenization;




const AdminUsers = () => {
    return (
        <div>
        <h1>Admin Users</h1>
        </div>
    );
    }

export default AdminUsers;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssetSettingsState {
  settings: any | null;
  loading: boolean;
  error: string | null;
}

const initialState: AssetSettingsState = {
  settings: null,
  loading: false,
  error: null,
};

const assetSettingsSlice = createSlice({
  name: 'assetSettings',
  initialState,
  reducers: {
    fetchAssetSettingsRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    fetchAssetSettingsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.settings = action.payload;
    },
    fetchAssetSettingsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addOrUpdateAssetSettingsRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
      state.error = null;
    },
    addOrUpdateAssetSettingsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.settings = action.payload;
    },
    addOrUpdateAssetSettingsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchAssetSettingsRequest,
  fetchAssetSettingsSuccess,
  fetchAssetSettingsFailure,
  addOrUpdateAssetSettingsRequest,
  addOrUpdateAssetSettingsSuccess,
  addOrUpdateAssetSettingsFailure,
} = assetSettingsSlice.actions;

export default assetSettingsSlice.reducer;

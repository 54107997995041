// src/features/assetStages/assetStagesSaga.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { getAssetStagesApi, createOrUpdateAssetStagesApi } from './assetStagesApi';
import {
  getAssetStagesRequest,
  getAssetStagesSuccess,
  getAssetStagesFailure,
  createOrUpdateAssetStagesRequest,
  createOrUpdateAssetStagesSuccess,
  createOrUpdateAssetStagesFailure,
} from './assetStagesSlice';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchAssetStagesSaga(action: PayloadAction<string>): Generator<any, void, any> {
  try {
    console.log("fetchAssetStagesSaga action:", action);
    console.log("fetchAssetStagesSaga action.payload:", action.payload);
    const assetID = action.payload;
    console.log("fetchAssetStagesSaga assetID:", assetID);
    const response = yield call(getAssetStagesApi, assetID);
    console.log("fetchAssetStagesSaga response:", response);
    yield put(getAssetStagesSuccess(response.data));
  } catch (error) {
    yield put(getAssetStagesFailure("Failed to fetch stages"));
  }
}

function* createOrUpdateAssetStagesSaga(
    action: PayloadAction<{ assetID: string; stage: string; 
      preBookingStartDate: string; saleStartDate:string; saleEndDate: string }>
  ): Generator<any, void, any> {
    try {
      console.log('createOrUpdateAssetStagesSaga action:', action);
      const assetStageData = action.payload;
      const response = yield call(createOrUpdateAssetStagesApi, assetStageData);
      yield put(createOrUpdateAssetStagesSuccess(response.data.stage));
    } catch (error) {
      yield put(createOrUpdateAssetStagesFailure('Failed to create or update stages'));
    }
  }
  

export default function* assetStagesSaga() {
  yield takeLatest(getAssetStagesRequest.type, fetchAssetStagesSaga);
  yield takeLatest(createOrUpdateAssetStagesRequest.type, createOrUpdateAssetStagesSaga);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WaitingListEntry {
  emailId: string;
  status: string; //approvalStatus
  registrationStatus: string;
  joinedAt: string;
  approvalStatus?: string;
    approvedAt?: string;
}

interface WaitingListState {
  entries: WaitingListEntry[];
  loading: boolean;
  successMessage: string | null;
  error: string | null;
}

const initialState: WaitingListState = {
    entries: [],
    loading: false,
    error: null,
    successMessage: null
};

const waitingListSlice = createSlice({
  name: 'waitingList',
  initialState,
  reducers: {
    fetchWaitingListRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchWaitingListSuccess: (state, action: PayloadAction<WaitingListEntry[]>) => {
      state.loading = false;
      state.entries = action.payload;
    },
    fetchWaitingListFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Approve waiting list request actions
    approveWaitingListRequest: (state, action: PayloadAction<{ emailId: string }>) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      },
      approveWaitingListSuccess: (state, action: PayloadAction<WaitingListEntry>) => {
        state.loading = false;
        state.successMessage = 'Waiting list entry approved successfully.';
        // Update the approved entry in the list if it exists
        const approvedEntryIndex = state.entries.findIndex(e => e.emailId === action.payload.emailId);
        if (approvedEntryIndex !== -1) {
          state.entries[approvedEntryIndex] = action.payload;
        } else {
          // If the entry wasn't previously in the list, you can add it or ignore
          state.entries.push(action.payload);
        }
      },
      approveWaitingListFailure: (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      },
  
  },
});

export const {
  fetchWaitingListRequest,
  fetchWaitingListSuccess,
  fetchWaitingListFailure,
  approveWaitingListRequest,
  approveWaitingListSuccess,
  approveWaitingListFailure,
} = waitingListSlice.actions;

export default waitingListSlice.reducer;

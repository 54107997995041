

const AddOffers = () => {

    return( 
        <div>
            <h1>Add Offers</h1>
        </div>
    )
}

export default AddOffers;
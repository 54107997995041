// src/components/AssetDetailEditor/AssetLocation.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCity, faMap, faFlag, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from './AssetLocation.module.css';

interface Location {
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface AssetLocationProps {
  location: Location;
}

const AssetLocation: React.FC<AssetLocationProps> = ({ location }) => {
  return (
    <div className={styles.locationContainer}>
      <h2 className={styles.locationHeading}>Location</h2>
      <div className={styles.locationDetails}>
        <div className={styles.locationItem}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.locationIcon} />
          <span className={styles.locationText}>Address: {location.address}</span>
        </div>
        <div className={styles.locationItem}>
          <FontAwesomeIcon icon={faCity} className={styles.locationIcon} />
          <span className={styles.locationText}>City: {location.city}</span>
        </div>
        <div className={styles.locationItem}>
          <FontAwesomeIcon icon={faMap} className={styles.locationIcon} />
          <span className={styles.locationText}>State: {location.state}</span>
        </div>
        <div className={styles.locationItem}>
          <FontAwesomeIcon icon={faFlag} className={styles.locationIcon} />
          <span className={styles.locationText}>Country: {location.country}</span>
        </div>
        <div className={styles.locationItem}>
          <FontAwesomeIcon icon={faEnvelope} className={styles.locationIcon} />
          <span className={styles.locationText}>Zip/Postal Code: {location.zip}</span>
        </div>
      </div>
    </div>
  );
};

export default AssetLocation;

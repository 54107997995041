// src/components/AssetDetailEditor/UserPurchases.tsx
import React, { useState } from 'react';
import styles from './UserPurchases.module.css';

interface PurchaseData {
  purchaseDate: string;
  purchaseUser: string;
  purchaseTokenCount: number;
  purchaseCost: string;
  purchaseStatus: string;
}

const purchaseData: PurchaseData[] = [
  {
    purchaseDate: '2024-06-01',
    purchaseUser: 'John Doe',
    purchaseTokenCount: 100,
    purchaseCost: '$5000.00',
    purchaseStatus: 'Completed',
  },
  {
    purchaseDate: '2024-06-15',
    purchaseUser: 'Jane Smith',
    purchaseTokenCount: 50,
    purchaseCost: '$2500.00',
    purchaseStatus: 'Pending',
  },
  // Add more data as needed
];

const UserPurchases: React.FC = () => {
  const [data, setData] = useState(purchaseData);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);
  const [filters, setFilters] = useState({ purchaseDate: '', purchaseUser: '', purchaseStatus: '' });

  const sortData = (key: string) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    const sortedData = [...data].sort((a, b) => {
      if (a[key as keyof PurchaseData] < b[key as keyof PurchaseData]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key as keyof PurchaseData] > b[key as keyof PurchaseData]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setData(sortedData);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const filteredData = data.filter((purchase) =>
    Object.keys(filters).every(
      (key) =>
        purchase[key as keyof PurchaseData]
          .toString()
          .toLowerCase()
          .includes(filters[key as keyof typeof filters].toLowerCase())
    )
  );

  return (
    <div className={styles.purchasesContainer}>
      <h2 className={styles.purchasesHeading}>User Purchases</h2>
      <div className={styles.filters}>
        <input
          type="text"
          name="purchaseDate"
          placeholder="Filter by Date"
          value={filters.purchaseDate}
          onChange={handleFilterChange}
          className={styles.filterInput}
        />
        <input
          type="text"
          name="purchaseUser"
          placeholder="Filter by User"
          value={filters.purchaseUser}
          onChange={handleFilterChange}
          className={styles.filterInput}
        />
        <input
          type="text"
          name="purchaseStatus"
          placeholder="Filter by Status"
          value={filters.purchaseStatus}
          onChange={handleFilterChange}
          className={styles.filterInput}
        />
      </div>
      <table className={styles.purchasesTable}>
        <thead>
          <tr>
            <th onClick={() => sortData('purchaseDate')}>Purchase Date</th>
            <th onClick={() => sortData('purchaseUser')}>Purchase User</th>
            <th onClick={() => sortData('purchaseTokenCount')}>Purchase Token Count</th>
            <th onClick={() => sortData('purchaseCost')}>Purchase Cost</th>
            <th onClick={() => sortData('purchaseStatus')}>Purchase Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((purchase, index) => (
            <tr key={index}>
              <td>{purchase.purchaseDate}</td>
              <td>{purchase.purchaseUser}</td>
              <td>{purchase.purchaseTokenCount}</td>
              <td>{purchase.purchaseCost}</td>
              <td>{purchase.purchaseStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserPurchases;

import imageCompression from 'browser-image-compression';

export const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1, // Set a max size for the compressed image in MB
      maxWidthOrHeight: 1024, // Resize the image to fit within these dimensions
      useWebWorker: true, // Use a Web Worker for better performance
    };
  
    try {
      // Perform the compression
      const compressedFile = await imageCompression(file, options);
      return compressedFile; // Return the compressed file
    } catch (error) {
      console.error('Error compressing image:', error);
      throw error; // Rethrow the error for handling elsewhere
    }
  };

// src/components/AssetDetailEditor/AssetPage.tsx
import React from 'react';
import './AssetPage.module.css';
import AssetCarousel from './AssetCarousel';
import AssetHeader from '../AssetHeader/AssetHeader';
import AssetBasicDetails from '../AssetBasicDetails/AssetBasicDetails';
import AssetFeatures from '../AssetFeatures/AssetFeatures';
import AssetLocation from '../AssetLocation/AssetLocation';
import AssetBlockchain from '../AssetBlockchain/AssetBlockchain';
import AssetValuationSample from '../AssetValuation/AssetValuation1';
import AssetValuation from '../AssetValuation/AssetValuation';
import MyTokenPurchases from '../MyTokenPurchases/MyTokenPurchases';
import TokenOffering from '../TokenOffering/TokenOffering';
import PurchaseToken from '../PurchaseToken/PurchaseToken';
import UserPurchases from '../UserPurchases/UserPurchases';

const AssetPage: React.FC = () => {
  const asset = {
    name: "1220 Petersham Town",
    address: "Petersham town, Wardll St - Australia PA 6550",
    price: "$130,000",
    propertyId: "5331",
    images: [
      "https://via.placeholder.com/800x400",
      "https://via.placeholder.com/800x400",
      "https://via.placeholder.com/800x400",
    ],
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
    features: [
      "Center Cooling",
      "Fire Alarm",
      "Heating",
      "Gym",
      "Balcony",
      "Modern Kitchen",
      "Pool",
      "Elevator",
      "Pet Friendly",
      "Storage",
      "Laundry",
      "Dish Washer"
    ],
    location: {
      address: "1220 Petersham Town",
      city: "Sydney",
      state: "Newcastle",
      country: "Australia",
      zip: "54330"
    },
    similarProperties: [
      {
        name: "House In Kent Street",
        image: "https://via.placeholder.com/200",
        price: "$130,000",
        address: "123 Kent Street, Sydney, NSW 2000"
      },
      {
        name: "Villa In Oglesby Ave",
        image: "https://via.placeholder.com/200",
        price: "$130,000/month",
        address: "1035 Oglesby Ave, Chicago, IL 60617"
      },
      {
        name: "Apartment In Long St.",
        image: "https://via.placeholder.com/200",
        price: "$70,000",
        address: "34 Long St, Jersey City, NJ 07305"
      }
    ],
    blockChainProperties: {
      tokenName: "Lenark Token",
      tokenSymbol: "LEN",
      tokenSupply: "100,000,000",
      tokenPrice: "$0.50",
      tokenType: "ERC20",
      tokenContract: "0x1234567890abcdef1234567890abcdef12345678",
      ownerWalletAddress: "0xabcdefabcdefabcdefabcdefabcdefabcdefabcdef",
    },
    valuationProperties: [
      {
        date: '2024 Jan 22',
        daysSince: '487 days since prior valuation',
        assetValuation: '$84,000.00',
        annualChange: '+17.65%',
        totalInvestment: '$84,900.00',
        capitalROI: '+13.29%',
        tokenPrice: '$53.06',
        regDistributed: '$7,882.22',
        newPhotos: 'No new photos at this time.',
        documentLink: 'View Appraisal Document',
      },
      {
        date: '2022 Sep 22',
        daysSince: 'Initial Valuation',
        assetValuation: '$68,000.00',
        totalInvestment: '$78,816.00',
        tokenPrice: '$49.26',
        documentLink: 'Property Financials Tab',
      },
    ]
  };

  return (
    <div className="assetPageContainer">
      <AssetHeader title={asset.name} address={asset.address} price={asset.price} propertyId={asset.propertyId} />
      
      <div className="assetContent">
        <div className="assetGallery">
          <AssetCarousel images={asset.images} />
        </div>

        <div className="assetDetails">
          <AssetBasicDetails
            area="1270 sq ft"
            beds="4 Bedrooms"
            baths="2 Bathrooms"
            rooms="6 Rooms"
            floors="3 Floors"
            garage="2 Garages"
            description={asset.description}
          />
          </div>
          <AssetFeatures features={asset.features} />
          <AssetLocation location={asset.location} />
          <AssetBlockchain blockChainProperties={asset.blockChainProperties} />

          <AssetValuation  />
          <TokenOffering />
          <MyTokenPurchases />
          <PurchaseToken />
          <UserPurchases />

        
      </div>
    </div>
  );
};

export default AssetPage;

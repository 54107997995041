// src/components/AssetDetailEditor/LocationDetails.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLocationDetailsRequest, getLocationDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

import styles from './LocationDetail.module.css';

interface LocationDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string }; // Assuming Asset is the type of your selected asset
}

const LocationDetails: React.FC<LocationDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  const {
    streetAddress1: locationStreetAddress1,
    streetAddress2: locationStreetAddress2,
    city: locationCity,
    state: locationState, // renamed to avoid conflict with useState
    province:   locationProvince,
    country: locationCountry,
    zipcode: locationZipcode,
  } = useSelector((state: RootState) => state.assetEditor.addressDetails);
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const [streetAddress1, setStreetAddress1] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');

  useEffect(() => {
    dispatch(getLocationDetailsRequest(selectedAsset._id));
  }, [dispatch]);

  useEffect(() => {
    setStreetAddress1(locationStreetAddress1);
    setStreetAddress2(locationStreetAddress2);
    setCity(locationCity);
    setState(locationState);
    setProvince(locationProvince);
    setCountry(locationCountry);
    setZipcode(locationZipcode);
  }, [locationStreetAddress1, locationStreetAddress2, locationCity, locationState, locationProvince, locationCountry, locationZipcode]);

  const handleSave = () => {
    const locationDetails = { streetAddress1, streetAddress2, city, state, province, country, zipcode };
    const _id = selectedAsset._id;
    const assetID = selectedAsset.assetID;
    console.log('Saving location details:', locationDetails);
    dispatch(saveLocationDetailsRequest({ locationDetails, assetID, _id }));
  };

  return (
    <div className={styles.locationDetailContainer}>
      <h2 className={styles.heading}>Location Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.formGroup}>
        <label>Street Address 1:</label>
        <input type="text" value={streetAddress1} onChange={(e) => setStreetAddress1(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Street Address 2:</label>
        <input type="text" value={streetAddress2} onChange={(e) => setStreetAddress2(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>City:</label>
        <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>State:</label>
        <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Province:</label>
        <input type="text" value={province} onChange={(e) => setProvince(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Country:</label>
        <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Zipcode:</label>
        <input type="text" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default LocationDetails;

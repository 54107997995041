import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { createAssetRequest, selectAsset, showPublishedAssetsRequest } from '../../../../features/asset/assetSlice';
import { useNavigate } from 'react-router-dom';
import styles from './AssetManagement.module.css';
import { Link } from 'react-router-dom';
import AssetDetailEditor from '../AssetDetailEditor/AssetDetailEditor';

const AssetManagement: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success, publishedAssets, selectedAsset } = useSelector((state: RootState) => state.asset);

  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of rows per page

  const handleCreateNewAsset = () => {
    dispatch(createAssetRequest());
  };

  const handleShowpublishedAssets = () => {
    //TODO: Implement this with Published Assets
    dispatch(showPublishedAssetsRequest());
  };

  const handleSelectAsset = (asset: { assetID: string; propertyID: number; name: string, _id: string }) => {
    dispatch(selectAsset(asset));
    navigate(`/admin/asset-management/${asset.propertyID}/${asset._id}`);
    //navigate(`/admin/unpublished-editor/${asset._id}`);
  };

  const sortedAssets = React.useMemo(() => {
    let sortableAssets = [...publishedAssets];
    if (sortConfig !== null) {
      sortableAssets.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof typeof a];
        const bValue = b[sortConfig.key as keyof typeof b];
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableAssets;
  }, [publishedAssets, sortConfig]);

  const filteredAssets = React.useMemo(() => {
    if (filterStatus === null) return sortedAssets;
    return sortedAssets.filter(asset => asset.publishStatus.toString() === filterStatus);
  }, [sortedAssets, filterStatus]);

  const paginatedAssets = React.useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredAssets.slice(startIndex, endIndex);
  }, [filteredAssets, currentPage]);

  const totalPages = Math.ceil(filteredAssets.length / pageSize);

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={handleShowpublishedAssets}>
          Show Published Assets 2
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>Asset created successfully!</p>}
      <div>
        {publishedAssets?.length > 0 && !selectedAsset && (
          <div className={styles.publishedAssets}>
            <h3>Published Assets ({publishedAssets?.length})</h3>

            {/* Filtering by Publish Status */}
            <div className={styles.filterContainer}>
              <label htmlFor="publishStatusFilter">Filter by Publish Status:</label>
              <select
                id="publishStatusFilter"
                onChange={(e) => setFilterStatus(e.target.value || null)}
                value={filterStatus || ''}
              >
                <option value="">All</option>
                <option value="true">Published</option>
                <option value="false">Unpublished</option>
              </select>
            </div>

            <table className={styles.assetTable}>
              <thead>
                <tr>
                  <th>Asset External ID</th>
                  <th>Asset ID</th>
                  <th onClick={() => requestSort('propertyID')} className={styles.pointerTitle}>Property ID</th>
                  <th onClick={() => requestSort('assetTitle')} className={styles.pointerTitle}>Title</th>
                  <th onClick={() => requestSort('publishStatus')} className={styles.pointerTitle}>Publish Status</th>
                  <th>Street Address</th>
                </tr>
              </thead>
              <tbody>
                {paginatedAssets.map((asset, index) => (
                  <tr key={index} onClick={() => handleSelectAsset(asset)} className={styles.assetRow}>
                    <td>{asset.assetExternalID}</td>
                    <td>{asset._id}</td>
                    <td>{asset.propertyID}</td>
                    <td>{asset.assetTitle || 'No Title'}</td>
                    <td>{asset.publishStatus ? 'Published': 'Unpublished'}</td>
                    <td>{asset.streetAddress1 || 'No Address'}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className={styles.paginationControls}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      {/*{selectedAsset && <AssetDetailEditor />}*/}
    </div>
  );
};

export default AssetManagement;


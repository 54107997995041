import React from 'react';
import styles from './NotificationBannerNew.module.css';

interface NotificationBannerProps {
  type: 'success' | 'error';
  message: string;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({ type, message }) => {
  return (
    <div className={`${styles.notificationBanner} ${styles[type]}`}>
      {message}
    </div>
  );
};

export default NotificationBanner;

// src/components/AssetDetailEditor/MyTokenPurchases.tsx
import React, { useState } from 'react';
import styles from './MyTokenPurchases.module.css';

const purchaseData = [
  {
    purchaseDate: '2023 Mar 15',
    purchaseCount: '100',
    purchaseCostPerToken: '$50.00',
    totalPurchaseCost: '$5,000.00',
    purchaseStatus: 'Completed',
  },
  {
    purchaseDate: '2022 Aug 10',
    purchaseCount: '150',
    purchaseCostPerToken: '$45.00',
    totalPurchaseCost: '$6,750.00',
    purchaseStatus: 'Pending',
  },
];

const MyTokenPurchases: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.purchaseContainer}>
      <h2 className={styles.purchaseHeading}>My Token Purchases</h2>
      <div className={styles.accordion}>
        {purchaseData.map((purchase, index) => (
          <div key={index} className={styles.accordionItem}>
            <div
              className={styles.accordionTitle}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{purchase.purchaseDate}</h3>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className={styles.accordionContent}>
                <div className={styles.purchaseDetails}>
                  <div className={styles.purchaseItem}>
                    <div className={styles.purchaseTitle}>PURCHASE DATE</div>
                    <div>{purchase.purchaseDate}</div>
                  </div>
                  <div className={styles.purchaseItem}>
                    <div className={styles.purchaseTitle}>PURCHASE COUNT</div>
                    <div>{purchase.purchaseCount}</div>
                  </div>
                  <div className={styles.purchaseItem}>
                    <div className={styles.purchaseTitle}>COST PER TOKEN</div>
                    <div>{purchase.purchaseCostPerToken}</div>
                  </div>
                  <div className={styles.purchaseItem}>
                    <div className={styles.purchaseTitle}>TOTAL PURCHASE COST</div>
                    <div>{purchase.totalPurchaseCost}</div>
                  </div>
                  <div className={styles.purchaseItem}>
                    <div className={styles.purchaseTitle}>PURCHASE STATUS</div>
                    <div>{purchase.purchaseStatus}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyTokenPurchases;

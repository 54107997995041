import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadValuationDocumentRequest,
  uploadValuationDocumentSuccess,
  uploadValuationDocumentFailure,
} from '../../../../features/assetEditor/assetEditorSlice';
import { addValuationRequest, getValuationByAssetIDRequest } from '../../../../features/assetValuation/assetValuationSlice';
import { RootState } from '../../../../store/rootReducer';
import { uploadValuationDocumentApi } from '../../../../features/assetEditor/assetEditorApi';

import styles from './ValuationDetails.module.css';


interface ValuationDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string };
  initialValuation: boolean;
}

const ValuationDetails: React.FC<ValuationDetailsProps> = ({ selectedAsset, initialValuation }) => {
  const dispatch = useDispatch();
  const { assetValuationList, status, error } = useSelector(
    (state: RootState) => state.valuation
  );

  const [title, setTitle] = useState(initialValuation ? 'Initial Valuation' : '');
  const [date, setDate] = useState('');
  const [cost, setCost] = useState<number>(0);
  // const [totalInvestment, setTotalInvestment] = useState<number>(0);
  // const [company, setCompany] = useState('');
  const [annualChange, setAnnualChange] = useState<number>(0);
  const [capitalROI, setCapitalROI] = useState<number>(0);
  // const [tokenPrice, setTokenPrice] = useState<number>(0);
  // const [totalTokens, setTotalTokens] = useState<number>(0);
  const [valuationCompany, setValuationCompany] = useState('');
  const [documentName, setDocumentName] = useState('assetValuation.pdf');
  const [document, setDocument] = useState<File>(new File([""], "filename"));

  useEffect(() => {
    dispatch(getValuationByAssetIDRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    if (title !== "Initial Valuation" && cost > 0 && assetValuationList.length > 0) {
      const lastValuation = assetValuationList[assetValuationList.length - 1];
      const previousCost = lastValuation.valuationAmount;
      const firstValuation = assetValuationList[0];
      const firstInvestment =  firstValuation.valuationAmount; //firstValuation.totalInvestment || 0;

      if (previousCost > 0 && firstInvestment > 0) {
        const calculatedAnnualChange = ((cost - previousCost) / previousCost) * 100;
        const calculatedCapitalROI = ((cost - firstInvestment) / firstInvestment) * 100;

        console.log('calculatedAnnualChange', calculatedAnnualChange);
        console.log('calculatedCapitalROI', calculatedCapitalROI);

        setAnnualChange(Math.round(calculatedAnnualChange*100) / 100);
        setCapitalROI(Math.round(calculatedCapitalROI*100) / 100);
      }
    }

    // if (title === "Initial Valuation" && tokenPrice > 0 && totalInvestment > 0) {
    //   const calculatedTotalTokens = totalInvestment / tokenPrice;
    //   setTotalTokens(calculatedTotalTokens);
    // }

    if (title !== "Initial Valuation" && cost > 0 && assetValuationList.length > 0) {
      const lastValuation = assetValuationList[assetValuationList.length - 1];
      // const lastTotalTokens = lastValuation.totalTokens || 0;

      const initalValuation = assetValuationList[0];
      const initialCost = initalValuation.valuationAmount;
      // const initialInvestment = initalValuation.totalInvestment || 0;
      // const initialExtras = initialInvestment - initialCost;

      // if (lastTotalTokens > 0) {
      //   const calculatedTokenPrice = (cost + initialExtras) / lastTotalTokens;
      //   setTokenPrice(Math.round(calculatedTokenPrice * 100) / 100);
      //   setTotalTokens(lastTotalTokens);
      // }
    }
  }, [title, cost, assetValuationList]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
       setDocument(e.target.files[0]);
    }
  };

  const handleSave = () => {
    const valuationDetails = {
      valuationTitle: title,
      valuationAmount: cost,
      // totalInvestment: totalInvestment,
      valuationDate: date,
      valuationCompany: valuationCompany,
      annualChange: annualChange,
      capitalRoi: capitalROI,
      // tokenPrice: tokenPrice,
      // totalTokens: totalTokens,
      valuationDocumentName: documentName
    };
    dispatch(addValuationRequest({ assetValuation: valuationDetails, assetID: selectedAsset._id, _id: selectedAsset._id }));
  };
  
  const handleSaveDoc = async () => {
   dispatch(uploadValuationDocumentRequest());
   const formData = new FormData();
    formData.append('file',document)
    formData.append('valuationTitle', title)
    formData.append('valuationAmount',cost.toString())
    formData.append('valuationDate',date)
    formData.append('valuationCompany',valuationCompany)
    formData.append('annualChange',annualChange.toString())
    formData.append('annualChange',capitalROI.toString())
    formData.append('documentName',documentName)

    try {
      await uploadValuationDocumentApi(
        formData,selectedAsset._id
      );
      dispatch(uploadValuationDocumentSuccess());
    } catch (error) {
      dispatch(uploadValuationDocumentFailure('Error while uploading valuation document'));
      console.error('Error saving document:', error);
    }
  };

  const valuationTitles = ["Initial Valuation", "Quarterly Valuation", "Half Yearly Valuation", "Yearly Valuation", "Random Valuation"];
  
  const filteredTitles = valuationTitles.filter(title => 
    !assetValuationList.some(detail => detail.valuationTitle === title)
  );

  return (
    <div className={styles.valuationDetailsContainer}>
      <h2 className={styles.heading}>Valuation Details</h2>
      {/*<NotificationBanner success={success} error={error} loading={loading} />*/}

      <div className={styles.tableContainer}>
        <h3>Valuation Details Summary</h3>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div className={styles.tableHeader}>Valuation Title</div>
            <div className={styles.tableHeader}>Valuation Date</div>
            <div className={styles.tableHeader}>Valuation Cost</div>
            {/* <div className={styles.tableHeader}>Total Investment</div> */}
            <div className={styles.tableHeader}>Annual Change (in %)</div>
            <div className={styles.tableHeader}>Capital ROI (in %)</div>
            {/* <div className={styles.tableHeader}>Token Price</div>
            <div className={styles.tableHeader}>Total Tokens</div> */}
            <div className={styles.tableHeader}>Valuation Company Name</div>
            <div className={styles.tableHeader}>Document Name</div>
            <div className={styles.tableHeader}>Document Link</div>
          </div>
          {assetValuationList.map((detail: any) => (
            <div key={detail._id} className={styles.tableRow}>
              <div className={styles.tableCell}>{detail.valuationTitle}</div>
              <div className={styles.tableCell}>{new Date(detail.valuationDate).toLocaleDateString()}</div>
              <div className={styles.tableCell}>{detail.valuationAmount}</div>
              {/* <div className={styles.tableCell}>{detail.totalInvestment}</div> */}
              <div className={styles.tableCell}>{detail.annualChange}</div>
              <div className={styles.tableCell}>{detail.capitalRoi}</div>
              {/* <div className={styles.tableCell}>{detail.tokenPrice}</div>
              <div className={styles.tableCell}>{detail.totalTokens}</div> */}
              <div className={styles.tableCell}>{detail.valuationCompany}</div>
              <div className={styles.tableCell}>{detail.valuationDocumentName}</div>
              <div className={styles.tableCell}>
                <a href={detail.documentLink}>{detail.documentLink}</a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <h2 className={styles.heading}>Add New Valuation</h2>

      <div className={styles.formGroup}>
      <label>Valuation Title:</label>
        <select
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={initialValuation} // Disable title selection if it's the initial valuation
        >
          {initialValuation ? (
            <option value="Initial Valuation">Initial Valuation</option>
          ) : (
            <>
              <option value="">Select Valuation Title</option>
              {filteredTitles.map((title) => (
                <option key={title} value={title}>
                  {title}
                </option>
              ))}
            </>
          )}
        </select>
      </div>

      <div className={styles.formGroup}>
        <label>Valuation Date:</label>
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Asset Price:</label>
        <input type="number" value={cost} onChange={(e) => setCost(Number(e.target.value))} />
      </div>

      {/* <div className={styles.formGroup}>
        <label>Total Investment:</label>
        <input
          type="number"
          value={totalInvestment}
          onChange={(e) => setTotalInvestment(Number(e.target.value))}
          readOnly={title !== 'Initial Valuation'}
        />
      </div> */}

      {!initialValuation && (
        <>
          <div className={styles.formGroup}>
            <label>Annual Change (in %):</label>
            <input
              type="text"
              value={annualChange}
              onChange={(e) => setAnnualChange(Number(e.target.value))}
              disabled={title === 'Initial Valuation'}
            />
          </div>

          <div className={styles.formGroup}>
          <label>Capital ROI (in %):</label>
          <input
            type="text"
            value={capitalROI}
            onChange={(e) => setCapitalROI(Number(e.target.value))}
            disabled={title === 'Initial Valuation'}
          />
          </div>
        </>
      )}


      {/* <div className={styles.formGroup}>
        <label>Token Price:</label>
        <input type="text" value={tokenPrice} onChange={(e) => setTokenPrice(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Total Tokens:</label>
        <input type="text" value={totalTokens} readOnly />
      </div> */}

      <div className={styles.formGroup}>
        <label>Valuation Company:</label>
        <input type="text" value={valuationCompany} onChange={(e) => setValuationCompany(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Valuation Document Name:</label>
        <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Valuation Document:</label>
        <input type="file" accept=".pdf" onChange={handleFileChange} />
      </div>

      <div className={styles.buttonContainer}>
        {/* <button onClick={handleSave}>Save Details</button>
        <button onClick={handleSaveDoc}>Save Document</button> */}
        <button onClick={handleSaveDoc}>Submit</button>
      </div>
    </div>
  );
};

export default ValuationDetails;

// src/api/assetSettingsApi.ts

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl;

// API function to add or update asset settings
export const addOrUpdateAssetSettings = async (assetID: string, settings: any) => {
  const response = await axios.post(`${API_BASE_URL}/asset-settings/${assetID}`, settings);
  return response.data;
};

// API function to fetch asset settings by asset ID
export const getAssetSettingsByAssetID = async (assetID: string) => {
  const response = await axios.get(`${API_BASE_URL}/asset-settings/${assetID}`);
  return response.data;
};

// src/features/assetStages/assetStagesApi.ts
import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;

const API_BASE_URL = apiUrl;

export const getAssetStagesApi = async (assetID: string) => {
  try {
    console.log('getAssetStagesApi assetID:', assetID);
    const response = await axios.get(`${API_BASE_URL}/asset-stages/${assetID}`);
    console.log('getAssetStagesApi response:', response);
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch stages for assetID: ${assetID}`);
  }
};

export const createOrUpdateAssetStagesApi = async (assetStageData: {
    assetID: string;
    stage: string;
    preBookingStartDate: string;
    saleStartDate: string;
    saleEndDate: string;
  }) => {
    try {
      console.log('createOrUpdateAssetStagesApi createDto:', assetStageData);
      const response = await axios.post(`${API_BASE_URL}/asset-stages`, assetStageData);
      return response;
    } catch (error) {
      throw new Error(`Failed to create or update stages for assetID: ${assetStageData.assetID}`);
    }
  };
// sagas/rootSaga.ts

import { all } from 'redux-saga/effects';
import authSaga from '../features/auth/authSaga';
import assetSaga from '../features/asset/assetSaga';
import assetEditorSaga from '../features/assetEditor/assetEditorSaga';
import distributionSaga from '../features/distribution/distributionSagas';
import assetValuationSaga from '../features/assetValuation/assetValuationSaga';
import companySaga from '../features/backendPartners/backendPartnerSaga';
import assetMonthlyFinancialsSaga from '../features/assetMonthlyFinancials/assetMonthlyFinancialsSaga';
import assetTokenizationSaga from '../features/assetTokenization/assetTokenizationSaga';
import watchAssetSettingsSaga from '../features/assetSettings/assetSettingsSaga';
import { watchCreateAdminUser } from '../features/adminUsers/adminUsersSaga';
import assetStagesSaga from '../features/assetStages/assetStagesSaga';
import { watchRegistrationWaitingListSagas } from '../features/registrationWaitingList/waitingListSaga';
// Import other sagas as needed

function* rootSaga() {
  yield all([
    authSaga(),
    assetSaga(),
    assetEditorSaga(),
    distributionSaga(),
    assetValuationSaga(),
    companySaga(),
    assetMonthlyFinancialsSaga(),
    assetTokenizationSaga(),
    watchAssetSettingsSaga(),
    watchCreateAdminUser(),
    assetStagesSaga(),
    watchRegistrationWaitingListSagas(),
    // Add other sagas here
  ]);
} 

export default rootSaga;

// src/components/AssetStages/AssetStages.tsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateAssetStagesRequest, getAssetStagesRequest } from '../../../../features/assetStages/assetStagesSlice';
import styles from './AssetStages.module.css';
import { RootState } from '../../../../store/rootReducer';

interface AssetStagesProps {
    selectedAsset: { assetID: string; name: string; _id: string };
}

const AssetStages: React.FC<AssetStagesProps> = ({ selectedAsset }) => {
    const dispatch = useDispatch();

    const [selectedStage, setSelectedStage] = useState<string>('NA');
    const [preBookingStartDate, setPreBookingStartDate] = useState<string>('');
    const [saleStartDate, setSaleStartDate] = useState<string>('');
    const [saleEndDate, setSaleEndDate] = useState<string>('');
    const [platformFee, setPlatformFee] = useState<number>(0);
    const [dividendStartDate, setDividendStartDate] = useState<string>('');
    const [sellingCoolOffPeriod, setSellingCoolOffPeriod] = useState<number>(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    // Fetch asset stages on component mount
    useEffect(() => {
        dispatch(getAssetStagesRequest(selectedAsset._id));
    }, [dispatch, selectedAsset._id]);
    
    const { loadingStages, stageDetails } = useSelector((state: RootState) => state.assetStages);

    console.log('AssetStages: Selected Asset:', stageDetails);


    const handleSave = () => {
        const assetStageData = {
            assetID: selectedAsset._id,
            stage: selectedStage,
            preBookingStartDate: selectedStage === 'Introductory' ? preBookingStartDate : 'NA',
            saleStartDate: selectedStage === 'Pre-Booking' || selectedStage === 'Sale' ? saleStartDate : 'NA',
            saleEndDate: selectedStage === 'Sale' ? saleEndDate : 'NA',
            platformFee: selectedStage === 'Sale' ? platformFee : 'NA',
            dividendStartDate: selectedStage === 'Sale' ? dividendStartDate : 'NA',
            sellingCoolOffPeriod: selectedStage === 'Sale' ? sellingCoolOffPeriod : 'NA',
        };

        dispatch(createOrUpdateAssetStagesRequest(assetStageData));

        // Optional: clear success/error states after save
        setSuccess(true);
        setError('');
    };

    return (
        <div className={styles.assetStagesContainer}>
            <h2 className={styles.heading}>Asset Stages</h2>
            {success && <div className={styles.notificationBanner}>Stage settings saved successfully!</div>}
            {error && <div className={styles.notificationBannerError}>Error saving stage settings</div>}

            <div className={styles.formGroup}>
                <label>Asset Stage:</label>
                <select
                    value={selectedStage}
                    onChange={(e) => setSelectedStage(e.target.value)}
                    className={styles.dropdown}
                >
                    <option value="NA">NA</option>
                    <option value="Introductory">Introductory</option>
                    <option value="Pre-Booking">Pre-Booking</option>
                    <option value="Sale">Sale</option>
                    <option value="Sold-Off">Sold-Off</option>
                </select>
            </div>

            {selectedStage === 'Introductory' && (
                <div className={styles.formGroup}>
                    <label>Pre-Booking Start Date:</label>
                    <input
                        type="date"
                        value={preBookingStartDate}
                        onChange={(e) => setPreBookingStartDate(e.target.value)}
                        required
                    />
                </div>
            )}

            {selectedStage === 'Pre-Booking' && (
                <div className={styles.formGroup}>
                    <label>Sale Start Date:</label>
                    <input
                        type="date"
                        value={saleStartDate}
                        onChange={(e) => setSaleStartDate(e.target.value)}
                        required
                    />
                </div>
            )}

            {selectedStage === 'Sale' && (
                <>

                    <div className={styles.formGroup}>
                        <label>Sale End Date:</label>
                        <input
                            type="date"
                            value={saleEndDate}
                            onChange={(e) => setSaleEndDate(e.target.value)}
                            required
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label>Platform Fee (%):</label>
                        <input
                            type="number"
                            value={platformFee}
                            onChange={(e) => setPlatformFee(Number(e.target.value))}
                            min="0"
                            max="100"
                            step="0.01"
                            required
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label>Dividend Start Date:</label>
                        <input
                            type="date"
                            value={dividendStartDate}
                            onChange={(e) => setDividendStartDate(e.target.value)}
                            required
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <label>Selling Cool Off Period (Days):</label>
                        <input
                            type="number"
                            value={sellingCoolOffPeriod}
                            onChange={(e) => setSellingCoolOffPeriod(Number(e.target.value))}
                            min="0"
                            required
                        />
                    </div>
                </>
            )}

            <div className={styles.buttonContainer}>
                <button onClick={handleSave}>Save Stage Settings</button>
            </div>


            <>
                {/* Table to display AssetDetails */}
                <h3 className={styles.heading}>Asset Stages Details</h3>
                {stageDetails && stageDetails?.assetID &&
                    <table className={styles.detailsTable}>
                        <tbody>
                            <tr>
                                <td>Asset Stage</td>
                                <td>{stageDetails?.assetStage}</td>
                            </tr>
                            <tr>
                                <td>Pre-Booking Start Date</td>
                                <td>{
                                stageDetails?.preBookingStartDate && 
                                new Date(stageDetails?.preBookingStartDate).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <td>Sale Start Date</td>
                                <td>{stageDetails?.saleStartDate &&
                                new Date(stageDetails?.saleStartDate).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <td>Dividend Start Date</td>
                                <td>{stageDetails?.dividendStartDate && 
                                new Date(stageDetails?.dividendStartDate).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <td>Platform Fee</td>
                                <td>{stageDetails?.platformFee}%</td>
                            </tr>
                            <tr>
                                <td>Sale End Date</td>
                                <td>{stageDetails?.saleEndDate && 
                                new Date(stageDetails?.saleEndDate).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} className={styles.subheading}>Stage Details</td>
                            </tr>
                            {stageDetails.stagesDetails && stageDetails.stagesDetails.map((stage: any) => (
                                <tr key={stage._id}>
                                    <td>{stage.stageName} Set Date</td>
                                    <td>{new Date(stage.stageSetDate).toLocaleDateString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>  
                }   
                {
                    stageDetails && !stageDetails?.assetID && 
                    <span>Asset Stages Details not Added yet</span>
                }       
            </>
        </div>
    );
};

export default AssetStages;

// src/components/MonthlySpendingDetails/MonthlySpendingDetails.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMonthlySpendingsRequest, addMonthlySpendingRequest } from '../../../../features/assetMonthlyFinancials/assetMonthlyFinancialsSlice';
import styles from './MonthlySpendingDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import { RootState } from '../../../../store/rootReducer';

interface MonthlySpendingDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const MonthlySpendingDetails: React.FC<MonthlySpendingDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const [isFormVisible, setFormVisible] = useState(false);

  const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


  const { success, error, loading, monthlySpendingsList } = useSelector(
    (state: RootState) => state.assetMonthlyFinancials
  );

  console.log('monthlySpendingsList', monthlySpendingsList);

  // Combine month and year into a single dropdown
  const [selectedMonthYear, setSelectedMonthYear] = useState<string>(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  const [spendingName, setSpendingName] = useState<string>('');
  const [spendingAmount, setSpendingAmount] = useState<number>(0);
  const [spendingComments, setSpendingComments] = useState<string>('');
  const [spendingDate, setSpendingDate] = useState<string>(new Date().toISOString().split('T')[0]);

  const handleUpdate = () => {
    const [year, month] = selectedMonthYear.split('-'); // Extract year and month
    const monthlySpendingDetails = {
      year: Number(year),
      month: Number(month),
      spendingName,
      spendingAmount,
      spendingComments,
      spendingDate,
      assetID: selectedAsset._id,
    };
    const updateObj = { monthlySpendingDetails };
    dispatch(addMonthlySpendingRequest(updateObj));
  };

  useEffect(() => {
    const [year, month] = selectedMonthYear.split('-');
    dispatch(fetchMonthlySpendingsRequest({ assetID: selectedAsset._id, year: Number(year), month: Number(month) }));
  }, [dispatch, selectedAsset._id, selectedMonthYear]);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  // Generate options for the month-year dropdown
  const getMonthYearOptions = () => {
    const options = [];
    const now = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const value = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const label = `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
      options.push({ value, label });
    }
    return options;
  };

  // Function to handle changes in Month-Year dropdown
  const handleMonthYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log('selectedMonthYear', e.target.value);
    setSelectedMonthYear(e.target.value);
    const [year, month] = e.target.value.split('-');
    console.log('year', year);
    console.log('month', month);
    dispatch(fetchMonthlySpendingsRequest({ assetID: selectedAsset._id, year: Number(year), month: Number(month) }));
  };

  return (
    <div className={styles.monthlySpendingDetailsContainer}>
      <h2 className={styles.heading}>Monthly Spending Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      {/* Combined Month-Year dropdown */}
      <div className={styles.formGroup}>
        <label>Month and Year:</label>
        <select value={selectedMonthYear} onChange={handleMonthYearChange}>
          {getMonthYearOptions().map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Table showing spending details */}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Year</th>
              <th>Month</th>
              <th>Spending Name</th>
              <th>Spending Amount</th>
              <th>Comments</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {monthlySpendingsList.map((detail: any) => (
              <tr key={detail._id}>
                <td>{detail.year}</td>
                <td>{monthArray[detail.month-1]}</td>
                {/* <td>{new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(`${detail.year}-${detail.month}-01`))}</td> */}
                <td>{detail.spendingName}</td>
                <td>{detail.spendingAmount}</td>
                <td>{detail.spendingComments}</td>
                <td>{new Date(detail.spendingDate).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.toggleButtonContainer}>
        <button onClick={toggleFormVisibility} className={styles.toggleButton}>
          {isFormVisible ? 'Hide' : 'Add/Edit Monthly Spending Details'}
        </button>
      </div>

      {isFormVisible && (
        <div className={styles.formContainer}>
          <h3>Add/Edit Monthly Spending Details</h3>

          {/* Spending Name */}
          <div className={styles.formGroup}>
            <label>Spending Name:</label>
            <input
              type="text"
              value={spendingName}
              onChange={(e) => setSpendingName(e.target.value)}
              placeholder="Enter spending name"
            />
          </div>

          {/* Spending Amount */}
          <div className={styles.formGroup}>
            <label>Spending Amount:</label>
            <input
              type="number"
              value={spendingAmount}
              onChange={(e) => setSpendingAmount(Number(e.target.value))}
              placeholder="Enter spending amount"
            />
          </div>

          {/* Spending Comments */}
          <div className={styles.formGroup}>
            <label>Comments:</label>
            <textarea
              value={spendingComments}
              onChange={(e) => setSpendingComments(e.target.value)}
              placeholder="Enter comments (optional)"
            />
          </div>

          {/* Spending Date */}
          <div className={styles.formGroup}>
            <label>Date:</label>
            <input
              type="date"
              value={spendingDate}
              onChange={(e) => setSpendingDate(e.target.value)}
            />
          </div>

          <div className={styles.buttonContainer}>
            <button onClick={handleUpdate}>Update Monthly Spending Details</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthlySpendingDetails;

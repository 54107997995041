// src/components/AssetDetailEditor/AssetHeader.tsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faTwitter, faFacebook, faGooglePlusG, faPinterest, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styles from './AssetHeader.module.css';

interface AssetHeaderProps {
  title: string;
  address: string;
  price: string;
  propertyId: string;
}

const AssetHeader: React.FC<AssetHeaderProps> = ({ title, address, price, propertyId }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.address}>{address}</p>
        </div>
        <div className={styles.priceContainer}>
          <span className={styles.forSale}>Built Year: 2001</span>
        </div>
      </div>
      <div className={styles.subHeader}>
        <p className={styles.propertyId}>Property ID: {propertyId}</p>
        <a href="#" className={styles.favorites}>
          Add to favorites <FontAwesomeIcon icon={faHeart} />
        </a>
        <div className={styles.shareContainer}>
          <span>Share:</span>
          <a href="#"><FontAwesomeIcon icon={faTwitter} className={styles.icon} /></a>
          <a href="#"><FontAwesomeIcon icon={faFacebook} className={styles.icon} /></a>
          <a href="#"><FontAwesomeIcon icon={faInstagram} className={styles.icon} /></a>
        </div>
      </div>
    </div>
  );
};

export default AssetHeader;

// src/components/AssetDetailEditor/AssetValuation.tsx
import React, { useState } from 'react';
import styles from './AssetValuation.module.css';

const valuationData = [
  {
    date: '2024 Jan 22',
    daysSince: '487 days since prior valuation',
    assetValuation: '$84,000.00',
    annualChange: '+17.65%',
    totalInvestment: '$84,900.00',
    capitalROI: '+13.29%',
    tokenPrice: '$53.06',
    regDistributed: '$7,882.22',
    newPhotos: 'No new photos at this time.',
    documentLink: 'View Appraisal Document',
  },
  {
    date: '2022 Sep 22',
    daysSince: 'Initial Valuation',
    assetValuation: '$68,000.00',
    totalInvestment: '$78,816.00',
    tokenPrice: '$49.26',
    documentLink: 'Property Financials Tab',
  },
];

const AssetValuation: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.valuationContainer}>
      <h2 className={styles.valuationHeading}>Asset Valuation</h2>
      <div className={styles.accordion}>
        {valuationData.map((valuation, index) => (
          <div key={index} className={styles.accordionItem}>
            <div
              className={styles.accordionTitle}
              onClick={() => toggleAccordion(index)}
            >
              <h3>{valuation.date}</h3>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className={styles.accordionContent}>
                <div className={styles.valuationDetails}>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>VALUATION DATE</div>
                    <div>{valuation.date}</div>
                  </div>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>VALUATION COST</div>
                    <div>{valuation.assetValuation}</div>
                  </div>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>TOTAL INVESTMENT</div>
                    <div>{valuation.totalInvestment}</div>
                  </div>
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>TOKEN PRICE</div>
                    <div>{valuation.tokenPrice}</div>
                  </div>
                  {valuation.annualChange && (
                    <div className={styles.valuationItem}>
                      <div className={styles.valuationTitle}>ANNUAL CHANGE</div>
                      <div>{valuation.annualChange}</div>
                    </div>
                  )}
                  {valuation.capitalROI && (
                    <div className={styles.valuationItem}>
                      <div className={styles.valuationTitle}>CAPITAL ROI</div>
                      <div>{valuation.capitalROI}</div>
                    </div>
                  )}
                  {valuation.regDistributed && (
                    <div className={styles.valuationItem}>
                      <div className={styles.valuationTitle}>REG DISTRIBUTED</div>
                      <div>{valuation.regDistributed}</div>
                    </div>
                  )}
                  <div className={styles.valuationItem}>
                    <div className={styles.valuationTitle}>VALUATION DOCUMENT</div>
                    <div><a href="#">{valuation.documentLink}</a></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetValuation;

import axios from 'axios';
import config from '../../config/config';

const { apiUrl } = config;
const API_BASE_URL = `${apiUrl}/waiting-list`;

// API call to get all entries in the waiting list
export const fetchAllWaitingListApi = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/`);
    return response.data; // This should be an array of WaitingList entries
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Failed to retrieve waiting list');
  }
};

// API call to approve a waiting list entry
export const approveWaitingListApi = async (emailId: string) => {
    try {
      const response = await axios.patch(`${API_BASE_URL}/approve/${emailId}`);
      return response.data; // Expecting a WaitingList object
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to approve waiting list entry');
    }
  };
// src/components/PrivateRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from '../components/LoginModule/Login';
import RegisterPage from '../components/LoginModule/Register';
import DashboardPage from '../components/Dashboard/Dashboard';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import CreateAsset from '../components/Admin/CreateAsset/CreateAsset';
import AdminPage from '../components/Admin/AdminPage/AdminPage';
import AdminDashboard from '../components/Admin/AdminDashboard/AdminDashboard';
import UnpublishedEditor from '../components/Admin/UnpublishedEditor/UnpublishedEditor';
import AssetPage from '../components/Admin/AssetDetailViewer/AssetPage/AssetPage';
import PublishedViewer from '../components/Admin/PublishedViewer/PublishedViewer';
import LoginWrapper from '../components/LoginModule/LoginWrapper';
import AdminPrivateRoute from './AdminPrivateRoute';
import HomeRoute from './HomeRoute';
import AssetManagementViewer from '../components/Admin/AssetManagement/AssetManagementViewer';
import ErrorNow from '../components/Error/ErrorNow';

const AppRoutes: React.FC = () => {
//   const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
        <Router>
            <Routes>
                {/* <Route path="/home" element={<HomePage />} /> */}

                <Route path="/signup" element={<LoginWrapper />} />
                <Route path="/register" element={<LoginWrapper />} />
                <Route path="/forgot-password" element={<LoginWrapper />} />
                <Route path="/reset-password/:rt" element={<LoginWrapper />} />
                <Route path="/email-verification/:vt" element={<LoginWrapper />} />
                <Route path="/how-it-works" element={<HowItWorks />} />     
                <Route path="how-it-works" element={<HowItWorks />} />
                <Route path="create-asset" element={<CreateAsset />} />
                <Route path="/error" element={<ErrorNow />} />
          

            <Route element={<AdminPrivateRoute />}>
                <Route path="/admin" element={<AdminPage />} >
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="unpublished-editor/:pid/:aid" element={<UnpublishedEditor />} />
                    <Route path="published-viewer" element={<PublishedViewer />} />
                    <Route path="asset-management/:pid/:aid" element={<AssetManagementViewer />} />
                </Route>
            </Route>    

            <Route element={<HomeRoute />}>
                <Route path="/login" element={<LoginWrapper />} />
                <Route path="/" element={<LoginWrapper />} />
            </Route>

            </Routes>
        </Router>
  )
};

export default AppRoutes;

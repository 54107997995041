import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { savePurchaseDetailsRequest, getPurchaseDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import { format } from 'date-fns';

import styles from './PurchaseDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface PurchaseDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string };
}

const PurchaseDetails: React.FC<PurchaseDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();
  const {
    purchasePrice,
    purchaseDate,
    purchasePriceExtras,
    purchaseComments,
    adminFee,
    dldFee,
    customFields,
  } = useSelector((state: RootState) => state.assetEditor.purchaseDetails);
  
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);

  const [price, setPrice] = useState<number>(0);
  const [date, setDate] = useState('');
  const [extras, setExtras] = useState<number>(0);
  const [comments, setComments] = useState('');
  const [adminFeeAmount, setAdminFeeAmount] = useState<number>(0);
  const [dldFeeAmount, setDldFeeAmount] = useState<number>(0);
  const [customFieldsItems, setCustomFieldsItems] = useState<{ [key: string]: number }>({});
  const [newCustomFieldName, setNewCustomFieldName] = useState('');

  // Calculate the total additional cost (Admin Fee + DLD Fee + Custom Fields)
  const customFieldsItemsTotal = customFieldsItems? Object.values(customFieldsItems).reduce((sum, value) => sum + value, 0) : 0;
  const totalAdditionalCost = adminFeeAmount + dldFeeAmount + customFieldsItemsTotal;

  // Calculate the total spending (Purchase Price + Total Additional Cost)
  const totalSpending = price + totalAdditionalCost;

  useEffect(() => {
    dispatch(getPurchaseDetailsRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    setPrice(purchasePrice);
    // setDate(purchaseDate);
    setExtras(purchasePriceExtras);
    setComments(purchaseComments);
    setAdminFeeAmount(adminFee);
    setDldFeeAmount(dldFee);
    setCustomFieldsItems(customFields);
    if (purchaseDate) {
      setDate(format(new Date(purchaseDate), 'yyyy-MM-dd')); // Using date-fns to format
    } else {
      setDate(''); // Ensure the input is not broken if date is null/empty
    }

  }, [purchasePrice, purchaseDate, purchasePriceExtras, purchaseComments, adminFee, dldFee, customFields]);

  const handleSave = () => {
    const _id = selectedAsset._id;
    const assetID = selectedAsset.assetID;

    // Create purchase details payload including admin fee, DLD fee, and custom fields
    const purchaseDetails = {
      assetID: _id,
      purchasePrice: price,
      purchaseDate: date,
      purchasePriceExtras: extras,
      purchaseComments: comments,
      adminFee: adminFeeAmount,
      dldFee: dldFeeAmount,
      customFields: customFieldsItems, // Include custom fields
    };

    console.log('Saving purchase details:', purchaseDetails);

    // Dispatch the action to save purchase details with API payload
    dispatch(savePurchaseDetailsRequest({ purchaseDetails, assetID, _id }));
  };

  const handleCustomFieldChange = (field: string, value: number) => {
    setCustomFieldsItems({ ...customFieldsItems, [field]: value });
  };

  const handleAddCustomField = () => {
    if (newCustomFieldName) {
      setCustomFieldsItems({ ...customFieldsItems, [newCustomFieldName]: 0 });
      setNewCustomFieldName('');
    }
  };

  return (
    <div className={styles.purchaseDetailsContainer}>
      <h2 className={styles.heading}>Purchase Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.formGroup}>
        <label>Purchase Price:</label>
        <input type="number" value={price} onChange={(e) => setPrice(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Purchase Date:</label>
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Admin Fee:</label>
        <input type="number" value={adminFeeAmount} onChange={(e) => setAdminFeeAmount(Number(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>DLD Fee:</label>
        <input type="number" value={dldFeeAmount} onChange={(e) => setDldFeeAmount(Number(e.target.value))} />
      </div>

      {/* Custom Fields Section */}
      {customFieldsItems && 
      (
        <div className={styles.customFieldsSection}>
          {Object.keys(customFieldsItems).map((field, index) => (
            <div key={index} className={styles.formGroup}>
              <label>{field}:</label>
              <input
                type="number"
                value={customFieldsItems[field]}
                onChange={(e) => handleCustomFieldChange(field, Number(e.target.value))}
              />
            </div>
          ))}
        </div>
      )}

      <div className={styles.formGroup}>
        <label>Purchase Comments:</label>
        <textarea value={comments} onChange={(e) => setComments(e.target.value)} />
      </div>

      {/* Total Additional Cost Section */}
      <div className={styles.formGroup}>
        <label>Total Additional Cost:</label>
        <input type="number" value={totalAdditionalCost} readOnly className={styles.readOnlyInput} />
      </div>

      <div className={styles.calculationExplanation}>
        <p>Total Additional Cost = Admin Fee ({adminFeeAmount}) + DLD Fee ({dldFeeAmount}) + Custom Fields ({customFieldsItems ? Object.values(customFieldsItems).reduce((sum, value) => sum + value, 0): 0})</p>
      </div>

      {/* Total Spending (Purchase Price + Total Additional Cost) */}
      <div className={styles.formGroup}>
        <label>Total Spending:</label>
        <input type="number" value={totalSpending} readOnly className={styles.readOnlyInput} />
      </div>

      <div className={styles.calculationExplanation}>
        <p>Total Spending = Purchase Price ({price}) + Total Additional Cost ({totalAdditionalCost})</p>
      </div>

      <div className={styles.newCustomField}>
        <label>Add Custom Field:</label>
        <input
          type="text"
          value={newCustomFieldName}
          onChange={(e) => setNewCustomFieldName(e.target.value)}
          placeholder="Field Name"
        />
        <button type="button" onClick={handleAddCustomField} className={styles.addCustomFieldButton}>
          Add Field
        </button>
      </div>


      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default PurchaseDetails;

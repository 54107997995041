// app/components/TopBanner.tsx

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './TopBanner.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from '../../../features/auth/authSlice';
import { RootState } from '../../../store/rootReducer';
import logo from '../../../assets/logo.png';

const TopBanner: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleLogout = async () => {
    try {
      dispatch(logoutRequest());
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className={styles.banner}>
            <div className={styles.leftSection}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="Assetslices Logo" className={styles.logo} />
              </div>
              <span className={styles.adminConsole}>Admin Console</span>
            </div>
      <nav className={styles.navMenu}>
        <div
          className={styles.navItem}
          onMouseEnter={() => setMenuVisible(true)}
        >
          <span className={styles.navLink}>Logout</span>
          {/* {menuVisible && (
            <div
              className={styles.submenu}
              onMouseEnter={() => setMenuVisible(true)}
              onMouseLeave={() => setMenuVisible(false)}
            >
             
              <button className={styles.submenuItem} onClick={handleLogout}>
                Logout  
              </button>
            </div>
          )} */}
        </div>
      </nav>
    </div>
  );
};

export default TopBanner;

// src/components/AssetDetailEditor/RentDetails.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveRentDetailsRequest, getRentDetailsRequest } from '../../../../features/assetEditor/assetEditorSlice';
import { RootState } from '../../../../store/rootReducer';
import styles from './RentDetails.module.css';
import NotificationBanner from '../NotificationBanner/NotificationBanner';

interface RentDetailsProps {
  selectedAsset: { assetID: string; name: string; _id: string }; // Assuming Asset is the type of your selected asset
}

const RentDetails: React.FC<RentDetailsProps> = ({ selectedAsset }) => {
  const dispatch = useDispatch();

  console.log('RentDetails: Selected Asset:', selectedAsset);
  useSelector((state: RootState) => {
    console.log('RentDetails: Rent Details:', state.assetEditor);
    return state.assetEditor.rentDetails;
  });

  const {
    rentalPrice: rentAmountFromState,
    rentalStart: rentalStartFromState,
    rentalEnd: rentalEndFromState,
    rentalStatus: rentalStatusFromState,
    monthlyUtilities: monthlyUtilitiesFromState,
    adminCharge: adminChargeFromState,
    comment: commentsFromState,
    tenantName: tenantNameFromState,
    tenantContact: tenantContactFromState,
  } = useSelector((state: RootState) => state.assetEditor.rentDetails);
  const { success, error, loading } = useSelector((state: RootState) => state.assetEditor);
  
  const rentDetailsList = useSelector((state: RootState) => state.assetEditor.rentDetailsList);
  console.log('Rent Details List:', rentDetailsList);
  const _rentDetailsList: any = [];
  rentDetailsList.map((detail: any) => {  
    _rentDetailsList.push(detail);
  });
  console.log('___Rent Details List:', _rentDetailsList);

  const [rentalPrice, setRentalPrice] = useState<number>(0);
  const [rentalStart, setRentalStart] = useState('');
  const [rentalEnd, setRentalEnd] = useState('');
  const [rentalStatus, setRentalStatus] = useState('');
  const [monthlyUtilities, setMonthlyUtilities] = useState<number>(0);
  const [adminCharge, setAdminCharge] = useState<number>(0);
  const [comment, setComment] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [tenantContact, setTenantContact] = useState('');

  useEffect(() => {
    dispatch(getRentDetailsRequest(selectedAsset._id));
  }, [dispatch, selectedAsset._id]);

  useEffect(() => {
    setRentalPrice(rentAmountFromState);
    setRentalStart(rentalStartFromState);
    setRentalEnd(rentalEndFromState);
    setRentalStatus(rentalStatusFromState);
    setMonthlyUtilities(monthlyUtilitiesFromState);
    setAdminCharge(adminChargeFromState);
    setComment(commentsFromState);
    setTenantName(tenantNameFromState);
    setTenantContact(tenantContactFromState);
  }, [rentAmountFromState, rentalStartFromState, rentalEndFromState, rentalStatusFromState, monthlyUtilitiesFromState, adminChargeFromState, commentsFromState, tenantNameFromState, tenantContactFromState]);

  const handleSave = () => {
    const _id = selectedAsset._id;
    const rentDetails = { assetID: _id, rentalPrice, rentalStart, rentalEnd, rentalStatus, monthlyUtilities, adminCharge, comment, tenantName, tenantContact };
    const assetID = selectedAsset.assetID;
    dispatch(saveRentDetailsRequest({ rentDetails, _id }));
  };

  return (
    <div className={styles.rentDetailContainer}>
      <h2 className={styles.heading}>Rent Contract Details</h2>
      <NotificationBanner success={success} error={error} loading={loading} />

      <div className={styles.tableContainer}>
        <h3>Rent Contract Details Summary</h3>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div className={styles.tableHeader}>Rental Price</div>
            <div className={styles.tableHeader}>Rental Start</div>
            <div className={styles.tableHeader}>Rental End</div>
            <div className={styles.tableHeader}>Rental Status</div>
            <div className={styles.tableHeader}>Tenant Name</div>
            <div className={styles.tableHeader}>Tenant Contact</div>
            <div className={styles.tableHeader}>Monthly Utilities</div>
            <div className={styles.tableHeader}>Admin Charge</div>
            <div className={styles.tableHeader}>Comment</div>
          </div>
          {_rentDetailsList.map((detail: { _id: React.Key | null | undefined; rentalPrice: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; rentalStart: string | number | Date; rentalEnd: string | number | Date; rentalStatus: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; tenantName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; tenantContact: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; monthlyUtilities: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; adminCharge: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; comment: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => (
            <div key={detail._id} className={styles.tableRow}>
              <div className={styles.tableCell}>{detail.rentalPrice}</div>
              <div className={styles.tableCell}>{new Date(detail.rentalStart).toLocaleDateString()}</div>
              <div className={styles.tableCell}>{new Date(detail.rentalEnd).toLocaleDateString()}</div>
              <div className={styles.tableCell}>{detail.rentalStatus}</div>
              <div className={styles.tableCell}>{detail.tenantName}</div>
              <div className={styles.tableCell}>{detail.tenantContact}</div>
              <div className={styles.tableCell}>{detail.monthlyUtilities}</div>
              <div className={styles.tableCell}>{detail.adminCharge}</div>
              <div className={styles.tableCell}>{detail.comment}</div>
            </div>
          ))}
        </div>
      </div>


      <h2 className={styles.heading}>Add New Rent Contract</h2>
      <div className={styles.formGroup}>
        <label>Rent Amount:</label>
        <input type="number" value={rentalPrice} onChange={(e) => setRentalPrice(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Rental Start:</label>
        <input type="date" value={rentalStart} onChange={(e) => setRentalStart(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Rental End:</label>
        <input type="date" value={rentalEnd} onChange={(e) => setRentalEnd(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Rental Status:</label>
        <input type="text" value={rentalStatus} onChange={(e) => setRentalStatus(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Monthly Utilities:</label>
        <input type="number" value={monthlyUtilities} onChange={(e) => setMonthlyUtilities(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Admin Charge:</label>
        <input type="number" value={adminCharge} onChange={(e) => setAdminCharge(parseInt(e.target.value))} />
      </div>

      <div className={styles.formGroup}>
        <label>Comments:</label>
        <textarea value={comment} onChange={(e) => setComment(e.target.value)} maxLength={1000} />
      </div>

      <div className={styles.formGroup}>
        <label>Tenant Name:</label>
        <input type="text" value={tenantName} onChange={(e) => setTenantName(e.target.value)} />
      </div>

      <div className={styles.formGroup}>
        <label>Tenant Contact:</label>
        <input type="text" value={tenantContact} onChange={(e) => setTenantContact(e.target.value)} />
      </div>

      <div className={styles.buttonContainer}>
        <button onClick={handleSave}>Save Details</button>
      </div>
    </div>
  );
};

export default RentDetails;
